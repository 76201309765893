/*
This module dispatches two things. On the left hand side the reservation form,
on the right hand side the calendar with already book resources that also are used
in the new reservation/edited reservation/template.
On each change (f.e. in the users or rsources) this module is called again to go
back with the changed values to the reservation form.
*/

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReservationForm3 } from './ReservationForm3';
import { CalendarForm } from './CalendarForm';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './addreservation.css';
import './eventUtils.js';
import { createEvents } from './eventUtils.js';
import {
   getReservationById, getResourceData, reservationReset, setSelectedReservation, setSelectedResources, setSelectedUsers
} from './reservationsSlice';
import { getTemplateById } from '../templates/templatesSlice.js';
import { useParams } from 'react-router-dom';
import { CreatorContext } from '../../context/CreatorProvider';
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/nl-be'; // Set the desired locale
import { fetchBookedResources, fetchBookedResourcesForId } from '../reservationresources/bookedResourcesSlice';
import { fetchBookedUsersForId } from '../reservationusers/bookedUsersSlice';
import { fetchResourceById } from '../resources/resourcesSlice.js';
import { fetchUserById } from '../users/usersSlice.js';



export const AddReservation = () => {
  const [events, setEvents] = useState([]);
  const use_params = useParams()
  let id = use_params.id
  let origin = use_params.origin
  
  const dispatch = useDispatch();
  const timeZone=moment.tz.setDefault('Europe/Brussels');

  let selectedReservations = useSelector((state) => state.reservations.reservations)
  let selectedResources = useSelector(
    (state) => state.reservations.selectedResources
  );
  const selectedUsers = useSelector(
    (state) => state.reservations.selectedUsers
  );
  const booked = useSelector((state) => state.bookedResources.resources);

  console.log('origin = ', origin)
  console.log('id = ', id)
  console.log('AddReservation: selectedReservations = ', selectedReservations)
  console.log('AddReservation: selectedResources = ', selectedResources)
  console.log('AddReservation: selectedUsers = ', selectedUsers)
  console.log('AddReservation: booked = ', booked)
  console.log('origin = ', origin)

  
  const reservationType = selectedReservations.type
  console.log('reservation project type = ', reservationType)
  

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchBookedResources())
      if (origin === 'edit') {
        const theReservation = await dispatch(getReservationById(parseInt(id)))
        const reservation = theReservation.payload
        console.log('reservation in AddReservation = ', reservation)
        console.log('reservation in AddReservation edit is: ', reservation)
        const bookedResources = await dispatch(fetchBookedResources())
        const booked=bookedResources.payload
        if (selectedResources.length === 0) {
            
            const selectedResourcesEdit = reservation[1].selectedResources
            const selectedUsersEdit = reservation[2].selectedUsers
            await dispatch(setSelectedResources(selectedResourcesEdit))
            await dispatch(setSelectedUsers(selectedUsersEdit))
            const newEvents = createEvents(selectedResourcesEdit, booked, id, reservationType);
            setEvents(newEvents);
        } else if (selectedResources.length > 0 && booked.length > 0) {
            
            const newEvents = createEvents(selectedResources, booked, id, reservationType);
            setEvents(newEvents);
        }
      } else if (origin === 'new') {
          const bookedResources = await dispatch(fetchBookedResources())
          const booked=bookedResources.payload
          const newEvents = createEvents(selectedResources, booked, id, reservationType);
          setEvents(newEvents);
      } else if (origin === 'template' || origin === 'fromNew') {
          const bookedResources = await dispatch(fetchBookedResources())
          const booked=bookedResources.payload
          const newEvents = createEvents(selectedResources, booked, id, reservationType);
          setEvents(newEvents);
      }
    }
    fetchData()
  }, []);


  const onDeleteEvent = (eventId) => {
    console.log('event id before filtering is: ', eventId)
    const filteredEvents = events.filter((event) => event.id !== eventId)
    setEvents(filteredEvents)
  }

  return (
    <>
      <div align='center'>
        <h1>Pick a date range, a project title and remarks.</h1>
        <h3>You can also review your resources and users.</h3>
        <br />
      </div>

      <div style={{ height: '100vh', display: 'flex' }}>
        <div style={{ flex: '1', padding: '1rem', display: 'flex' }}>
          <div style={{ flex: '1' }}>
            <ReservationForm3 currentEvents={events} timeZone={timeZone} id={id} origin={origin} />
          </div>
        </div>

        <div style={{ flex: '1', padding: '1rem' }}>
          {events && <CalendarForm timeZone={timeZone} key={events.length} events={events} onDeleteEvent={onDeleteEvent} />}
        </div>
      </div>
    </>
  );
};
