import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Logo from '../../../src/assets/logo.png';

import './Navbar.css';

export const NewNavBar = () => {
  
  
  return (
    <>
      <Navbar className='navbar'>
        <Container>
          <Navbar.Brand>
            <a target='_blank' href='https://www.ugent.be' rel="noreferrer">
              <img src={Logo} height='158px' />
            </a>
          </Navbar.Brand>
          <Link to='/'>
            <h2 className='app-title'>REVA RESERVATION</h2>
          </Link>
          <Nav className='nav-menu'>
            <li className='nav-item'>
              <Link to='/home' className='nav-links'>
                HOME
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='posts' className='nav-links'>
                POSTS
              </Link>
            </li>
            <NavDropdown title='RESERVATIONS'>
              <NavDropdown.Item>
                <Link to='/selectresources/0/new'>CREATE RESERVATION</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='myreservations'>MY RESERVATIONS</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='managetemplates'>TEMPLATES</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title='ADMIN'>
              <NavDropdown.Item>
                <Link to='manageresources'>MANAGE RESOURCES</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='manageusers'>MANAGE USERS</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='manageposts'>MANAGE POSTS</Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to='managereservations'>MANAGE RESERVATIONS</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>

      <br />
    </>
  );
};
