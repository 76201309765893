import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  resourcesReset,
  fetchResources,
  fetchResourcesByCategoryId,
  fetchResourcesBySubCategoryId,
} from './resourcesSlice';
import { reset } from './resourcesSlice';
import {
  reservationActions,
  resourcesRefresh,
} from '../reservations/reservationsSlice';
import Select from 'react-select';
import { Button } from '@mui/material';
import { Checkbox } from '../../assets/Checkbox';
import { ResourceFilter } from './ResourceFilter';
import { appendResources } from '../reservations/reservationsSlice';

import { COLUMNS, CATEGORIES, SUBCATEGORIES } from './resourceConstants';
import '../../styles.css';
import { ResourceSelection } from './ResourceSelection';

export const ResourceView = () => {
  const dispatch = useDispatch();
  const use_params = useParams();
  const id = use_params.id;
  const origin = use_params.origin
  const [subCategoryVisible, setSubCategoryVisible] = useState(false);
  let resources = useSelector((state) => state.resources.resources);
  const selectedResources = useSelector(
    (state) => state.reservations.selectedResources);
  const navigate = useNavigate();

  
  useEffect(() => {
    console.log('id = ', id)
    console.log('origin = ', origin)
    if (!id && !origin) {
      dispatch(fetchResources()).then((action) => {
        console.log('action is: ', action)
        //resources = action.payload
      }
      )  
    }
  }, [])

  const navToNext = () => {
    if (selectedFlatRows.length > 0) {
      selectedFlatRows.map((row) => {
        const selectedResource = row.original;
        //console.log('selectedResource = ', selectedResource);
        dispatch(appendResources(selectedResource));
      });
      navigate('/selectusers');
    } else {
      // display message
    }
  };

  const clickAdd = () => {
    if (selectedFlatRows.length > 0) {
      selectedFlatRows.map((row) => {
        const selectedResource = row.original;
        const exists = selectedResources.some(
          (resource) => resource.resource_id === selectedResource.resource_id
        );
        console.log('exists = ', exists);
        if (!exists) {
          console.log('append is fired')
          dispatch(appendResources(selectedResource));
        }
        console.log('selectedResources = ', selectedResources);
      });
    }
  };

  const getStyle = (row) => {
    let styleSetting = '';
    console.log('row = ', row)
    const theRow = row.original;
    if (theRow.resource_status !== 1) {
      //console.log('in IT');
      styleSetting = 'unavailable';

    }
    return styleSetting;
  }

  //const data = useMemo(() => [...resources.resources], [resources.resources]);

  const handleCategoryChange = (selectedOption) => {
    // console.log('Selected category is:', selectedOption.value);
    selectedOption.value === 2
      ? setSubCategoryVisible(true)
      : setSubCategoryVisible(false);

    dispatch(fetchResourcesByCategoryId({ categoryId: selectedOption.value }));
  };

  const handleSubCategoryChange = (selectedOption) => {
    dispatch(
      fetchResourcesBySubCategoryId({ subCategoryId: selectedOption.value })
    );
  };

 

  
  
  const data = useMemo(() => [...resources], [resources]);
  const columns = useMemo(() => COLUMNS, []);

  // Create table instance
  const tableInstance = useTable(
    {
      columns: columns, // we could ommit the repeat
      data: data, // dito
      initialState: {
        hiddenColumns: ['resource_category_id', 'resource_subcategory_id'],
        selectedFlatRows: selectedResources,
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  

  console.log('selected resources = ', selectedResources);

  return (
    <div align='center' className='resource-table-admin'>
      {selectedResources && selectedResources.length > 0 ? (
        <>
          <h1>Reservation delete/add resources</h1>
          <h3>Select your resources and click Add or Delete</h3>
          <ResourceSelection selectedResources={selectedResources} />
        </>
      ) : (
        <>
          <h1>Reservation - Step 1: select resources</h1>
          <h3>Select your resources and click next.</h3>
        </>
      )}
      <br />
      <div>
        <div className='resource-buttons'>
          <Select
            placeholder='Select category...'
            options={CATEGORIES}
            onChange={handleCategoryChange}
            className='category-select'
          />
          {subCategoryVisible && (
            <Select
              placeholder='select sub-category...'
              options={SUBCATEGORIES}
              onChange={handleSubCategoryChange}
              className='sub-category-select'
            />
          )}
          <ResourceFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        {/* {console.log(selectedFlatRows.map((row) => row.original))} */}
      </div>
      <br />

      {selectedResources && selectedResources.length > 0 ? (
        <div className='button-next'>
          <Button variant='contained' onClick={clickAdd}>
            Add
          </Button>
        </div>
      ) : (
        <div className='button-next'>
          <Button variant='contained' onClick={navToNext}>
            Next
          </Button>
        </div>
      )}

      <div>
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={getStyle(row)}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render('Header')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <div>
        <br />
      </div>
      <div className='pagination-style'>
        <span className='page-index'>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          &nbsp;
        </span>
        <span className='goto-page'>
          | Go to page&nbsp;
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: '50px', fontSize: '12px' }}
          />
          &nbsp;
        </span>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          className='page-size-select'
        >
          {[10, 15, 20, 25, 50, 75].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>

        <button
          className='pagination-button'
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>
        <button
          className='pagination-button'
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <button
          className='pagination-button'
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next
        </button>
        <button
          className='pagination-button'
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
  // Note: "Cell" is a prop from COLUMNS but is not shown nor defined
};
