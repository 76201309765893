import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { ResourceView } from './features/resources/ResourceView';
import { ResourceSelection } from './features/resources/ResourceSelection'
import { UserSelection } from './features/users/UserSelection'
import { PostsView } from './features/bulletinboard/PostsView';
import { PostAdminView } from './features/bulletinboard/admin/PostAdminView';
import { PostForm } from './features/bulletinboard/admin/PostForm';
import { ResourceForm } from './features/resources/admin/ResourceForm';
import { UserForm } from './features/users/admin/UserForm';
import { TemplateForm } from './features/templates/TemplateForm';
import { ResourceAdminView } from './features/resources/admin/ResourceAdminView';
import { UserAdminView } from './features/users/admin/UserAdminView';
import { Home } from './components/Home';
import { Missing } from './components/Missing';
import { Login } from './Login';
import { Register } from './Register';
import { UserView } from './features/users/UserView';
import { AddReservation } from './features/reservations/AddReservation';
import { MyReservations } from './features/reservations/MyReservations';
import { ReservationView } from './features/reservations/ReservationView';
import { TemplateAdminView } from './features/templates/TemplateAdminView';
import { NewNavBar } from './components/navigation/NewNavBar';
import { Unauthorized } from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import './App.css';

function App() {
  const ROLES = {
    USER: 1,
    ADMIN: 2,
  };
  return (
    <>
      <NewNavBar />
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* Public Routes */}
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='*' element={<Missing />} />

          {/* Protected Routes*/}
          <Route
            element={<RequireAuth allowedRoles={[ROLES.USER, ROLES.ADMIN]} />}
          >
            <Route path='/home' element={<Home />} />
            <Route path='/posts' element={<PostsView />} />
            <Route path='/selectusers' element={<UserView />} />
            <Route path='/selectresources' element={<ResourceView />} />
            <Route path='/selectresources/:id/:origin' element={<ResourceSelection />} />
            <Route path='/selectusers/:id/:origin' element={<UserSelection />} />
            <Route path='/finalizereservation' element={<AddReservation />} />
            <Route path='/finalizereservation/:id' element={<AddReservation />} />
            <Route path='/finalizereservation/:id/:origin' element={<AddReservation />} />
            <Route path='/addreservation' element={<AddReservation />} />
            <Route path='/addreservation/:id' element={<AddReservation />} />
            <Route path='/addreservation/:id/:origin' element={<AddReservation />} />
            <Route path='/addreservation/:id/:action' element={<AddReservation />} />
            <Route path='/myreservations' element={<MyReservations />} />
            <Route path='/managetemplates' element={<TemplateAdminView />} />
            <Route path='/managetemplates/:add' element={<TemplateForm />} />
            <Route path='/managetemplates/:edit/:id' element={<TemplateForm />} />S
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path='/manageposts' element={<PostAdminView />} />S
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageposts/:add'
              element={<PostForm />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageposts/:edit/:id'
              element={<PostForm />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path='/manageresources' element={<ResourceAdminView />} />S
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageresources/:add'
              element={<ResourceForm />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageresources/:edit/:id'
              element={<ResourceForm />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path='/manageusers' element={<UserAdminView />} />S
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageusers/:add'
              element={<UserForm />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route
              path='/manageusers/:edit/:id'
              element={<UserForm />}
            />
          </Route>
          
          <Route element={<RequireAuth allowedRoles={[ROLES.ADMIN]} />}>
            <Route path='/managereservations' element={<ReservationView />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
