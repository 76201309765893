import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import axios from '../../localApi/axios';

const RESOURCES_URL = '/resources';
const RESOURCES_BY_NAME_URL = '/resourcesbyname';
const RESOURCES_BY_CATEGORY_URL = '/resourcesbycategoryid';
const RESOURCES_BY_SUBCATEGORY_URL = '/resourcesbysubcategoryid';

const initialState = {
  loading: false,
  resources: [],
  error: '',
  categoryId: 0,
  subCategoryId: 0,
};

// Create, update and delete still have to be done
// const headers = {
//   'Content-Type': 'application/json',
// };

export const fetchResources = createAsyncThunk(
  'resources/fetchresources',
  async () => {
    return await axios.get(RESOURCES_URL).then((response) => response.data);
  }
);

export const fetchResourceById = createAsyncThunk(
  'resources/fetchResourceById',
  async (id) => {
    return await axios
      .get(`${RESOURCES_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const fetchResourceByName = createAsyncThunk(
  'resources/fetchResourceByName',
  async (id) => {
    const encodedId = encodeURIComponent(id)
    return await axios
      .get(`${RESOURCES_BY_NAME_URL}/${encodedId}`)
      .then((response) => response.data);
  }
);

export const fetchResourcesByCategoryId = createAsyncThunk(
  'resources/fetchresourcesbycategoryid',
 async ({ categoryId }) => {
    return await axios
      .get(`${RESOURCES_BY_CATEGORY_URL}/${categoryId}`)
      .then((response) => response.data);
  }
);

export const fetchResourcesBySubCategoryId = createAsyncThunk(
  'resources/fetchresourcesbysubcategoryid',
  async ({ subCategoryId }) => {
    return await axios
      .get(`${RESOURCES_BY_SUBCATEGORY_URL}/${subCategoryId}`)
      .then((response) => response.data);
  }
);

// TO DO:

export const updateResource = createAsyncThunk(
  'resources/updateresource',
  async ({ id, data }) => {
    const response =  await axios
      .patch(`${RESOURCES_URL}/${id}`, data)
      .catch((error) => console.log(error))
    return response.data
  }
)

export const deleteResource = createAsyncThunk(
  'resources/deleteresource',
  async(id) => {
    const response = await axios
      .delete(`${RESOURCES_URL}/${id}`)
      .catch((error) => console.log(error))
    return response.data
  }
)


export const createResource = createAsyncThunk(
  'resources/createresource',
  async (fields) => {
    //console.log('fields = ', fields);
    const response = await axios
      .post(RESOURCES_URL, fields)
      .catch((error) => console.log(error));
    return response.data;
  }
);
// arguments: action type, action payload

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    resourcesReset: (state) => {
      // Set the slice state back to its initial value
      //Object.assign(state, initialState);
      state.resources = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResources.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.loading = false;
      state.resources = action.payload;
      state.error = '';
    });
    builder.addCase(fetchResourceById.fulfilled, (state, action) => {
      state.loading = false;
      state.resources = action.payload;
      state.error = '';
    });
    builder.addCase(deleteResource.fulfilled, (state, action) => { 
      const { id } = action.payload;
      const resources = state.resources.filter((resource) => resource.resource_id !== id);
      state.resources = resources;
    });
    builder.addCase(fetchResources.rejected, (state, action) => {
      state.loading = false;
      state.resources = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchResourcesByCategoryId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResourcesByCategoryId.fulfilled, (state, action) => {
      state.loading = false;
      state.resources = action.payload;
      state.error = '';
    });
    builder.addCase(updateResource.fulfilled, (state, action) => {
      state.loading = false;
      state.status = 'succeeded';
      
    });
    builder.addCase(fetchResourcesByCategoryId.rejected, (state, action) => {
      state.loading = false;
      state.resources = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchResourcesBySubCategoryId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchResourcesBySubCategoryId.fulfilled,
      (state, action) => {
        state.loading = false;
        state.resources = action.payload;
        state.error = '';
      }
    );
    builder.addCase(fetchResourcesBySubCategoryId.rejected, (state, action) => {
      state.loading = false;
      state.resources = [];
      state.error = action.error.message;
    });
  },
});

export const { resourcesReset } = resourcesSlice.actions;
export default resourcesSlice.reducer;
