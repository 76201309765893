import React, { useState, useEffect, useContext } from 'react';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteReservation, fetchReservationsOverview, getReservations, updateReservationSmall } from './reservationsSlice';
import { ActionForm } from './ActionForm';
import { ConfirmationForm } from './ConfirmationForm';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { Button, Box } from '@mui/material';
import { Popup } from '../../components/Popup';
import { ApproveForm } from './ApproveForm';
import { PROJECT_TYPES } from './reservationConstants';
import { RESERVATION_COLUMNS } from './reservationConstants';
import { Filters } from '../../components/Filters';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { createDenialBody } from './eventUtils';
import { sendEmail } from './reservationsSlice';
import { format } from 'date-fns';
import { CreatorContext } from '../../context/CreatorProvider';

import '../../styles.css';
import './reservationView.css'
import { fetchBookedResourcesForId } from '../reservationresources/bookedResourcesSlice';
import { fetchBookedUsersForId } from '../reservationusers/bookedUsersSlice';

export const ReservationView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupApprove, setOpenPopupApprove] = useState(false)
    const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
   
    const [selectedReservation, setSelectedReservation] = useState([]);
    const [reservationsLoaded, setReservationsLoaded] = useState(false);
    const [reservationsToApprove, setReservationsToApprove] = useState([])
    const [approvedReservations, setApprovedReservations] = useState([])
    const [rowSelectionApproved, setRowSelectionApproved] = useState({})
    const [selectedResources, setSelectedResources] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [data, setData] = useState({})
    const [sorting, setSorting] = useState([])
    const [rowSelectionToApprove, setRowSelectionToApprove] = useState({})
    const [columnFilters, setColumnFilters] = useState([])
    const [remarks, setRemarks] = useState('')
    const { creator } = useContext(CreatorContext);
    const [rowSelection, setRowSelection] = useState({})
    const reservations = useSelector((state) => state.reservations.reservations);
    
    const [reservationsState, setReservationsState] = useState(reservations);

    

    // const toApprove = useMemo(() => [...reservationsToApprove], [reservationsToApprove]);
    // const approved = useMemo(() => [...approvedReservations], [approvedReservations])
    //const columns = useMemo(() => RESERVATION_COLUMNS, []);

    const tableToApprove = useReactTable(
        {
            data: reservationsToApprove,
            columns: RESERVATION_COLUMNS,
            state: {
                columnVisibility: { reservation_id: false, reservation_project_type: false, reservation_recurrent: false },
                sorting: sorting,
                rowSelection: rowSelectionToApprove,
                columnFilters,
                selectedRowIds:{},
                
            },
            //,
            sorting: sorting,
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: (selectedRowIds) => {
                    setRowSelectionToApprove(selectedRowIds); // Update row selection state for the first table
                },
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setReservationsToApprove(setFilterFunc);
                },
            }
        },
    )
    
    const tableApproved = useReactTable(
        {
            data: approvedReservations,
            columns: RESERVATION_COLUMNS,
            state: {
                columnVisibility: { reservation_id: false, reservation_project_type: false, reservation_recurrent: false  },
                sorting: sorting,
                rowSelection: rowSelectionApproved,
                columnFilters,
                selectedRowIds: {},
                
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: (selectedRowIds) => {
                    setRowSelectionApproved(selectedRowIds); // Update row selection state for the first table
                },
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setApprovedReservations(setFilterFunc);
                },
            }
        },
    )
    //console.log('columnFilters = ', columnFilters)


    const approveReservation = async (reservationId) => {
        await dispatch(updateReservationSmall({ id: reservationId, action: 'approve'}));
        

    // Update reservationsState with the updated array
        const updatedReservations = reservationsState.map((reservation) => {
            if (reservation.reservation_id === reservationId) {
                return { ...reservation, approved: 1 };
            }
                return reservation;
            });
        setReservationsState(updatedReservations);

        if (reservations && reservations.length > 0) {
            setReservationsToApprove(reservations.filter((reservation) => reservation.approved === 0))
            setApprovedReservations(reservations.filter((reservation) => reservation.approved === 1))
        }

        setOpenPopup(false);
        setSelectedReservation(null);
        setReservationsLoaded(false);
        tableToApprove.resetRowSelection()
        navigate(window.location.pathname, { replace: true });
    };

    // const denyReservation = async (reservationId) => {

    // }

    useEffect(() => {
        if (!reservationsLoaded) {
            //   dispatch(fetchReservationsOverview());
            dispatch(getReservations())
            setReservationsLoaded(true);
            
        } else {
        // Update reservationsState when reservations prop changes
            setReservationsState(reservations);
            console.log('reservations = ', reservations)
        }
        if (reservations && reservations.length > 0) {
            setReservationsToApprove(reservations.filter((reservation) => reservation.approved === 0))
            setApprovedReservations(reservations.filter((reservation) => reservation.approved === 1))
        }
    }, [dispatch, reservationsLoaded, reservations]);
    
    useEffect(() => {
        console.log('in useEffect on first visit')
        if (!reservationsLoaded) {
            // dispatch(fetchReservationsOverview());
            dispatch(getReservations())
            setReservationsLoaded(true);
            console.log('dispatch called because reservationsLoaded was ', reservationsLoaded)
        }
        if (reservations && reservations.length > 0) {
            setReservationsToApprove(reservations.filter((reservation) => reservation.approved === 0))
            setApprovedReservations(reservations.filter((reservation) => reservation.approved === 1))
        }
    }, [])


    const handleDetails = (row) => {
        setSelectedReservation(row[0].original);
        console.log('Selected reservation = ', selectedReservation)
        setOpenPopup(true)
    };

    const handleDelete = async (rows) => {
        const meta = tableApproved.options.meta
        let reservationString='';
        for (let i = 0; i < rows.length; i++ ) {
            const selected = rows[i].original.reservation_project_name
            console.log('selected = ', selected)
            if (i !== rows.length-1) {
                reservationString += selected + ', '
            } else {
                reservationString += selected + '?'
            }
        }
        const result = window.confirm(`Are you sure you want to delete reservation(s) ${reservationString}`);
        if (result) {
            console.log('inside result true')
            const deletedReservationIds = rows.map(row => row.original.reservation_id);
            for (let i = 0; i < deletedReservationIds.length; i++) {
                console.log('about to dispatch')
                await dispatch(deleteReservation(deletedReservationIds[i]));
            }
            meta.removeSelectedRows(tableApproved.getSelectedRowModel().rows.map(row => row.index))
            tableApproved.resetRowSelection()
        } else {
            navigate('/managereservations');
        }
  }

   const handleDeny = async (reservation, remarks) => {
        setRemarks(remarks);
        setSelectedReservation(reservation);

        // Fetch booked resources and users asynchronously
        const selectedResourcesPromise = dispatch(fetchBookedResourcesForId(reservation.reservation_id));
        const selectedUsersPromise = dispatch(fetchBookedUsersForId(reservation.reservation_id));

        // Wait for both promises to resolve
        const [selectedResources, selectedUsers] = await Promise.all([selectedResourcesPromise, selectedUsersPromise]);
       
        // Now you can proceed with the rest of the code
        setSelectedResources(selectedResources.payload)
        setSelectedUsers(selectedUsers.payload)

        let message = `Are you sure you want to deny reservation '${selectedReservation.reservation_project_name}'?`;
        setConfirmationMessage(message);
        setOpenPopupConfirmation(true);
    };

    const handleYesClick = async () => {
        const meta = tableToApprove.options.meta
        console.log('selectedReservation = ', selectedReservation);
        console.log('passed remarks = ', remarks)
        setOpenPopupConfirmation(false)
        const id = selectedReservation.reservation_id
        const formattedData = {
            ...selectedReservation,
            reservation_start_date: format(new Date(selectedReservation.reservation_start_date), 'yyyy-MM-dd HH:mm:ss'),
            reservation_end_date: format(new Date(selectedReservation.reservation_end_date), 'yyyy-MM-dd HH:mm:ss'),
            selectedResources,
            selectedUsers,
            created_by: creator.user_email,
        };
        console.log('formattedData = ', formattedData)
        const body = createDenialBody(remarks, selectedReservation);
        await dispatch(sendEmail({
          project: formattedData,
          action: 'deny',
          subject: `Your reservation for project ${selectedReservation.reservation_project_name} was denied.`,
          body: body
        }))
        await new Promise((resolve) => setTimeout(resolve, 1000))
        await (dispatch(deleteReservation(id)))
        setOpenPopup(false)
        meta.removeSelectedRows(tableToApprove.getSelectedRowModel().rows.map(row => row.index))
        tableApproved.resetRowSelection()
        //navigate(window.location.pathname, { replace: true });
        
    }
    
    const onCancelApprove = () => {
        setOpenPopup(false)
        setRowSelectionApproved({})
        setRowSelectionToApprove({})
    }
    const onCancelConfirmation = () => {
        setOpenPopupConfirmation(false)
        setRowSelectionApproved({})
        setRowSelectionToApprove({})
    }

    return (
        <>
            <div align='center' className='-table-adresourcemin'>
                 <div align='center'>
                    <h1>Reservations to approve</h1>
                </div>
                <Box sx={{ '& button': { m: 1 },  display: 'flex', alignItems: 'center', width: '80%' } }>
                    <div>
                        <Filters 
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}        
                        />
                    </div>  
                    <div className='button-next' style={{ marginLeft: 'auto' }}>
                            <Button disabled={(tableToApprove.getSelectedRowModel().flatRows).length !== 1} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleDetails(tableToApprove.getSelectedRowModel().flatRows)}>DETAILS</Button>
                    </div>
                </Box>    
            <table width={tableToApprove.getTotalSize()}>
                <thead>
                {tableToApprove.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {tableToApprove.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <br />
        <br />
        <div align='center' className='-table-adresourcemin'>
                <div align='center'><h1>Approved reservations</h1></div>    
                <Box sx={{ '& button': { m: 1 },  display: 'flex', alignItems: 'center', width: '80%' } }>
                        <div>
                            <Filters 
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}        
                            />
                        </div>  
                        <div className='button-next' style={{ marginLeft: 'auto' }}>
                                {/* <div className='button-next' style={{ marginLeft: 'auto' }}> */}
                                <Button disabled={(tableApproved.getSelectedRowModel().flatRows).length !== 1} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleDetails(tableApproved.getSelectedRowModel().flatRows)}>DETAILS</Button>
                                <Button disabled={(tableApproved.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(tableApproved.getSelectedRowModel().flatRows)}>DELETE</Button>
                                {/* </div> */}    
                        </div>
                </Box>       
            
            <table width={tableApproved.getTotalSize()}>
                <thead>
                {tableApproved.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {tableApproved.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
        </table>
            <div>
            {selectedReservation && (
                (openPopup ? (
                <Popup
                    openPopup={openPopup}
                    title={'Approve or deny'}
                >
                    <ApproveForm selectedReservation={selectedReservation} onSubmit={approveReservation} handleDeny={handleDeny} onCancel={onCancelApprove} />
                
                    </Popup>
                    ) : null)
                )}
            </div>
            <div>   
                {selectedReservation && (
                    <ConfirmationPopup
                        openPopupConfirmation={openPopupConfirmation}
                        title={'Confirm'} >
                        <ConfirmationForm
                            message={confirmationMessage}
                            onCancel={onCancelConfirmation}
                            onConfirm={handleYesClick}
                        />
                    </ConfirmationPopup>
                )}
                </div>
                </div>
            </>
        
    )

}