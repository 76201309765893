import React, {useState, useEffect } from 'react'
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch, useSelector} from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchResourceById, fetchResources} from './resourcesSlice';
import { fetchBookedResourcesForId } from '../reservationresources/bookedResourcesSlice';
import { appendResources, removeResources } from '../reservations/reservationsSlice';
import { appendTemplateResources, removeTemplateResources } from '../templates/templatesSlice';
import { Button, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { RESOURCE_COLUMNS } from './admin/adminConstants';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { ConfirmationForm } from '../reservations/ConfirmationForm';
import { Filters } from '../../components/Filters';



import '../../styles.css';


export const ResourceSelection = () => {
    const [resourceData, setResourceData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [columnFilters, setColumnFilters] = useState([])
    const [sorting, setSorting] = useState([])
    const [rowSelection, setRowSelection] = useState([])
    const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [selectedRowId, setSelectedRowId] = useState()
    const [rowSelectionSelectedResources, setRowSelectionSelectedResources] = useState({})
    const [rowSelectionResources, setRowSelectionResources] = useState({})

    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate()

    const id = params.id
    const origin = params.origin

    const selectedResources = useSelector((state) => state.reservations.selectedResources)
    const selectedTemplateResources = useSelector((state) => state.templates.selectedResources)
    console.log('selectedResources: ', selectedResources)
    console.log('selectedTemplateResources: ', selectedTemplateResources)
    //console.log('selectedResourcesTemplate: ', selectedResourcesTemplate)




    // console.log('id=', id)
    // console.log('origin=', origin)
    
    

    const getStyle = (row) => {
        let styleSetting = '';
        const theRow = row.original;
        if (theRow.resource_status !== 1) {
            //console.log('in IT');
            styleSetting = 'unavailable';

        }
        return styleSetting;
    }

    const SelectedResourcesTable = useReactTable(
        {
            data: selectedData,
            columns: RESOURCE_COLUMNS,
            state: {
                columnVisibility: { resource_category_id: false, resource_subcategory_id: false, status_id: false },
                sorting: sorting,
                rowSelection: rowSelectionSelectedResources,
                columnFilters,
                selectedRowIds: {},
                
            },
            //,
            
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: (selectedRowIds) => {
                    setRowSelectionSelectedResources(selectedRowIds); // Update row selection state for the first table
                },
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setSelectedData(setFilterFunc);
          //setOriginalData(setFilterFunc);
                },
            }

        },
    )
    
    const ResourcesTable = useReactTable(
        {
            data: resourceData,
            columns: RESOURCE_COLUMNS,
            state: {
                columnVisibility: { resource_category_id: false, resource_subcategory_id: false, status_id: false },
                sorting: sorting,
                rowSelection: rowSelectionResources,
                columnFilters,
                selectedRowIds: {},
                
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: (selectedRowIds) => {
                    setRowSelectionResources(selectedRowIds); // Update row selection state for the first table
                },
            enableRowSelection: true,
        },
    )
    console.log('columnFilters = ', columnFilters)

    const handleAdd = async (rows) => {
        //console.log('row is = ', rows)
        rows.map((row) => {
            let check;
            const availability = row.original.resource_status;
            console.log('availability = ', availability)
            const resourceId = row.original.resource_id
            if (availability !== 2) {
                //console.log('resourceId = ', resourceId)
                if (origin !== 'template') {// Check if the resource is not already selected
                    check = selectedResources.filter((resource) => resource.resource_id === resourceId)
                } else {
                    check = selectedTemplateResources.filter((resource) => resource.resource_id === resourceId)
                }
                //console.log('check = ', check)
                if (check.length === 0) {
                    dispatch(fetchResourceById(resourceId)).then((result) => {
                        const resource = result.payload;
                        setSelectedData((previousArray) => [...previousArray, resource]);
                        if (origin !== 'template') {
                            dispatch(appendResources(resource))
                        } else {
                            dispatch(appendTemplateResources(resource))
                            //console.log('selectedTemplateResources = ', selectedTemplateResources)
                        }
                
                
                    })
                }
            }
        })
        ResourcesTable.resetRowSelection(true)
        //ResourcesTable.setFilterValue('searchColumn', '')
    }

    const handleDelete = async (rows) => {
        const meta = SelectedResourcesTable.options.meta
        // let resourceString='';
        // for (let i = 0; i < rows.length; i++ ) {
        //     const selected = rows[i].original.resource_name
        //     console.log('selected = ', selected)
        //     if (i !== rows.length-1) {
        //         resourceString += selected + ', '
        //     } else {
        //         resourceString += selected + '?'
        //     }
        // }
        //const result = window.confirm(`Are you sure you want to delete resource ${resourceString}`);
        //if (result) {
        const deletedResourceIds = rows.map(row => row.original.resource_id);
            for (let i = 0; i < deletedResourceIds.length; i++) {
                if (origin !== 'template') {
                        await dispatch(removeResources(deletedResourceIds[i]));
                    } else {
                        dispatch(removeTemplateResources(deletedResourceIds[i]))
                    }
            }
            meta.removeSelectedRows(SelectedResourcesTable.getSelectedRowModel().rows.map(row => row.index))
            SelectedResourcesTable.resetRowSelection()
        // } else {
        //     navigate('/manageresources');
        // }
  }
    

    const onCancelConfirmation = () => {
        setOpenPopupConfirmation(false)
    }
    
    useEffect(() => {
        
        (async () => {
            let result=[]            
            result = await dispatch(fetchResources())
            console.log('result = ', result)
            setResourceData(result.payload);
            
            if (origin !== 'template') {
                console.log('origin is no template in resource selection')
                selectedResources.map((resource) => {
                    dispatch(fetchResourceById(resource.resource_id)).then((result) => {
                        const resource = result.payload;
                        setSelectedData((previousArray) => [...previousArray, resource]);
                    })
                })
            } else {
                console.log('origin is template in resource selection')
                selectedTemplateResources.map((resource) => {
                    dispatch(fetchResourceById(resource.resource_id)).then((result) => {
                        const resource = result.payload;
                        setSelectedData((previousArray) => [...previousArray, resource]);
                    })
                })
            }

          console.log('state selected data = ', selectedData)
      })();
    }, []);

    return (
    <>        
        <div align='center'>
            <Box sx={{ width: '80%'}}>
                    <div className='button-align-left'>
                        <Button
                            variant='contained'
                            style={{ textAlign: 'left' }}
                            onClick={() =>
                                origin !== 'template'
                                ? navigate(`/addreservation/${id}/${origin}`)
                                    : origin === 'fromNew' ?
                                        navigate(`/addreservation/0/${origin}`)
                                        : navigate(`/managetemplates/edit/${id}`)
                            }
                        >
                            Back
                        </Button>
                </div>
            </Box>
            <h1>Selected Resources</h1>
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>  
            <div className='button-next' style={{ marginLeft: 'auto' }}>
                <Button disabled={(SelectedResourcesTable.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(SelectedResourcesTable.getSelectedRowModel().flatRows)}>DELETE</Button>
            </div>
            </Box>
            <table width={SelectedResourcesTable.getTotalSize()}>
                <thead>
                {SelectedResourcesTable.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {SelectedResourcesTable.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <br />
        <br />
        <div align='center'>
            <h1>Resources</h1>
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>  
              <div>
                <Filters 
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}        
                />
              </div>
              <div className='button-next'>
                  <Button variant='contained' sx = {{marginLeft: 'auto'}} onClick={() => handleAdd(ResourcesTable.getSelectedRowModel().flatRows)}>
                    Add
                  </Button>
              </div>      
            </Box>      
            <br />
            <br />
            <table width={ResourcesTable.getTotalSize()}>
                <thead>
                {ResourcesTable.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {ResourcesTable.getRowModel().rows.map(row =>
                        <tr key={row.id} className={getStyle(row)}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
        </table>
        <br />
            <div className='pagination-style'>
              <Button variant='text' onClick={()=> ResourcesTable.setPageIndex(0)} size='small'>First</Button>
              <Button disabled={!ResourcesTable.getCanPreviousPage()} variant='text' onClick={()=>ResourcesTable.previousPage()} size='small'>Previous</Button>
              <Button disabled={!ResourcesTable.getCanNextPage()} variant='text' onClick={()=>ResourcesTable.nextPage()} size='small'>Next</Button>
              <Button variant='text' onClick={()=>ResourcesTable.setPageIndex(ResourcesTable.getPageCount()-1)} size='small'>Last</Button>
            </div>
            </div>
            <ConfirmationPopup
                            openPopupConfirmation={openPopupConfirmation}
                            title={'Confirm'} >
                            <ConfirmationForm
                                message={confirmationMessage}
                                onCancel={onCancelConfirmation}
                                //onConfirm={handleYesClick}
                            />
                        </ConfirmationPopup>
            
    </>
  )
}


