import { useRef, useState, useEffect } from 'react';
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import axios from './localApi/axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Register.css';
import { green } from '@mui/material/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paper: {
    width: '25%',
    padding: '20px',
    textAlign: 'center',
  },
};

const USER_REGEX = /^[A-z][A-z0-9-_@.]{3,72}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

export const Register = () => {
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [validUser, setValidUser] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    const result = USER_REGEX.test(user);
    setValidUser(result);
  }, [user]);

  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg('Invalid Entry');
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      setSuccess(true);
      setUser('');
      setPwd('');
      setMatchPwd('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg('Registration Failed');
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <section>
          <Grid style={styles.container}>
            <Grid item xs={10} sm={8} md={6}>
              <Typography variant='h4' color='green' align='center'>
                Your password was successfully registered!
              </Typography>
              <br />
              <Typography variant='h6' color='green' align='center'>
                <Link to='/login'>Log in.</Link>
              </Typography>
            </Grid>
          </Grid>
        </section>
      ) : (
        <section>
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live='assertive'
            align='center'
          >
            {errMsg}
          </p>
          <Grid style={styles.container}>
            <Grid item xs={10} sm={8} md={6}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant='h4' align='center'>
                  Register
                </Typography>
                <form onSubmit={handleSubmit}>
                  <label htmlFor='user'>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validUser ? 'valid' : 'hide'}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validUser || !user ? 'hide' : 'invalid'}
                    />
                  </label>
                  <TextField
                    id='user'
                    label='Email'
                    ref={userRef}
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    type='email'
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    aria-invalid={validUser ? 'false' : 'true'}
                    aria-describedby='uidnote'
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                    autoComplete='off'
                    required
                  />
                  <label htmlFor='password'>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validPwd ? 'valid' : 'hide'}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validPwd || !pwd ? 'hide' : 'invalid'}
                    />
                  </label>
                  <TextField
                    id='password'
                    label='Password'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    type='password'
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    autoComplete='off'
                    required
                    aria-invalid={validPwd ? 'false' : 'true'}
                    aria-describedby='pwdnote'
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  />
                  <p
                    id='pwdnote'
                    className={
                      pwdFocus && !validPwd ? 'instructions' : 'offscreen'
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{' '}
                    <span aria-label='exclamation mark'>!</span>{' '}
                    <span aria-label='at symbol'>@</span>{' '}
                    <span aria-label='hashtag'>#</span>{' '}
                    <span aria-label='dollar sign'>$</span>{' '}
                    <span aria-label='percent'>%</span>
                  </p>
                  <label htmlFor='confirm_pwd'>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={validMatch && matchPwd ? 'valid' : 'hide'}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={validMatch || !matchPwd ? 'hide' : 'invalid'}
                    />
                  </label>
                  <TextField
                    id='confirm_pwd'
                    label='Confirm password'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    type='password'
                    onChange={(e) => setMatchPwd(e.target.value)}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch ? 'false' : 'true'}
                    aria-describedby='confirmnote'
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                  />
                  <p
                    id='confirmnote'
                    className={
                      matchFocus && !validMatch ? 'instructions' : 'offscreen'
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Must match the first password input field.
                  </p>
                  <br />
                  <br />
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={
                      !validUser || !validPwd || !validMatch ? true : false
                    }
                  >
                    Register
                  </Button>
                </form>
                <br />
                <span>
                  <p>
                    Password already registered?{' '}
                    <Link to='/login'>Log in.</Link>
                  </p>
                </span>
              </Paper>
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
};
