import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { Grid, Button, TextField } from '@mui/material';
import { PROJECT_TYPES } from './reservationConstants';
import { rrulestr, RRule, RRuleSet } from 'rrule';
import { fetchBookedResourcesForId } from '../reservationresources/bookedResourcesSlice';
import { fetchBookedUsersForId } from '../reservationusers/bookedUsersSlice';
import { deleteReservation } from './reservationsSlice';
import { ConfirmationPopup } from '../../components/ConfirmationPopup'
import { ConfirmationForm } from './ConfirmationForm'
import { format } from 'date-fns';

export const ApproveForm = ({ selectedReservation, onSubmit, onCancel, handleDeny }) => {
  
  const [resources, setResources] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch()
  const [reasonDenial, setReasonDenial] = useState('');
  const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false)
  const [message, setMessage] = useState('')

  const handleReasonDenialChange = (event) => {
    setReasonDenial(event.target.value);
  };

  

  console.log('selectedReservation=', selectedReservation)
  const getProjectType = (value) => {
      const projType = PROJECT_TYPES.find((obj) => obj.value === value);
      console.log('project type = ', projType);
      return projType.label;
  };
  
  const getProjectResources = async (value) => {
    try {
      const result = await dispatch(fetchBookedResourcesForId(value));
      const resources = result.payload;
      console.log('resources = ', resources);
      setResources(resources); ;
    } catch (error) {
      console.error('Error fetching resources:', error);
      setResources([]); // Return an empty array or handle the error as needed
    }
  }

  const getProjectUsers = async (value) => {
    try {
      const result = await dispatch(fetchBookedUsersForId(value));
      const users = result.payload;
      console.log('users = ', users);
      setUsers(users); ;
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]); // Return an empty array or handle the error as needed
    }
  }

  async function fetchResources() {
    const resources = await getProjectResources(selectedReservation.reservation_id);
    return resources.map((resource) => (
      <li key={resource.resource_id}>{resource.resource_name}</li>
    ));
  }

  async function fetchUsers() {
    const users = await getProjectUsers(selectedReservation.reservation_id);
    return users.map((user) => (
      <li key={user.user_id}>{user.user_email}</li>
    ));
  }
    
  const translateRruleToText = (rruleString, start, end) => {
    console.log('rruleString = ', rruleString)
    console.log('start = ', start)
    console.log('end = ', end)
        let humanReadableText = ''
        let humanReadableRule = ''
        console.log('rruleString = ', rruleString)
        const rruleLines = rruleString.split('\n');
        const rruleOptions = rruleLines.find((line) => line.startsWith('RRULE:'));
        if (!rruleOptions) return "Invalid RRULE test Options";

        if (!rruleOptions) {
            return "Invalid RRULE: Missing RRULE options.";
        }

        const weekdaysMatch = rruleOptions.match(/BYDAY=([A-Z,]+)/);
        const monthdaysMatch = rruleOptions.match(/BYMONTHDAY=([+-]?\d+)/);
        const dayOfMonthMatch = rruleOptions.match(/BYMONTHDAY=([+-]?\d+)/);

        if (!weekdaysMatch && !monthdaysMatch && !dayOfMonthMatch) {
        return "Invalid RRULE: Missing or invalid BYDAY, BYMONTHDAY, and BYMONTHDAY options.";
        }

        if (weekdaysMatch) {
            const weekdays = weekdaysMatch[1].split(',');
            const weekdaysText = weekdays.map((day) => day).join(', ');
            humanReadableRule = `Every ${weekdaysText}.`;
        }
        if (monthdaysMatch) {
            const monthdays = monthdaysMatch[1].split(',');
            const monthdaysText = monthdays.map((day) => day).join(', ');
            humanReadableRule = `Every ${monthdaysText}.`;
        }
        if (dayOfMonthMatch) {
            const dayOfMonth = parseInt(dayOfMonthMatch[1]);
            humanReadableRule = `Every ${ordinalSuffix(dayOfMonth)} day of the month.`;
        }

        const excludedDates = parseExcludedDates(rruleString);
        // const startTime = format(new Date(start), 'HH:mm')
        // const endTime = format(new Date(end), 'HH:mm')
       
        const excludedDatesText = excludedDates
            .filter((date) => !isNaN(date.getTime()))
            .map(formatDate)
            .map((date, index) => <li key={index}>{date}</li>);

        humanReadableText = (
            <>
            {`${humanReadableRule} from ${start} to ${end}`}
            <br />
            {excludedDatesText.length > 0 ? (
                <>
                {'except on:'}
                <ul>
                    {excludedDatesText}
                </ul>
                </>
            ) : (
                ' No exceptions.'
            )}
            </>
        );
    console.log('humanReadableText = ', humanReadableText)        
    return humanReadableText;
        
  };
  
  function ordinalSuffix(number) {
        const j = number % 10;
        const k = number % 100;
        if (j === 1 && k !== 11) {
            return number + "st";
        }
        if (j === 2 && k !== 12) {
            return number + "nd";
        }
        if (j === 3 && k !== 13) {
            return number + "rd";
        }
        return number + "th";
  }
  
  function parseExcludedDates(rruleString) {
        const excludedDates = [];
        const lines = rruleString.split('\n');
        for (const line of lines) {
            if (line.startsWith('EXDATE:')) {
                line.replace('EXDATE', ';EXDATE')
                const exdateString = line.substring('EXDATE:'.length);
                const exdateValues = exdateString.split(',');
                exdateValues.forEach((dateString) => {
                    // Convert the date string to a valid format (e.g., 'YYYY-MM-DDTHH:mm:ssZ')
                    const formattedDateString = dateString.slice(0, 4) + '-' + dateString.slice(4, 6) + '-' + dateString.slice(6, 11) + ':' + dateString.slice(11, 13) + ':' + dateString.slice(13);
                    const date = new Date(formattedDateString);
                    if (!isNaN(date.getTime())) {
                        excludedDates.push(date);
                    }
                });
            }
        }
        return excludedDates;
  }

  function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
        
  }
  
  const removeExDates = (rulestring) => {
        
        const lines = rulestring.split('\n');
        const modifiedLines = lines.filter(line => !line.startsWith('EXDATE:'));
        const modifiedRruleString = modifiedLines.join('\n');
        const rule = RRule.fromString(modifiedRruleString);
        const occurrences = rule.all();
        const dtStart = occurrences.length > 0 ? occurrences[0] : null;
        const until = occurrences.length > 0 ? occurrences[occurrences.length - 1] : null;
        return [dtStart, until];
    };
  
  const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit(selectedReservation.reservation_id)
  }

  const onDeny = (event) => {
    event.preventDefault();
    console.log('deny button clicked')
    handleDeny(selectedReservation, reasonDenial);
  }

  // const handleDeny = (event) => {
  //   event.preventDefault();
  //   onDelete(selectedReservation.reservation_id)
  // }

  useEffect(() => {
    fetchResources();
    fetchUsers()
  }, [selectedReservation.reservation_id]);

  // Render the resources once they are available
  const resourceList = resources.map((resource) => (
    <li key={resource.resource_id}>{resource.resource_name}</li>
  ));

  const userList = users.map((user) => (
    <li key={user.user_id}>{user.user_email}</li>
  ));

  

  const onCancelConfirmation = () => {
    setOpenPopupConfirmation(false)
  }
    
  return (
    <form onSubmit={handleSubmit}>
    <Grid container>
      <Grid item xs={4} hidden>
        ID
      </Grid>
      <Grid item xs={8} hidden>
        {selectedReservation.reservation_id}
      </Grid>
      <Grid item xs={4}>
        Project Name:
      </Grid>
      <Grid item xs={8}>
        {selectedReservation.reservation_project_name}
      </Grid>
      <Grid item xs={4}>
        Project Type:
      </Grid>
      <Grid item xs={8}>
        {getProjectType(selectedReservation.reservation_project_type)}
        {/* {selectedReservation.reservation_project_type} */}
      </Grid>
      
      <Grid item xs={12}>
        <hr />
      </Grid>
      <Grid item xs={4}>
          Resources:
      </Grid>
      <Grid item xs={8}>
         {resourceList}
      </Grid>
      <Grid item xs={12 }>
        <hr />
      </Grid>
      <Grid item xs={4}>
          Users
      </Grid>
      <Grid item xs={8}>
        {userList}
        </Grid>
        <Grid item xs={12 }>
        <hr />
      </Grid>
      
      <>
        {selectedReservation.reservation_recurrent === 0 ? (
          <>
            <Grid item xs={4}>
              Start Date:
            </Grid>
            <Grid item xs={8}>
              {selectedReservation.reservation_start_date}
            </Grid>

            <Grid item xs={4}>
              End Date:
            </Grid>
            <Grid item xs={8}>
              {selectedReservation.reservation_end_date}
              </Grid>
              <Grid item xs={12 }>
                <hr />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              Start Date:
            </Grid>
            <Grid item xs={8}>
              {format(removeExDates(selectedReservation.reservation_rrule)[0], 'dd/MM/yyyy')}
            </Grid>

            <Grid item xs={4}>
              End Date:
            </Grid>
            <Grid item xs={8}>
              {format(removeExDates(selectedReservation.reservation_rrule)[1], 'dd/MM/yyyy')}
              </Grid>    
                
            <Grid item xs={4}>
              Recurrence:
            </Grid>
            <Grid item xs={8}>
                  {translateRruleToText(selectedReservation.reservation_rrule,
                    format(new Date(selectedReservation.reservation_start_date), 'HH:mm'),
                    format(new Date(selectedReservation.reservation_end_date), 'HH:mm'))}
                </Grid>
                <Grid item xs={12 }>
                  <hr />
              </Grid>
          </>
        )}
      </>
      <Grid item xs={4}>
        Template:
      </Grid>
      <Grid item xs={8}>
        {selectedReservation.reservation_template === 0 ? 'NO' : 'YES'}
      </Grid>
      <Grid item xs={4}>
        Created By:
      </Grid>
      <Grid item xs={8}>
        {selectedReservation.created_by}
      </Grid>
      <Grid item xs={4}>
        Created On:
      </Grid>
      <Grid item xs={8}>
        {selectedReservation.created_on}
      </Grid>

      <Grid item xs={4}>
        Remarks:
      </Grid>
      <Grid item xs={8}>
        {selectedReservation.reservation_remarks}
      </Grid>
      <Grid item xs={12} marginTop='25px'>
        <TextField
          name='reason_denial'
          multiline
          maxRows={10}
          label='Reason for denial'
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { fontFamily: 'UGent' },
          }}
          InputProps={{ sx: { height: 200 } }}
          value={reasonDenial}
          onChange={handleReasonDenialChange}
        />
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" marginTop='25px'>
            <Grid item>
                <Button
                          id='cancel'
                          variant='outlined'
                          size='small'
                          align="left"
                          onClick={ onCancel}>
                    CANCEL
                </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} align="center">
                <Button variant='contained' size='small' type='submit'>
                    APPROVE
                </Button>
            </Grid>
            <Grid item>
                <Button variant='contained' size='small' align="right" onClick={onDeny}>
                    DENY
                </Button>
            </Grid>
              </Grid>
      </Grid>
    </form>
    
  )
}


