import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { deleteResource, fetchResources } from '../resourcesSlice';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { RESOURCE_COLUMNS } from './adminConstants'
import { Button, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Filters} from '../../../components/Filters'

import '../../../styles.css';

export const ResourceAdminView = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [data, setData] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
   

  const getStyle = (row) => {
    let styleSetting = '';
    //console.log('row = ', row)
    const theRow = row.original;
    if (theRow.resource_status !== 1) {
      //console.log('in IT');
      styleSetting = 'unavailable';

    }
    return styleSetting;
  }
  

  const table = useReactTable(
    {
      data: data,
      columns: RESOURCE_COLUMNS,
      state: {
        columnVisibility: { resource_category_id: false, resource_subcategory_id: false, status_id: false },
        globalFilter,
        sorting: sorting,
        rowSelection: rowSelection,
        columnFilters,
                
      },
      getCoreRowModel: getCoreRowModel(),
      onGlobalFilterChange: setGlobalFilter,
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
      getFilteredRowModel: getFilteredRowModel(),
      onRowSelectionChange: setRowSelection,
      enableRowSelection: true,
      meta: {
        removeSelectedRows: (selectedRows) => {
          const setFilterFunc = (old) =>
          old.filter((_row, index) => !selectedRows.includes(index));
          setData(setFilterFunc);
          //setOriginalData(setFilterFunc);
        },
      }
    },
  )

  
  
  const handleCreate = () => {
    navigate('/manageresources/add')
  }

  const handleEdit = (row) => {
    const resourceId = row[0].original.resource_id;
    navigate(`/manageresources/edit/${resourceId}`);
  }

  
  const handleDelete = async (rows) => {
    const meta = table.options.meta
    let resourceString='';
    for (let i = 0; i < rows.length; i++ ) {
      const selected = rows[i].original.resource_name
      console.log('selected = ', selected)
      if (i !== rows.length-1) {
        resourceString += selected + ', '
      } else {
        resourceString += selected + '?'
      }
    }
    const result = window.confirm(`Are you sure you want to delete resource ${resourceString}`);
    if (result) {
      const deletedResourceIds = rows.map(row => row.original.resource_id);
      // Dispatch deleteResource action for each resource
      for (let i = 0; i < deletedResourceIds.length; i++) {
        await dispatch(deleteResource(deletedResourceIds[i]));
      }
      meta.removeSelectedRows(table.getSelectedRowModel().rows.map(row => row.index))
      table.resetRowSelection()
    } else {
      navigate('/manageresources');
   }
  }

  useEffect(() => {
      (async () => {
          const result = await dispatch(fetchResources())
          console.log('result = ', result.payload)
          setData(result.payload);
      })();
  }, []);

    

    return (
        <div align='center' className='-table-adresourcemin'>
            <h1>Manage Resources</h1>
            <br />
            <br />
            <Box sx={{ '& button': { m: 1 },  display: 'flex', alignItems: 'center', width: '80%' } }>
              <div>
                  <Filters 
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}        
                  />
              </div>  
            <div className='button-next' style={{ marginLeft: 'auto' }}>
                    {/* <div className='button-next' style={{ marginLeft: 'auto' }}> */}
                    <Button disabled={table.getIsSomeRowsSelected()} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleCreate()}>CREATE</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length !== 1} startIcon={<EditOutlinedIcon />} variant='contained' onClick={() => handleEdit(table.getSelectedRowModel().flatRows)}>EDIT</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(table.getSelectedRowModel().flatRows)}>DELETE</Button>
                    {/* </div> */}
                </div>
            </Box>          
            <br />
            <table width={table.getTotalSize()}>
                <thead>
                {table.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        <tr key={row.id} className={getStyle(row)}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
        </table>
        <br />
            <div className='pagination-style'>
              <Button variant='text' onClick={()=> table.setPageIndex(0)} size='small'>First</Button>
              <Button disabled={!table.getCanPreviousPage()} variant='text' onClick={()=>table.previousPage()} size='small'>Previous</Button>
              <Button disabled={!table.getCanNextPage()} variant='text' onClick={()=>table.nextPage()} size='small'>Next</Button>
              <Button variant='text' onClick={()=>table.setPageIndex(table.getPageCount()-1)} size='small'>Last</Button>
          </div>
        <hr />
        {/* <div>
          {console.log(table.getSelectedRowModel().flatRows)}
          {console.log(getSelection())}
        </div> */}
      </div>
  )
}
