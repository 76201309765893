import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import axios from '../../localApi/axios';

const TEMPLATES_URL = '/templates';


const initialState = {
    loading: false,
    templates: [],
    selectedResources: [],
    selectedUsers: [],
    error: '',
}

export const fetchTemplates = createAsyncThunk(
  'templates/fetchtemplates',
  async () => {
    return await axios.get(TEMPLATES_URL).then((response) => response.data);
  }
);

export const getTemplateById = createAsyncThunk(
  'template/gettemplatebyid',
  async (id) => {
    return await axios
      .get(`${TEMPLATES_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const updateTemplate = createAsyncThunk(
  'resources/updatetemplate',
  async ({ id, data }) => {
    console.log('id = ', id)
    console.log('data = ', data)
    const response =  await axios
      .post(`${TEMPLATES_URL}/${id}`, data)
      .catch((error) => console.log(error))
    return response.data
  }
)

export const deleteTemplate = createAsyncThunk(
  'resources/deletetemplate',
  async(id) => {
    const response = await axios
      .delete(`${TEMPLATES_URL}/${id}`)
      .catch((error) => console.log(error))
    return response.data
  }
)

export const createTemplate = createAsyncThunk(
  'templates/createtemplate',
  async (fields) => {
    console.log('fields = ', fields);
    const response = await axios
      .post(TEMPLATES_URL, fields)
      .catch((error) => console.log(error));
    return response.data;
  }
)

export const fetchTemplateById = createAsyncThunk(
  'templates/fetchtemplatebyid',
  async (id) => {
    return await axios
      .get(`${TEMPLATES_URL}/${id}`)
      .then((response) => response.data);
  }
);
// arguments: action type, action payload

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
  reducers: {
    templateReset: (state) => {
      // Set the slice state back to its initial value
      //Object.assign(state, initialState);
      state.templates = [];
      state.selectedResources = [];
      state.selectedUsers = []
    },
    setSelectedTemplate: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.templates = action.payload;
      console.log('In slice. Reservations is : ', state.reservations)
    },
    setSelectedResources: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.selectedResources = action.payload;
      console.log('In slice. selectedResources[] is : ', state.selectedResources)
    },
    setSelectedUsers: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.selectedUsers = action.payload;
      console.log('In slice. selectedUsers[] is : ', state.selectedResources)
    },
    appendTemplateResources: (state, action) => {
      console.log('Slice, append, payload = ', action.payload)
      state.selectedResources.push(action.payload);
      console.log('state.selectedResources = ', state.selectedResources)
    },
    appendTemplateUsers: (state, action) => {
      console.log('Slice, append, payload = ', action.payload)
      state.selectedUsers.push(action.payload);
      console.log('state.selectedUsers = ', state.selectedUsers)
    },
    removeTemplateResources: (state, action) => {
      const resourceIdToRemove = action.payload;
      console.log('resource id to remove is: ', resourceIdToRemove)
      state.selectedResources = state.selectedResources.filter(
        (resource) => resource.resource_id !== resourceIdToRemove
      );
      console.log('state.resources after filter is:', state.selectedResources)
    },
    removeTemplateUsers: (state, action) => {
      const userIdToRemove = action.payload;
      console.log('user id to remove is: ', userIdToRemove)
      state.selectedUsers = state.selectedUsers.filter(
        (user) => user.user_id !== userIdToRemove
      );
      console.log('state.users after filter is:', state.selectedUsers)
    },
  },
    
    extraReducers: (builder) => {
        builder.addCase(fetchTemplates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTemplates.fulfilled, (state, action) => {
            state.loading = false;
            state.templates = action.payload;
            state.error = '';
        });
        builder.addCase(deleteTemplate.fulfilled, (state, action) => {
            const { id } = action.payload;
            const templates = state.templates.filter((template) => template.template_id !== id);
            state.templates = templates;
        });
        builder.addCase(fetchTemplates.rejected, (state, action) => {
            state.loading = false;
            state.templates = [];
            state.error = action.error.message;
        });
        builder.addCase(updateTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(createTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
        });
        builder.addCase(fetchTemplateById.fulfilled, (state, action) => {
            state.loading = false;
            state.status = 'succeeded';
            state.templates = action.payload
        });
    }
})

export const { templateReset, appendTemplateResources, appendTemplateUsers, setSelectedResources, setSelectedUsers, setSelectedTemplate, removeTemplateUsers, removeTemplateResources } = templatesSlice.actions;
export default templatesSlice.reducer;
