export const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'resource_id',
  },
  {
    Header: 'Name',
    accessor: 'resource_name',
  },
  {
    Header: 'Description',
    accessor: 'resource_description',
  },
  {
    Header: 'Location',
    accessor: 'resource_location',
  },
  {
    Header: 'category_id',
    accessor: 'resource_category_id',
  },
  {
    Header: 'Category',
    accessor: 'category_name',
  },
  {
    Header: 'subcategory_id',
    accessor: 'resource_subcategory_id',
  },
  {
    Header: 'Sub-Category',
    accessor: 'subcategory_name',
  },
  {
    Header: 'Status',
    accessor: 'status_name',
  },
];

export const CATEGORIES = [
  { value: 0, label: 'Select the category' },
  { value: 1, label: 'RESEARCH' },
  { value: 2, label: 'EDUCATION' },
  { value: 3, label: 'DEPARTMENT' },
];

export const SUBCATEGORIES = [
  { value: 0, label: 'Select the subcategory...' },
  { value: 1, label: 'KINE' },
  { value: 2, label: 'LOGO/AUDIO' },
  { value: 3, label: 'nvt' },
];

export const RESOURCE_STATUSSES = [
  { value: 0, label: 'Select the status...' },
  { value: 1, label: 'AVAILABLE' },
  { value: 2, label: 'UNAVAILABLE' },
  { value: 3, label: 'MAINTENANCE' },
];

