import { configureStore } from '@reduxjs/toolkit';
import postsReducer from '../features/bulletinboard/postsSlice';
import usersReducer from '../features/users/usersSlice';
import resourcesReducer from '../features/resources/resourcesSlice';
import reservationsReducer from '../features/reservations/reservationsSlice';
import bookedResourcesReducer from '../features/reservationresources/bookedResourcesSlice';
import bookedUsersReducer from '../features/reservationusers/bookedUsersSlice';
import templatesReducer from '../features/templates/templatesSlice'


export const store = configureStore({
  reducer: {
    posts: postsReducer,
    users: usersReducer,
    resources: resourcesReducer,
    reservations: reservationsReducer,
    bookedResources: bookedResourcesReducer,
    bookedUsers: bookedUsersReducer,
    templates: templatesReducer
  },
});
