import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../localApi/axios'

// DEV
const POSTS_URL = '/posts';

/* PRODUCTION */
//const POSTS_URL = 'https://revareservatie.ugent.be/reva-api/posts';


const initialState = {
  posts: [],
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
  const response = await axios.get(POSTS_URL);
  return response.data;
});

export const getPostById = createAsyncThunk(
  'posts/getPostById',
  async (id) => {
    return await axios
      .get(`${POSTS_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const addNewPost = createAsyncThunk(
  'posts/addNewPost',
  async ( initialPost ) => {
    console.log('New Post in slice is:', initialPost);
    const response = await axios
      .post(POSTS_URL, initialPost)
      .catch((error) => console.log(error));
    return response.data;
  }
);

export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async ({id, data}) => {
      const response = await axios.put(`${POSTS_URL}/${id}`, data);
      return response.data
  }
);

export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async ( id ) => {
    console.log('In Axios call, id is ', id);
    return await axios
      .delete(`${POSTS_URL}/${id}`)
      .then((response) => response.data);
  }
);

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    //     postAdded: {
    //       reducer(state, action) {
    //         state.posts.push(action.payload);
    //       },
    //       prepare(postId, postTitle, postType, postBody) {
    //         return {
    //           payload: {
    //             postId,
    //             postTitle,
    //             postType,
    //             postBody,
    //           },
    //         };
    //       },
    //     },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = action.payload;
        state.error = '';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Adding date and reactions
        //const loadedPosts = action.payload.map((post) => {
        state.posts = action.payload;
        //return post;
      })

      // Add any fetched posts to the array
      //state.posts = state.posts.concat(loadedPosts);
      //   }),
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addNewPost.fulfilled, (state, action) => {
        //action.payload.posted_on = new Date().toISOString();
        console.log(action.payload);
        state.posts.push(action.payload);
      })
      .addCase(updatePost.fulfilled, (state, action) => {
      state.loading = false;
      state.status = 'succeeded';
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        console.log('In slice reducer...');
        const id = action.payload;
        console.log('action=', action);
        state.posts = state.posts.filter((post) => post.post_id !== id);
      });
  },
});

export const selectAllPosts = (state) => state.posts.posts;
export const getPostsStatus = (state) => state.posts.status;
export const getPostsError = (state) => state.posts.error;

export const selectPostById = (state, postId) =>
  state.posts.posts.find((post) => post.post_id === postId);

export const { postAdded } = postsSlice.actions;

export default postsSlice.reducer;
