import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

export const Popup = (props) => {
    const { title, children, openPopup, openPopupInfo, openPopupAction, translateRruleToText } = props;
    console.log('children : ', props.children)


    return (
            (openPopupInfo || openPopup ? (
                <Dialog open={openPopupInfo || openPopup}>
                    <DialogTitle bgcolor='#1e64c8' color='white'>
                        <h3 align='center'>{title}</h3>
                    </DialogTitle>
                    <DialogContent dividers>{children}</DialogContent>
                </Dialog>
        ) : (
                <Dialog open={openPopupAction}>
                    <DialogTitle bgcolor='#1e64c8' color='white'>
                        <h3 align='center'>{title}</h3>
                    </DialogTitle>
                    <DialogContent dividers>
                    {translateRruleToText ? React.cloneElement(children, { translateRruleToText }) : children}
                    </DialogContent>
                </Dialog>
                )
            )
        )
};
