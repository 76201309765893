import React, { useState,useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    createTemplate, updateTemplate, fetchTemplateById, fetchTemplates,
    appendTemplateResources, appendTemplateUsers, templateReset,
    setSelectedResources, setSelectedUsers, setSelectedTemplate
} from './templatesSlice'
import { useForm, Controller } from "react-hook-form";
import { DevTool } from '@hookform/devtools';
import { PROJECT_TYPES } from './templateConstants';
import { TextField, MenuItem, Box, Button, Select, FormControl, InputLabel, FormHelperText, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchResourceByName, fetchResourceById } from '../resources/resourcesSlice';
import { fetchResources, resourcesReset } from '../resources/resourcesSlice';
import { fetchUserByEmail, fetchUserById, fetchUsers, usersReset } from '../users/usersSlice';
import { useFormContext } from '../../context/FormProvider';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';

export const TemplateForm = () => {
    
    const [existingResources, setExistingResources] = useState([]);
    const [existingUsers, setExistingUsers] = useState([]);

    const template = useSelector((state) => state.templates.templates)
    const selectedResources = useSelector((state) => state.templates.selectedResources)
    const selectedUsers = useSelector((state) => state.templates.selectedUsers)

    console.log('template = ', template)
    console.log('template_resources = ', selectedResources)
    console.log('template_users = ', selectedUsers)

    const { register, control, reset, formState, setValue, getValues, handleSubmit } = useFormContext({
        defaultValues: {
            template_id: 0,
            project_name: '',
            project_type: 0,
            selectedResources,
            selectedUsers,
        }
    })
    const { errors } = formState;
    const { current: resetForm } = useRef(reset);

    const params = useParams();
    const id = params.id;
    const origin = params.origin;
    console.log('id = ', id)
    const isAddMode = id === undefined || id === null || isNaN(id) || Number(id) === 0;
    console.log('isAddMode = ', isAddMode)
    console.log('origin = ', origin)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const onSubmit = (data) => {
        if (isAddMode) {
            create(data);
            console.log('in Add mode')
            console.log('data = ', data)
        } else {
            update(data)
            console.log('in Edit mode')
            console.log('data = ', data)
            //update(data);
        }
    };

    const create = async () => {
        const values = getValues();
        console.log('values = ', values)
        const data = {
            template_id: 0,
            project_name: values.project_name,
            project_type: values.project_type,
            selectedResources,
            selectedUsers

        }
        console.log('data in create = ', data)
        try {
            await dispatch(createTemplate(data));
            resetForm();
            //console.log('Form has been reset.')
            navigate('/managetemplates');
        } catch (error) {
            console.error('Error creating template:', error.message);
        }
    };

    const update = async () => {
        const values = getValues();
        const data = {
            template_id: values.template_id,
            project_name: values.project_name,
            project_type: values.project_type,
            selectedResources,
            selectedUsers

        }
        console.log('data in update = ', data)
        try {
            await dispatch(updateTemplate({ id: id, data: data }));
            resetForm();
            console.log('Form has been reset.')
            navigate('/managetemplates');
        } catch (error) {
            console.error('Error updating template:', error.message);
        }
    };
    
    useEffect(() => {
        console.log('isAddMode = ', isAddMode)
        const fetchData = async () => {
            try {
                if (!isAddMode) {
                    dispatch(fetchTemplateById(id)).then(async action => {
                        const theTemplate = action.payload;
                        console.log('theTemplate is : ', theTemplate)
                        const templateData = theTemplate[0].templateData;
                        const resources = theTemplate[1].templateResources;
                        const users = theTemplate[2].templateUsers
                        

                        console.log('templateData = ', templateData)
                        console.log('resources = ', selectedResources)
                        console.log('users = ', selectedUsers)
                        dispatch(setSelectedTemplate(templateData))
                        //dispatch(setSelectedResources(selectedResources))
                        //dispatch(setSelectedUsers(selectedUsers))
                        await dispatch(setSelectedResources(resources))
                        await dispatch(setSelectedUsers(users))

                        const fields = ['template_id', 'project_name', 'project_type'];
                        fields.forEach((field) => {
                            setValue(field, templateData[0][field], {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true
                            });
                        });
                    })
                } 
            } catch (error) {
                console.error('Error fetching reservation data:', error);
            }
        }
        fetchData()
    }, []);

    return (
        <div align='center'>
            <h1>{isAddMode ? 'Add Template' : 'Edit Template'}</h1>
            <br />
            <Box sx={{ '& .MuiTextField-root': { m: 1, width: '75ch' }, '& .MuiSelect-select': { fontFamily: 'UGent', m: 1, minWidth: 120 } }}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div>
                        <TextField
                            hidden
                            id='templateId'
                            {...register('template_id')}
                        />
                    </div>
                    <div>
                        <TextField
                            id='projectName'
                            label='Project Name'
                            InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                            {...register('project_name', {
                                required: {
                                    value: true,
                                    message: 'Project name is required'
                                }
                            })}
                            error={!!errors.project_name}
                            helperText={errors.project_name?.message}
                            inputProps={{ style: { fontFamily: 'UGent' } }}
                        />
                    </div>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                            <InputLabel id="project_type" style={{ fontFamily: 'UGent' }} shrink={true}>Project Type</InputLabel>
                            <Controller
                                name="project_type"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label="Project Type"
                                        error={!!errors.project_type}
                                    >
                                        {PROJECT_TYPES.map((type) => (
                                            <MenuItem
                                                key={type.value}
                                                value={type.value}
                                                sx={{ fontFamily: 'UGent' }}
                                            >
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.project_type && (
                                <FormHelperText>{errors.project_type.message}</FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div>
                        <Box sx={{ m: 1, width: '75ch' }}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
                                        onClick={() => {
                                            dispatch(fetchResources())
                                                .then(() => {
                                                    const formValues = getValues();
                                                    if (isAddMode) {
                                                       navigate(`/selectresources/${'0'}/template`)
                                                    } else {
                                                        navigate(`/selectresources/${formValues.template_id}/template`);
                                                    }
                                                    
                                                });
                                            }}
                                    >
                                        RESOURCES
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
                                        onClick={() => {
                                            dispatch(fetchUsers())
                                                .then(() => {
                                                    const formValues = getValues();
                                                    if (isAddMode) {
                                                       navigate(`/selectusers/${'0'}/template`)
                                                    } else {
                                                        navigate(`/selectusers/${formValues.template_id}/template`);
                                                    }
                                                    
                                                });
                                            }}
                                    >
                                        USERS
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <br />
                    <br />
                    <div>
                        <Button
                            id='cancel'
                            variant='outlined'
                            sx={{ margin: 1 }}
                            onClick={() => {
                                resetForm()
                                dispatch(fetchTemplates()).then(() => {
                                    navigate('/managetemplates');
                                })
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button variant='contained' id='submit' type='submit' sx={{ margin: 1 }}>
                            SUBMIT
                        </Button>
                    </div>
                </form>
                <DevTool control={control} />
            </Box>
        </div>
    )
}