import React from 'react'
import { Grid, Button } from '@mui/material';
import { findTypeByValue } from './eventUtils';

export const ActionForm = ({ selectedData, onEdit, onDelete, onDeleteSeries, onCancel }) => {
    const [data] = selectedData
    console.log('data in actionform is : ', data)

    const handleSubmit = (e, action) => {
    e.preventDefault(); // Prevent the default form submission
    if (action === 'edit') {
      onEdit(); // Call the onEdit function when the form is submitted for editing
    } else if (action === 'deleteSeries') {
      onDeleteSeries(); // Call the onDeleteSeries function when the form is submitted for deleting a series
    } else if (action === 'delete') {
      onDelete(); // Call the onDelete function when the form is submitted for deleting
    }
  };
    
  return (
      <form>
    <Grid container>
        <Grid item xs={4}>
            Project name:
        </Grid>
        <Grid item xs={8}>
            {data.projectName}
        </Grid>
        <Grid item xs={4}>
            Project type:
        </Grid>
        <Grid item xs={8}>
            {findTypeByValue(data.type)}
        </Grid>
        <Grid item xs={4}>
            Resources:         
        </Grid>
        <Grid item xs={8}>
            <ul>
                {data.resources.map((resource,index) => (
                    <li key={index}>{resource.resource_name}</li>
                ))}       
            </ul>
              </Grid>
        <Grid item xs={4}>
            Users:         
        </Grid>
        <Grid item xs={8}>
            <ul>
                {data.users.map((user,index) => (
                    <li key={index}>{user.user_email}</li>
                ))}       
            </ul>
        </Grid>
        {!data.recurrenceRule ? (
            <>
                <Grid item xs={4}>
                    Start date:
                </Grid>
                <Grid item xs={8}>
                    {data.projectStart}
                </Grid>
                <Grid item xs={4}>
                    End date:
                </Grid>
                <Grid item xs={8}>
                   {data.projectEnd}
                </Grid>
            </>
        
            ) : (
            <>
                
                <Grid item xs={4}>
                    Start date:
                </Grid>
                <Grid item xs={8}>
                    {data.projectStart}
                </Grid>
                <Grid item xs={4}>
                    End date:
                </Grid>
                <Grid item xs={8}>
                   {data.projectEnd}
                </Grid>
                <Grid item xs={4}>
                    Recurrence:
                </Grid>
                <Grid item xs={8}>
                    {data.recurrenceRule}
                </Grid>
            </>
        )}
        <Grid item xs={4}>
            Created by:
        </Grid>
        <Grid item xs={8}>
            {data.createdBy}
              </Grid>
        <Grid item xs={4} hidden>
            internal id:
        </Grid> 
        <Grid item xs={8} hidden>
            {data.internalId}
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" marginTop='25px'>
            <Grid item>
                    <Button
                          id='cancel'
                          variant='outlined'
                          size='small'
                          
                          onClick={onCancel}>
                    CANCEL
                    </Button>
            </Grid>
            <Grid item>
                    <Button
                          id='edit_block'
                          variant='contained'
                          size='small'
                          
                          onClick={(e) => handleSubmit(e, 'edit')}>
                    EDIT
                    </Button>
                  </Grid>
                  {data.recurrenceRule ? (
                      <Grid item>
                          <Button
                              id='delete_series'
                              variant='contained'
                              size='small'
                          
                              onClick={(e) => handleSubmit(e, 'deleteSeries')}>
                              DELETE SERIES
                          </Button>
                      </Grid>) : ('')
                  } 
            <Grid item>
                    <Button
                          id='delete_occurrence'
                          variant='contained'
                          size='small'
                          
                          onClick={(e) => handleSubmit(e, 'delete')}>
                    DELETE
                    </Button>
            </Grid>
        </Grid>
    </Grid>
    </form>
  )
}
