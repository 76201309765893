import React from 'react'
import { Grid, Button } from '@mui/material';
import { RRule } from 'rrule';
import { findTypeByValue } from './eventUtils';


export const InfoForm = ({ selectedData, onCancel }) => {
    const [data] = selectedData

    console.log('Selected data in infoform = ', selectedData)

   
    
    
    
  return (
    <form onCancel={ onCancel}>
    <Grid container>
        <Grid item xs={4}>
            Resource name:
        </Grid>
        <Grid item xs={8}>
            {data.resourceName}
        </Grid>
        <Grid item xs={4}>
            Project title:
        </Grid>
        <Grid item xs={8}>
            {data.projectName}
        </Grid>
        <Grid item xs={4}>
            Project type
        </Grid>
        <Grid item xs={8}>
            {findTypeByValue(data.type)}
        </Grid>
            {!data.recurrenceRule ? (
            <>
                <Grid item xs={4}>
                    Start date:
                </Grid>
                <Grid item xs={8}>
                    {data.resourceStart}
                </Grid>
                <Grid item xs={4}>
                    End date:
                </Grid>
                <Grid item xs={8}>
                   {data.resourceEnd}
                      </Grid>
            </>
        
            ) : (
            <>
                <Grid item xs={4}>
                    Start date:              
                </Grid>
                <Grid item xs={8}>
                    {data.projectStart}
                </Grid>
                <Grid item xs={4}>
                    End date:              
                </Grid>
                <Grid item xs={8}>
                    {data.projectEnd}              
                </Grid>
                <Grid item xs={4}>
                    Recurrence:
                </Grid>
                <Grid item xs={8}>
                    {data.recurrenceRule}
                </Grid>
            </>
            )}
                  
        <Grid item xs={4}>
            Created by:
        </Grid>
        <Grid item xs={8}>
            {data.createdBy}
              </Grid>
        <Grid item xs={4} hidden>
            internal id:
        </Grid> 
        <Grid item xs={8} hidden>
            {data.internalId}
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" marginTop='25px'>
            <Grid item>
                      <Button
                          id='cancel'
                          variant='outlined'
                          size='small'
                          align="left"
                          onClick={ onCancel}>
                    CANCEL
                </Button>
            </Grid>
        </Grid>
    </Grid>
    </form>
  )
}
