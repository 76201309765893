import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../localApi/axios';

/* DEV */
 const RESERVATION_RESOURCES_URL =
  '/reservationresources';
const BOOKED_RESOURCES_URL = '/bookedresources'

/* PROD */
// const RESERVATION_RESOURCES_URL =
//   'https://revareservatie.ugent.be/reva-api/reservationresources';


const initialState = {
  resources: [],
  status: 'idle',
  error: null,
};

export const fetchBookedResources = createAsyncThunk(
  'bookedresources/fetchbookedresources',
  async () => {
    return await axios
      .get(RESERVATION_RESOURCES_URL)
      .then((response) => response.data);
  }
);

export const fetchBookedResourcesForId = createAsyncThunk(
  'bookedresources/fetchbookedresourcesforid',
  async (id) => {
    return await axios
      .get(`${RESERVATION_RESOURCES_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const fetchBookedResourcesWithRruleForId = createAsyncThunk(
  'bookedusers/fetchbookedresourceswithrruleforid',
  async (id) => {
    return await axios
      .get(`${BOOKED_RESOURCES_URL}/${id}`)
      .then((response) => response.data);
  }

)

export const updateResourceRruleForId = createAsyncThunk(
  'bookedresources/updateresourcerruleforid',
  async ({ rrule, id }) => {
    return await axios
      .patch(`${BOOKED_RESOURCES_URL}/${id}?rrule=${encodeURIComponent(rrule)}`)
      .then((response) => response.data)
  }
)

const bookedResourcesSlice = createSlice({
  name: 'bookedresources',
  initialState,
  reducers: {
    bookedResourcesReset: (state) => {
      // Set the slice state back to its initial value
      state.resources = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookedResources.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookedResources.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.resources = action.payload;
      })
      .addCase(fetchBookedResources.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBookedResourcesForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.resources = action.payload;
      })
      .addCase(updateResourceRruleForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload
      })
      .addCase(fetchBookedResourcesWithRruleForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload
      });
  },
});

export const selectBookedResources = (state) => state.bookedResources.resources;
export const selectBookedResourcesStatus = (state) =>
  state.bookedResources.status;

export const { bookedResourcesReset } = bookedResourcesSlice.actions;

export default bookedResourcesSlice.reducer;
