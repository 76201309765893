import { useState } from 'react';
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { RRule } from 'rrule';
import RRuleGenerator from 'react-rrule-generator';
import moment from 'moment-timezone';
import 'moment-timezone';

export const RecurrenceModal = ({ isOpen, onClose, updateRrule }) => {
  console.log('RecurrenceModal - isOpen:', isOpen);
  console.log('RecurrenceModal - onClose:', onClose);
  console.log('RecurrenceModal - updateRrule:', updateRrule);

  const [rrule, setRrule] = useState('');

  const handleClose = () => onClose();
  //const handleShow = () => onClose(true);

  const handleRrule = () => {
    if (rrule) {
      //console.log('rrule in modal is:', rrule);
      updateRrule(rrule);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onClose={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Recurrence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RRuleGenerator
            border='10px'
            config={{
              hideStart: false,
              allDay: false,
              repeat: ['Monthly', 'Weekly', 'Daily'],
              yearly: 'on',
              weekly: 'on',
              daily: 'on',
              start: ['datetime'],
              end: ['On date'],
              weekStartsOnSunday: false,
              hideError: true,
            }}
            onChange={(rruleString) => {
              const parsedOptions = RRule.parseString(rruleString);
              const timeZone = 'Europe/Brussels';

              // Adjust dtstart and until by adding 1 day and setting the time to midnight
              const startDate = moment
                .utc(parsedOptions.dtstart)
                .add(1, 'day')
                .startOf('day');
              const untilDate = moment
                .utc(parsedOptions.until)
                .add(1, 'day')
                .startOf('day');

              const localStartDate = startDate.clone().tz(timeZone);
              const localUntilDate = untilDate.clone().tz(timeZone);

              console.log('localStartDate:', localStartDate.format()); // Log the local dates
              console.log('localUntilDate:', localUntilDate.format()); // Log the local dates

              const options = {
                ...parsedOptions,
                dtstart: localStartDate.toDate(),
                until: localUntilDate.toDate(),
              };

              const rruleInstance = new RRule(options);
              const updatedRrule = rruleInstance.toString();

              console.log('updatedRrule:', updatedRrule); // Log the updated RRule

              setRrule(updatedRrule);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='variant'
            type='cancel'
            sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          {rrule && (
            <Button
              sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
              variant='contained'
              onClick={handleRrule}
            >
              SET
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
