import { useRef, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from './components/hooks/useAuth';
import { CreatorContext } from './context/CreatorProvider';

import { TextField, Button, Grid, Paper, Typography } from '@mui/material';
import axios from './localApi/axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import { appendUsers } from './features/reservations/reservationsSlice';


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paper: {
    width: '25%',
    padding: '20px',
    textAlign: 'center',
  },
};

/* DEV */
const LOGIN_URL = '/auth';

export const Login = () => {
  const { setAuth } = useAuth();
  const { creator, loginUser, logoutUser } = useContext(CreatorContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/home';

  const userRef = useRef();
  const errRef = useRef();

  //   const [email, setEmail] = useState('');
  //   const [role, setRole] = useState('');
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  //   const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          //withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const roles = [response?.data?.user_role];
      setAuth({ user, pwd, roles, accessToken });
      setUser('');
      setPwd('');
      const creatorData = {
        user_id: response?.data?.user_id,
        user_name: response?.data?.user_name,
        user_first_name: response?.data?.user_first_name,
        user_last_name: response?.data?.user_last_name,
        user_email: (response?.data?.user_email).toLowerCase(),
        user_phone: response?.data?.user_phone,
        user_role: response?.data?.user_role,
        user_language: response?.data?.user_language,
        user_department: response?.data?.user_department,
      };
      loginUser(creatorData);
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  };

  return (
    <section>
      <p
        ref={errRef}
        className={errMsg ? 'errmsg' : 'offscreen'}
        aria-live='assertive'
      >
        {errMsg}
      </p>
      <Grid style={styles.container}>
        <Grid item xs={10} sm={8} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant='h4' align='center'>
              Login
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                id='user'
                label='User'
                ref={userRef}
                variant='outlined'
                fullWidth
                margin='normal'
                type='email'
                value={user}
                onChange={(e) => setUser(e.target.value)}
                autoComplete='off'
                required
              />
              <TextField
                id='password'
                label='Password'
                variant='outlined'
                fullWidth
                margin='normal'
                type='password'
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                autoComplete='off'
                required
              />
              <br />
              <br />
              <Button type='submit' variant='contained' color='primary'>
                Sign in
              </Button>
            </form>
            <br />
            <span>
              <p>
                No password yet?{' '}
                <Link to='/register'>Register your password.</Link>
              </p>
            </span>
          </Paper>
        </Grid>
      </Grid>
    </section>
  );
};
