import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

export const ConfirmationPopup = (props) => {
    const { title, children, openPopupConfirmation, onCancel, onConfirm, onDelete } = props;
  return (
    <Dialog open={openPopupConfirmation}>
        <DialogTitle bgcolor='#1e64c8' color='white'>
            <h3 align='center'>{title}</h3>
        </DialogTitle>
          <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}

