import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import { fetchUsers, usersReset } from './usersSlice';
import { appendUsers } from '../reservations/reservationsSlice';
import useAuth from '../../components/hooks/useAuth';
import { CreatorContext } from '../../context/CreatorProvider';

import { Button } from '@mui/material';
import { Checkbox } from '../../assets/Checkbox';
import { UserFilter } from './UserFilter';

import { COLUMNS } from './userConstants';
import { UserSelection } from './UserSelection';
import '../../styles.css';
import { id } from 'date-fns/locale';

export const UserView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const { creator } = useContext(CreatorContext);

  const users = useSelector((state) => state.users.users);

  const selectedUsers = useSelector(
    (state) => state.reservations.selectedUsers
  );
  console.log('Users at load of page = ', users);
  console.log('selectedUsers at load of page = ', selectedUsers);

  const navToFinalize = () => {
    if (selectedFlatRows.length > 0) {
      selectedFlatRows.map((row) => {
        console.log('row.user_email = ', row.user_email);
        console.log('auth.user = ', auth.user);
        const selectedUser = row.original;
        dispatch(appendUsers(selectedUser));
      });
    } else {
      // no selected users
    }
    dispatch(appendUsers(creator));
    navigate('/finalizereservation/0/new');
    // } else {
    // }
  };

  const clickAdd = () => {
    if (selectedFlatRows.length > 0) {
      selectedFlatRows.map((row) => {
        const selectedUser = row.original;
        const exists = selectedUsers.some(
          (user) => user.user_id === selectedUser.user_id
        );
        console.log('exists = ', exists);
        if (!exists) {
          dispatch(appendUsers(selectedUser));
        }
        console.log('selectedUsers = ', selectedUsers);
      });
    }
  };

  useEffect(() => {
    const resetAndFetchUsers = async () => {
      // Dispatch the usersReset action to clear the users state
      await dispatch(usersReset());
      // Fetch the resources after resetting
      await dispatch(fetchUsers());
      // Clear the session storage
    };
    resetAndFetchUsers();
  }, []);

  useEffect(() => {
    console.log('Updated selectedUsers:', selectedUsers);
  }, [selectedUsers]);

  const data = useMemo(() => [...users], [users]);
  const columns = useMemo(() => COLUMNS, []);

  // Create table instance
  const tableInstance = useTable(
    {
      columns: columns, // we could ommit the repeat
      data: data, // dito
      initialState: {
        hiddenColumns: ['user_id', 'user_name'],
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <div align='center' className='resource-table-admin'>
      {selectedUsers && selectedUsers.length >= 1 ? (
        <>
          <h1>Reservation delete/add users</h1>
          <h3>Select your users and click Add or Delete</h3>
          <UserSelection selectedUsers={selectedUsers} />
        </>
      ) : (
        <>
          <h1>Reservation - Step 2: select users</h1>
          <h3>Select your additional users and click next.</h3>
          <h5>(You are automatically added, so do not select yourself.)</h5>
        </>
      )}
      <div align='center' className='user-table-admin'>
        <br />
        <div>
          <UserFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
        <div>{console.log(selectedFlatRows.map((row) => row.original))};</div>
        <br />
        {selectedUsers && selectedUsers.length >= 1 ? (
          <div className='button-next'>
            <Button variant='contained' onClick={clickAdd}>
              Add
            </Button>
          </div>
        ) : (
          <div className='button-next'>
            <Button variant='contained' onClick={navToFinalize}>
              Next
            </Button>
          </div>
        )}
        <div>
          <br />
        </div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render('Header')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>

        <div>
          <br />
        </div>
        <div className='pagination-style'>
          <span className='page-index'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
            &nbsp;
          </span>
          <span className='goto-page'>
            | Go to page&nbsp;
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              style={{ width: '50px', fontSize: '12px' }}
            />
            &nbsp;
          </span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className='page-size-select'
          >
            {[10, 15, 20, 25, 50, 75].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <button
            className='pagination-button'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            className='pagination-button'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Previous
          </button>
          <button
            className='pagination-button'
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </button>
          <button
            className='pagination-button'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
        </div>
      </div>
    </div>
  );

  // Note: "Cell" is a prop from COLUMNS but is not shown nor defined
};
