// UserContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CreatorContext = createContext();

export const CreatorProvider = ({ children }) => {
  const [creator, setCreator] = useState(null);

  const loginUser = (userData) => {
    // Set the user data after successful login
    //console.log('userData = ', userData);
    setCreator(userData);
  };

  useEffect(() => {
    //console.log('Creator from state is = ', creator);
  }, [creator]);

  const logoutUser = () => {
    // Clear the user data on logout
    setCreator(null);
  };

  return (
    <CreatorContext.Provider value={{ creator, loginUser, logoutUser }}>
      {children}
    </CreatorContext.Provider>
  );
};
