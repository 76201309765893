import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import { parseISO, format, isSameDay } from 'date-fns';
import { RRule, rrulestr } from 'rrule';
import { PROJECT_TYPES } from './reservationConstants';



export const findTypeByValue = (key) => {
  const projType = PROJECT_TYPES.find((type) => type.value === key);
  return projType.label
}

export function filterBookingsById(selectedResources, bookedResources, id) {
  console.log('id in filter is: ', id);
  console.log('Type of id:', typeof id);
  let filteredBookings = [];
  
  // resources are in start/end date format
  console.log('selectedResources in filterBooking is ', selectedResources);
  selectedResources.forEach((selectedResource) => {
    filteredBookings.push(
      ...bookedResources.filter(
        (resource) => resource.resource_id === selectedResource.resource_id
      )
    );
  })
  

   
  
  console.log('filteredBookings:', filteredBookings);
  return filteredBookings;
}

export function createMyEvents(reservations, bookedResources, bookedUsers) {
    //console.log('Inside createMyEvents.');
  let eventsArray = [];
  let theEvent = [];
  reservations.map((reservation) => {
    if (reservation && reservation.reservation_rrule !== undefined) {
      console.log('in map, reservation = ', reservation)
      console.log('in map, reservation rrule = ', reservation.reservation_rrule)
      console.log('in map, reservation id = ', reservation.reservation_id)
      if (reservation.reservation_rrule === '') {
        console.log('NON RECURRING EVENT')
        theEvent = {
          id: `${reservation.reservation_id}_${new Date(reservation.reservation_start_date).toISOString()}}`,
          projectId: reservation.reservation_id,
          reservationId: reservation.reservation_id,
          start: format(
            new Date(reservation.reservation_start_date),
            'yyyy-MM-dd HH:mm:ss'
          ),
          end: format(
            new Date(reservation.reservation_end_date),
            'yyyy-MM-dd HH:mm:ss'
          ),
          title: reservation.reservation_project_name,
          type: reservation.reservation_project_type,
          resources: bookedResources.filter(
            (resource) => resource.reservation_id === reservation.reservation_id
          ),
          users: bookedUsers.filter(
            (user) => user.reservation_id === reservation.reservation_id
          ),
          display: 'block',
          backgroundColor: reservation.approved === 1 ? 'orange' : 'gray',
          createdBy: reservation.created_by,
          isRecurrent: false
        
        };
        console.log('theEvent = ', theEvent);
        eventsArray.push(theEvent);
      } else {
        console.log('In is rrule in myreservations')
        const rule = rrulestr(reservation.reservation_rrule);
        const occurrences = rule.all();
        const occStart = TimeExtractor(reservation.reservation_start_date);
        const occEnd = TimeExtractor(reservation.reservation_end_date);
        console.log('occurrences = ', occurrences)
        console.log('occStart = ', occStart)
        console.log('occEnd = ', occEnd)
        occurrences.forEach((occurrence) => {
          console.log('occurrence = ', occurrence)
          const occurrenceDate = new Date(occurrence)
          const formattedDate = occurrenceDate.toISOString().split('T')[0];
          theEvent = {
            id: `${reservation.reservation_id}_${formattedDate}`,
            start: `${formattedDate}T${occStart}:00`,
            end: `${formattedDate}T${occEnd}:00`,
            projectId: reservation.reservation_id,
            reservationId: reservation.reservation_id,
            title: reservation.reservation_project_name,
            type: reservation.reservation_project_type,
            project: reservation.reservation_project_name,
            createdBy: reservation.created_by,
            resources: bookedResources.filter(
              (resource) => resource.reservation_id === reservation.reservation_id
            ),
            users: bookedUsers.filter(
              (user) => user.reservation_id === reservation.reservation_id
            ),
            display: 'block',
            backgroundColor: reservation.approved === 1 ? 'green' : '#c8a2c8',
            isRecurrent: true,
            rruleString: reservation.reservation_rrule.toString()
          };
          eventsArray.push(theEvent);
          console.log('theEvent = ', theEvent);
        })
      }
    }  
        }
  
  )
    //console.log('events = ', eventsArray);
    return eventsArray
}

export function translateRruleToText(rruleString) {
        const rrule = rrulestr(rruleString);
        const recurrenceText = rrule.toText();

        return recurrenceText;
    };

export function createEvents(selectedResources, bookedResources, id, type) {
  console.log('passed id = ', id)
  let resources = []
  const eventsArray = [];
  if (!bookedResources) {
    return [];
  }
  resources = filterBookingsById(selectedResources, bookedResources, id)
  let theEvent = [];
  
  resources.map((resource) => {
    //console.log('resource in createEvents = ', resource)
    if (resource.rRule !== '' && resource.rRule !== null) {
      //console.log('type of rrule is : ', typeof resource.rRule)
      const formattedString = (resource.rRule).replace(/(RRULE:|EXDATE:)/g, "\n$1");
      const rule = rrulestr(formattedString)
      const occurrences = rule.all()
      const occStart = TimeExtractor(resource.start_date);
      const occEnd = TimeExtractor(resource.end_date);

      occurrences.forEach((occurrence) => {
        const occurrenceDate = new Date(occurrence)
        const formattedDate = occurrenceDate.toISOString().split('T')[0];
        theEvent = {
          id: `${resource.reservation_id}_${formattedDate}`,
          projectId : parseInt(resource.reservation_id),
          title: resource.resource_name,
          type: type !== 0 ? type : '', 
          project: resource.reservation_project_name,
          start: `${formattedDate}T${occStart}:00`,
          end: `${formattedDate}T${occEnd}:00`,
          createdBy: resource.created_by,
          resource: resource.resource_id,
          display: 'block',
          backgroundColor: 'green',
          isRecurrent: true,
          rruleString: resource.rRule
        };

        eventsArray.push(theEvent);
      })     
    } else {
      theEvent = {
        id: `${resource.reservation_id}_${resource.reservation_id}`,
        projectId: parseInt(resource.reservation_id),
        title: resource.resource_name,
        project: resource.reservation_project_name,
        type: type !== 0 ? type : '', 
        createdBy: resource.created_by,
        start: format(
                    new Date(resource.start_date),
                    'yyyy-MM-dd HH:mm:ss'
        ),
        end: format(
                    new Date(resource.end_date),
                    'yyyy-MM-dd HH:mm:ss'
        ),
        resource: resource.resource_id,        
        display: 'block',
        backgroundColor: 'orange',
        isRecurrent: false,
        rruleString: ''
      };

      eventsArray.push(theEvent);
    };
  })
  //console.log(eventsArray);
  return eventsArray;
}

export function createFormEvents(resources, users, startDateTime, endDateTime, rrule) {
  const formEvents = [];
  //const recurringevents = [];
  let theEvent = [];
  let projectId;
  
  resources.forEach((resource) => {
    
    console.log('resource in forEach is ', resource)
    if (resource.reservation_id !== '' || resource.reservation_id !== undefined) {
      projectId = resource.reservation_id
    }
    if (rrule !== '') {
      theEvent = {
        projectId,
        start: format(
                    new Date(startDateTime),
                    'yyyy-MM-dd HH:mm'
        ),
        end: format(
                    new Date(endDateTime),
                    'yyyy-MM-dd HH:mm'
        ),
        resource: resource.resource_id, 
        rrule: rrule,
        title: resource.resource_name,
        user: users,
        
      };
      formEvents.push(theEvent);
    } else {
      theEvent = {
        projectId,
        start: format(new Date(startDateTime), 'yyyy-MM-dd HH:mm'),
        end: format(new Date(endDateTime), 'yyyy-MM-dd HH:mm'),
        title: resource.resource_name,
        resource: resource.resource_id,
        user: users,
        rrule: '',
      };

      formEvents.push(theEvent);
    }
  });

  //console.log('Events from createFormEvents', formEvents);
  return formEvents;
}





function checkNonRecurringConflicts(existingEvent, newEvent, occurrenceStart, occurrenceEnd, conflicts) {

  const existingEventStart = parseISO(existingEvent.start);
  const existingEventEnd = parseISO(existingEvent.end);
  
  if (
    occurrenceStart.getTime() >= existingEventStart.getTime() &&
    occurrenceStart.getTime() <= existingEventEnd.getTime() &&
    newEvent.resource === existingEvent.resource
  ) {
    conflicts.push({
      resource: newEvent.resource,
      start: occurrenceStart,
      title: newEvent.title,
      end: occurrenceEnd,
      type: 'start time conflict',
    });
  }

  if (
    occurrenceEnd.getTime() >= existingEventStart.getTime() &&
    occurrenceEnd.getTime() <= existingEventEnd.getTime() &&
    newEvent.resource === existingEvent.resource
  ) {
    conflicts.push({
      resource: newEvent.resource,
      start: occurrenceStart,
      title: newEvent.title,
      end: occurrenceEnd,
      type: 'end time conflict',
    });
  }

  if (
    occurrenceStart.getTime() <= existingEventStart.getTime() &&
    occurrenceEnd.getTime() >= existingEventEnd.getTime() &&
    newEvent.resource === existingEvent.resource
  ) {
    conflicts.push({
      resource: newEvent.resource,
      start: occurrenceStart,
      title: newEvent.title,
      end: occurrenceEnd,
      type: 'overlap conflict',
    });
  }
}

export function checkConflictingEvents(existingEvents, newEvents, rrule) {
  const conflicts = [];
  // console.log('existingEvents = ', existingEvents)
  // console.log('newEvents = ', newEvents)
  newEvents.forEach((newEvent) => {
    const startTime = newEvent.start.split(' ')[1];
    const endTime = newEvent.end.split(' ')[1];
    existingEvents.forEach((existingEvent) => {
      const existingEventStart = new Date(existingEvent.start);
      const existingEventEnd = new Date(existingEvent.end);
      // console.log('existingEventStart = ', existingEventStart);
      // console.log('existingEventEnd = ', existingEventEnd);

      // Check for conflicts with non-recurring events
      if (
        newEvent.start < existingEvent.end &&
        newEvent.end > existingEvent.start &&
        newEvent.resource === existingEvent.resource
      ) {
        conflicts.push({
          resource: newEvent.resource,
          start: newEvent.start,
          title: newEvent.title,
          end: newEvent.end,
          type: 'overlap conflict',
        });
      }
    })
  })
  if (rrule !== '') {
    const formattedString = rrule.replace(/(RRULE:|EXDATE:)/g, '\n$1');
    const rule = rrulestr(formattedString);
    const ruleOccurrences = rule.all();

    newEvents.forEach((newEvent) => {
      const startTime = newEvent.start.split(' ')[1];
      const endTime = newEvent.end.split(' ')[1];

      ruleOccurrences.forEach((occurrence) => {
        const occurrenceDate = occurrence.toISOString().split('T')[0];
        const occurrenceStart = new Date(`${occurrenceDate} ${startTime}`);
        const occurrenceEnd = new Date(`${occurrenceDate} ${endTime}`);
        // console.log('occurrenceStart = ', occurrenceStart)
        // console.log('occurrenceEnd = ', occurrenceEnd)

        existingEvents.forEach((existingEvent) => {
          const existingEventStart = new Date(existingEvent.start);
          const existingEventEnd = new Date(existingEvent.end);
          // console.log('existingEventStart = ', existingEventStart)
          // console.log('existingEventEnd = ', existingEventEnd)

          if (
            occurrenceStart.getTime() >= existingEventStart.getTime() &&
            occurrenceStart.getTime() <= existingEventEnd.getTime() &&
            newEvent.resource === existingEvent.resource
          ) {
            conflicts.push({
              resource: newEvent.resource,
              start: occurrenceStart,
              title: newEvent.title,
              end: occurrenceEnd,
              type: 'start time conflict',
            });
          }

          if (
            occurrenceEnd.getTime() >= existingEventStart.getTime() &&
            occurrenceEnd.getTime() <= existingEventEnd.getTime() &&
            newEvent.resource === existingEvent.resource
          ) {
            conflicts.push({
              resource: newEvent.resource,
              start: occurrenceStart,
              title: newEvent.title,
              end: occurrenceEnd,
              type: 'end time conflict',
            });
          }
          if (
            occurrenceStart.getTime() >= existingEventStart.getTime() &&
            occurrenceEnd.getTime() <= existingEventEnd.getTime() &&
            newEvent.resource === existingEvent.resource
          ) {
            conflicts.push({
              resource: newEvent.resource,
              start: occurrenceStart,
              title: newEvent.title,
              end: occurrenceEnd,
              type: 'overlap conflict',
            });
          }
        });
      });
    });
  }
      console.log('conflicts = ', conflicts)
      return conflicts
}


export function errorboxButton() {
  const closeBtn = document.getElementById('close-error-btn');
  const errorContainer = document.querySelector('.error-container');

  closeBtn.addEventListener('click', () => {
    errorContainer.style.display = 'none';
  });
}

const TimeExtractor = (dateStr) => {
  // Sample date string in the format 'dd/mm/yyyy hh:mm'
  const dateString = dateStr;
  //console.log('dateString = ', dateString)

  // Split the string to extract date and time parts
  const [datePart, timePart] = dateString.split(' ');

  // Create a new Date object using the extracted date and time components
  const dateObject = new Date(datePart.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3') + ' ' + timePart);

  // Retrieve time as hh:mm using toLocaleTimeString() method
  const time = dateObject.toLocaleTimeString('nl-BE', { hour: '2-digit', minute: '2-digit' });

  return time;

}

export function convertDateToString(theDate) {
    //console.log('theDate = ', theDate)
    const originalDateTime = theDate;
    //console.log('originalDateTime = ', originalDateTime)
    const convertedDate = new Date(originalDateTime);
    //console.log('convertedDate = ', convertedDate)

    const year = convertedDate.getFullYear();
    //console.log('year = ', year)
    const month = String(convertedDate.getMonth() + 1).padStart(2, '0');
    //console.log('month = ', month)
    const day = String(convertedDate.getDate()).padStart(2, '0');
    //console.log('day = ', day)

    const convertedString = `'${year}${month}${day}'`;
    console.log('convertedString = ', convertedString); // Output: '20230707'
    return convertedString
}

export function createOverlay() {
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // semi-transparent black
  overlay.style.zIndex = '999'; // set the z-index to a value higher than the toast message

  // add the overlay element to the document body
  document.body.appendChild(overlay);
}

export function createFormattedDate(originalDateString) {
 const parsedDate = new Date(originalDateString);

// Format the date with the 'be' locale
const formattedDate = new Intl.DateTimeFormat('nl-BE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'Europe/Brussels', // Adjust the timezone according to your needs
}).format(parsedDate);

  //console.log('Formatted date is: ', formattedDate);
  return formattedDate
}


export const createApprovalBody = (formattedData) => {
  const project = formattedData;
  const users = formattedData.selectedUsers;
  const resources = formattedData.selectedResources;
  let body = '<html>' +
          '<head>' +
          '<title>Reservation request</title>' +
          '</head>' +
          '<body>' +
          '<div>' +
              '<p><strong>New reservation created:</strong>:</p>' +
                  '<p>Reservation created by: ' + project['created_by'] + '</p>' +	  
                  '<p>Project name: ' + project['reservation_project_name'] + '</p>';
                      body += '<p>Start: ' + project['reservation_start_date'] + '	End: ' + project['reservation_end_date'] + '</p>';
                      body += '<p>Recurrence: ' + (project['reservation_rrule'] !== '' ? project['reservation_rrule'] : '')  +'<p>';
                  
              body += 'Users:';
              body += '<ul>';
              for (let i = 0; i < users.length; i++) {
                  body += '<li>' + users[i]['user_email'] + '</li>';
              }
              body += '</ul><br />';
              body += 'Resources:';
              body += '<ul>';
              for (let i = 0; i < resources.length; i++) {
                  body += '<li>' + resources[i]['resource_name'] + '</li></ul><br />';
              }
              body +=     
              '<table border="1" cellpadding="10">' +
                  '<tr>' +
                  '<th width="300">Project Name</th>' +
                  '</tr>' +
                      '<tr>' +
                          '<td width="300">' + project['reservation_project_name'] + '</td>' +
                      '</tr>' +
              '</table>' +
              '<p><a href="https://revareservatie.ugent.be">Manage</a></p>' +
              '</div></body></html>'

  return body;

}

export const createDenialBody = (denialRemarks, deniedReservation) => {
  const remarks = denialRemarks;
  const reservation = deniedReservation
  let body = '<html>' +
          '<head>' +
          '<title>Reservation denied</title>' +
          '</head>' +
          '<body>' +
          '<div>' +
          '<p><strong>Your reservation ' + reservation['reservation_project_name'] + ' was denied.</strong></p>' +
          '<p>This reservation was deleted from the database and removed from your calendar.</p>' +
            '<p>Reason for denial:<br />' + remarks + '</p>'; 
  console.log('denial body = ', body)
  return body;
}