import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//import axios from 'axios';
import axios from '../../localApi/axios';

/* PRODUCTION 
const USERS_URL = 'https://revareservatie.ugent.be/reva-api/users';
*/


/* DEV */
const USERS_URL = '/users';

const initialState = {
  users: [],
  loading: false,
  error: '',
};

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  return axios.get(USERS_URL).then((response) => response.data);
});

export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  async (id) => {
    return await axios
      .get(`${USERS_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const fetchUserByEmail = createAsyncThunk(
  'users/fetchUserByEmail',
  async (email) => {
    return await axios
      .get(`${USERS_URL}/${email}`)
      .then((response) => response.data);
  }
);

export const createUser = createAsyncThunk(
  'users/createuser',
  async (fields) => {
    return await axios
      .post(USERS_URL, fields)
      .then((response) => response.data);
  }
);

export const updateUser = createAsyncThunk(
  'users/updateuser',
  async ({id, data}) => {
     const response =  await axios
      .post(`${USERS_URL}/${id}`, data)
      .catch((error) => console.log(error))
    //console.log('response data = ', response.data)
    return response.data
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteuser',
  async ( id ) => {
    return await axios
      .delete(`${USERS_URL}/${id}`)
      .then((response) => response.data);
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersReset: (state) => {
      // Set the slice state back to its initial value
      //Object.assign(state, initialState);
      state.users = [];
    },
  },

  extraReducers(builder) {
    // FETCH reducers
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = '';
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    // CREATE reducers
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.users = action.payload;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });

    // DELETE reducers
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const { id } = action.payload;
      const users = state.users.filter((user) => user.user_id !== id);
      state.users = users;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });

    //UPDATE reducers
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (!action.payload?.id) {
        console.log('Update could not complete');
        console.log(action.payload);
        return;
      }
      const { id } = action.payload;
      const users = state.users.filter((user) => user.user_id !== id);
      state.users = [...users, action.payload];
    });
  },
});

export const selectAllUsers = (state) => state.users.users;
export const getUsersStatus = (state) => state.users.status;
export const getUsersError = (state) => state.users.error;

export const selectUserById = (state, userId) =>
  state.users.users.find((user) => user.user_id === userId);

export const selectUserByEmail = (state, email) =>
  state.users.users.find((user) => user.user_email === email);

export const { usersReset } = usersSlice.actions;

export default usersSlice.reducer;
