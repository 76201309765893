import { Checkbox } from '../../assets/Checkbox'

const commaSeparatedList = (list) => {
  // Split the comma-separated string into an array
  const items = list.split(',');

 // console.log('items = ', items)

  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item.trim()}</li>
      ))}
    </ul>
  );
};
  
const getProjectTypeVerbose = (value) => {
        const projType = PROJECT_TYPES.find((type) => type.value === Number(value));
        //console.log('project type = ', projType);
        return projType.label;
    };
  
export const TEMPLATE_COLUMNS = [
  {
    id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
  },
  {
    accessorKey: 'template_id',
    header: 'ID',
    props: (props) => props.getValue(),
  },
  {
    id: 'searchColumn',
    accessorKey: 'project_name',
    header: 'Project Name',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'project_type',
    header: 'Project Type',
    //props: (props) => props.getValue(),
    cell: ( props ) => {
          const projectType = getProjectTypeVerbose(props.getValue());
          return <span>{projectType}</span>;
    },
  },
  {
    accessorKey: 'template_resources',
    header: 'Resources',
    cell: (props) => commaSeparatedList(props.getValue())
  },
  {
    accessorKey: 'template_users',
    header: 'Users',
    cell: (props) => commaSeparatedList(props.getValue())
  }
]

export const PROJECT_TYPES = [
  { value: 0, label: '' },
  { value: 1, label: 'PHD' },
  { value: 2, label: 'PostDoc' },
  { value: 3, label: 'MP' },
  { value: 4, label: 'Education' },
  { value: 5, label: 'Other' },
];