import {Checkbox } from '../../../assets/Checkbox'

export const RESOURCE_COLUMNS = [
  {
    id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
  },
  {
    accessorKey: 'resource_id',
    header: 'ID',
    props: (props) => props.getValue(),
  },
  {
    id: 'searchColumn',
    accessorKey: 'resource_name',
    header: 'Name',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'resource_description',
    header: 'Description',
    props: (props) => props.getValue(),
  },
  {
    header: 'Location',
    accessorKey: 'resource_location',
    props: (props) => props.getValue(),
  },
  {
    header: 'category_id',
    accessorKey: 'resource_category_id',
    props: (props) => props.getValue(),
  },
  {
    header: 'Category',
    accessorKey: 'category_name',
    props: (props) => props.getValue(),
  },
  {
    header: 'subcategory_id',
    accessorKey: 'resource_subcategory_id',
    props: (props) => props.getValue(),
  },
  {
    header: 'Sub-Category',
    accessorKey: 'subcategory_name',
    props: (props) => props.getValue(),
  },
  {
    header: 'Status',
    accessorKey: 'status_name',
    props: (props) => props.getValue(),
  },
];

export const USER_COLUMNS = [
  {
    id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
  },
  {
    accessorKey: 'user_id',
    header: 'ID',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_name',
    header: 'Name',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_first_name',
    header: 'First Name',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_last_name',
    header: 'Last Name',
    props: (props) => props.getValue(),
  },
  {
    id: 'searchColumn',
    accessorKey: 'user_email',
    header: 'Email',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_role',
    header: 'Role',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_department',
    header: 'Department',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_phone',
    header: 'Phone',
    props: (props) => props.getValue(),
  },
  {
    accessorKey: 'user_password',
    header: 'password',
    props: (props) => props.getValue(),
  },
];

export const ROLES = [
  {value: 1, label: 'user' },
  {value: 2, label: 'admin'}
]

export const CATEGORIES = [
  { value: 0, label: 'Select the category...' },
  { value: 1, label: 'RESEARCH' },
  { value: 2, label: 'EDUCATION' },
  { value: 3, label: 'DEPARTMENT' },
];

export const SUBCATEGORIES = [
  { value: 0, label: 'Select the subcategory...' },
  { value: 1, label: 'KINE' },
  { value: 2, label: 'LOGO/AUDIO' },
  { value: 3, label: 'nvt' },
];

export const RESOURCE_STATUSSES = [
  { value: 0, label: 'Select the status...' },
  { value: 1, label: 'AVAILABLE' },
  { value: 2, label: 'UNAVAILABLE' },
  { value: 3, label: 'MAINTENANCE' },
];

