import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createUser, fetchUserById, updateUser, fetchUsers } from '../usersSlice'
import { useForm, Controller } from "react-hook-form";
import { DevTool } from '@hookform/devtools';
import { TextField, MenuItem, Box, Button, Select, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { ROLES } from './adminConstants';
import './userForm.css'
import { useNavigate } from 'react-router-dom';

export const UserForm = () => {
    
    const params = useParams()
    // console.log('params = ', params)
    // console.log('resourceId = ', resourceId, ' of type ', typeof(resourceId))
    const id = parseInt(params.id)
    // console.log('id = ', id, ' of type ', typeof(id))
    const isAddMode = !id
    const dispatch = useDispatch()
    const navigate = useNavigate()
   

    const form = useForm({
        defaultValues: {
            user_id: 0,
            user_name: '',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            user_role: '',
            user_phone: '',
            user_department: 'GE37',
            user_password:''
        },
    })
    
    const { register, control, handleSubmit, setValue, formState } = form
    const { errors } = formState
    
    function onSubmit(data) {
        return isAddMode
            ? create(data)
            : update(data);
    }

    const create = async (data) => {
        console.log('data in create = ', data)
        await (dispatch(createUser(data))).then((result) => {
            console.log('Create succeeded');
            console.log('result is ', result)
            navigate('/manageusers')
        }).catch((error) => {
            console.log('Error creating user:', error.message)
            }
        )
    }  

    const update = async (data) => {
        console.log('data in update = ', data)
        await dispatch(updateUser({ id: id, data: data })).then((result) => {
            console.log('Update succeeded')
            console.log('result is ', result)
            navigate('/manageusers')
        }).catch((error) => console.log('error = ', error))
    }
   
    
    useEffect(() => {
        if (!isAddMode) {
            console.log('Inside useEffect')
            console.log('id = ', id)
            dispatch(fetchUserById(id)).then(action => {
                const theUser = action.payload
                const fields = ['user_id', 'user_name', 'user_first_name', 'user_last_name', 'user_email', 'user_role', 'user_phone', 'user_department', 'user_language','user_password'];
                fields.forEach((field) => {
                    if (field === 'user_id' || field === 'user_role') {
                        setValue(field, parseInt(theUser[field]), {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    } else {
                        setValue(field, theUser[field], {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    }
                })
            })
            
        }       
    }, []);
    
    return (
        <>
            <div align='center'>
            <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1>
            <Box
                sx={{
                '& .MuiTextField-root': { m: 1, width: '75ch' },
                '& .MuiSelect-select': { fontFamily: 'UGent', m:1, minWidth: 120} ,
                }}
                >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div>
            <TextField      
                hidden
                id='userId'
                {...register('user_id')}
                />
            </div>
            <div>
            <TextField
                id='userName'
                label='User Name'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                {...register('user_name', {
                    required: {
                        value: true,
                        message: 'User Name is required'
                    }
                })}
                error={!!errors.user_name}
                helperText={errors.user_name?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
                />
            </div>
            <div >
            <TextField
                multiline
                id='userFirstName'
                label='First Name'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                {...register('user_first_name', {
                    required: {
                        value: true,
                        message: 'First Name is required'
                    }
                })}
                error={!!errors.user_first_name}
                helperText={errors.user_first_name?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            />
            
            </div>
            <div >
            <TextField
                id='userLastName'
                label='Last Name'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('user_last_name', {
                    required: {
                        value: true,
                        message: 'User last name is required'
                    }
                })}
                error={!!errors.user_last_name}
                helperText={errors.user_last_name?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
            </div>
            <div >
            <TextField
                id='userEmail'
                label='Email'
                type='email'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('user_email', {
                    required: {
                        value: true,
                        message: 'User email is required'
                    }
                })}
                error={!!errors.user_email}
                helperText={errors.user_email?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
            </div>
            <div >
            <TextField
                id='userPhone'
                label='Phone'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('user_phone')}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
            </div>
            <div >
            <TextField
                id='userDepartment'
                label='Department'
                defaultValue={'GE37'}
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('user_department',
                    {
                    required: {
                        value: true,
                        message: 'User department name is required'
                    }
                })}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
                        </div>
                         <div >
            <TextField
                id='userLanguage'
                label='Language'
                defaultValue={'NL'}
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('user_language')}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
            </div>
            <div >
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id="userRole" style={{ fontFamily: 'UGent' }} shrink={true}>Role</InputLabel>
                <Controller
                    name="user_role"
                    control={control}
                    defaultValue="" // Set the default value
                    render={({ field }) => (
                <Select
                    {...field}
                    label="Role"
                    error={!!errors.user_role}
                    >
                    {ROLES.map((role) => (
                        <MenuItem
                        key={role.value}
                        value={role.value}
                        sx={{ fontFamily: 'UGent' }}
                        >
                        {role.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            />
            {errors.user_role && (
            <FormHelperText>{errors.user_role.message}</FormHelperText>
            )}
            </FormControl>
            </div>
            <div>
            <Button
                id='cancel'
                variant='outlined'
                sx={{ m: 4 }}
                onClick={() => {
                    dispatch(fetchUsers()).then(() => {
                        navigate('/manageusers');
                    })
                }}
            >
                CANCEL
            </Button>
            <Button variant='contained' id='submit' type='submit'>
                    SUBMIT
            </Button>
            </div>
            </form>
            <DevTool control={control }/>
        </Box>
        </div>    
        </>
  )
}

