// Inside SpinnerModal component
import React from 'react';
import { Modal, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: theme.palette.common.white, // Set background color to white
    padding: theme.spacing(2), // Adjust padding as needed
    borderRadius: theme.shape.borderRadius, // Use the global border radius
    textAlign: 'center',
  },
  spinner: {
    color: theme.palette.primary.main,
    fontSize: '2rem', // Increase font size
  },
  text: {
    marginTop: theme.spacing(1), // Adjust margin as needed
    color: theme.palette.text.primary,
    fontFamily: 'UGent', // Use the global font family
  },
}));

export const SpinnerModal = ({ open, onClose, loading }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      BackdropComponent="div"
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className={classes.content}>
        {loading ? (
          <div>
            <CircularProgress className={classes.spinner} />
            <Typography className={classes.text}>Creating and loading events...</Typography>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
