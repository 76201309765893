import { Link } from 'react-router-dom';

export const Unauthorized = () => {
  return (
    <div align='center'>
    <article style={{ padding: '100px' }}>
      <h1>Error</h1>
      <p>You are not authorized to view this page.</p>
      <div className='flexGrow'>
        <Link to='/home'>Visit Our Homepage</Link>
      </div>
      </article>
      </div>
  );
};
