import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../localApi/axios';

/* DEV */
const RESOURCES_URL = '/reservationresources';
const RESOURCES_DATA_URL = '/resources';
const USERS_URL = '/reservationusers';
const RESERVATIONS_URL = '/reservations';
const RESERVATIONS_OVERVIEW_URL = '/reservationsoverview'

/* PRODUCTION 
const RESOURCES_URL = 'https://revareservatie.ugent.be/reva-api/reservationresources';
const RESOURCES_DATA_URL = 'https://revareservatie.ugent.be/reva-api/resources';
const USERS_URL = 'https://revareservatie.ugent.be/reva-api/reservationusers';
const RESERVATIONS_URL = 'https://revareservatie.ugent.be/reva-api/reservations';
const RESERVATIONS_OVERVIEW_URL = 'https://revareservatie.ugent.be/reva-api/reservationsoverview'
*/


const initialState = {
  status: 'idle',
  reservations: [],
  error: '',
  selectedResources: [],
  selectedUsers: [],
  alreadyBooked: [],
  authenticatedUser: null
};

export const getReservations = createAsyncThunk(
  'reservation/getreservations',
  async () => {
    return await axios.get(RESERVATIONS_URL).then((response) => response.data);
  }
);

export const fetchReservationsOverview = createAsyncThunk(
  'reservation/fetchreservationsoverview',
  async () => {
    return await axios.get(RESERVATIONS_OVERVIEW_URL).then((response) => response.data)
  }
)

export const getReservationById = createAsyncThunk(
  'reservation/getreservationbyid',
  async (id) => {
    return await axios
      .get(`${RESERVATIONS_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const getReservationByEmail = createAsyncThunk(
  'reservation/getreservationbyemail',
  async (email) => {
    return await axios
      .get(`${RESERVATIONS_URL}/${email}`)
      .then((response) => response.data);
  }
);

export const addReservation = createAsyncThunk(
  'reservation/createreservation',
  async (data) => {
    // Exclude reservation_id from the data
    const { reservation_id, ...postData } = data;

    try {
      const response = await axios.post(RESERVATIONS_URL, postData);
      return response.data;
    } catch (error) {
      console.error('Error creating reservation:', error);
      throw error; // Rethrow the error to be caught by the calling code
    }
  }
);

export const addResourcesForReservation = createAsyncThunk(
  'reservation/createresourcesforreservation',
  async (data) => {
    //console.log('fields = ', fields);
    const response = await axios
      .post(RESOURCES_URL, data)
      .catch((error) => console.log(error));
    return response.data;
  }
);

export const addUsersForReservation = createAsyncThunk(
  'reservation/createusersforreservation',
  async (data) => {
    //console.log('fields = ', fields);
    const response = await axios
      .post(USERS_URL, data)
      .catch((error) => console.log(error));
    return response.data;
  }
);

export const deleteUsersFromReservation = createAsyncThunk(
  'reservation/deleteusersfromreservation',
  async (id) => {
    //console.log('fields = ', fields);
    const response = await axios
      .delete(`${USERS_URL}/${id}`)
      .catch((error) => console.log(error));
    return response.data;
  }
);

export const getResourceData = createAsyncThunk(
  'reservation/getresourcedata',
  async (id) => {
    return await axios
      .get(`${RESOURCES_DATA_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const approveReservation = createAsyncThunk(
  'reservation/approvereservation',
  async (id) => {
  return await axios 
      .patch(`${RESERVATIONS_URL}/${id}`)
      .then((response)=>response.data)
  }
)

export const updateReservationSmall = createAsyncThunk(
  'reservation/updatereservationSmall',
  async ({ id, action, rrule }) => {
    console.log('UpdateReservationSmall')
    console.log('id = ', id)
    console.log('action = ', action)
    console.log('rrule = ', rrule)
    switch (action) {
      case 'approve':
        //return await axios.patch(`${RESERVATIONS_URL}/${id}`).then((response)=>response.data);
        return await axios.patch(`${RESERVATIONS_URL}/${id}?action=approve`).then((response) => response.data);
      case 'setRrule':
        return await axios.patch(`${RESERVATIONS_URL}/${id}?action=setRrule&rrule=${encodeURIComponent(rrule)}`).then((response) => response.data);
      default:
        throw new Error('Invalid action parameter');
    }
  }
);

export const updateReservation = createAsyncThunk(
  'reservation/updateReservation',
  async ({ id, data }) => {
    console.log('data = ', data);
    const response = await axios
      .post(`${RESERVATIONS_URL}/${id}`, data)
      .catch((error) => console.log(error));
    return response.data;
  }
)

export const deleteReservation = createAsyncThunk(
    'reservation/deletereservation',
    async (id) => {
        return await axios
            .delete(`${RESERVATIONS_URL}/${id}`)
            .then((response) => response.data)
    }
)

export const sendEmail = createAsyncThunk(
  'reservation/sendEmail',
  async ({ project, action, subject, body, users }, { getState }) => {
    const state=getState()
    console.log('resources in state = ', state.selectedResources)
    try {
      const response = await axios.post('/email', { project, action, subject, body, users });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const reservationsSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    reservationReset: (state) => {
      state.reservations = [];
      state.selectedResources = [];
      state.selectedUsers = [];
    },
    setAuthenticatedUser: (state, action) => {
      state.authenticatedUser = action.payload;
    },
    setSelectedReservation: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.reservations = action.payload;
      console.log('In slice. Reservations is : ', state.reservations)
    },
    setSelectedResources: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.selectedResources = action.payload;
      console.log('In slice. selectedResources[] is : ', state.selectedResources)
    },
    setSelectedUsers: (state, action) => {
      console.log('In slice. Payload is : ', action.payload)
      state.selectedUsers = action.payload;
      console.log('In slice. selectedUsers[] is : ', state.selectedUsers)
    },
    appendResources: (state, action) => {
      //console.log('Slice, append, payload = ', action.payload)
      state.selectedResources.push(action.payload);
      //console.log('state.selectedResources = ', state.selectedResources)
    },
    appendUsers: (state, action) => {
      //console.log('Slice, append, payload = ', action.payload)
      state.selectedUsers.push(action.payload);
      //console.log('state.selectedUsers = ', state.selectedUsers)
    },
    setResourceData: (state, action) => {
      state.resourceData.push(action.payload);
    },
    setUserData: (state, action) => {
      state.userData.push(action.payload);
    },
    setAlreadyBooked: (state, action) => {
      state.alreadyBooked.push(action.payload);
    },
    removeResources: (state, action) => {
      const resourceIdToRemove = action.payload;
      state.selectedResources = state.selectedResources.filter(
        (resource) => resource.resource_id !== resourceIdToRemove
      );
    },
    removeUsers: (state, action) => {
      const userIdToRemove = action.payload;
      state.selectedUsers = state.selectedUsers.filter(
        (user) => user.user_id !== userIdToRemove
      );
    },
    fetchResources: (state, action) => {
      return state.selectedResources;
    },
    fetchUsers: (state, action) => {
      return state.selectedUsers
    }
    
  },
  // Extra reducers for database actions
  extraReducers(builder) {
    builder.addCase(getReservations.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.reservations = action.payload;
      //console.log('Inside slice, reservations = ', action.payload)
    });
    builder.addCase(getReservations.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getReservations.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(fetchReservationsOverview.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.reservations = action.payload;
    });
    builder.addCase(fetchReservationsOverview.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchReservationsOverview.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(getReservationById.fulfilled, (state, action) => {
      console.log('Inside getreservationById')
      console.log('action.payload is: ', action.payload)
      state.status = 'succeeded';
      state.reservations = action.payload
    });
    builder.addCase(addReservation.fulfilled, (state, action) => {
      state.reservations.push(action.payload);
    });
    builder.addCase(getResourceData.fulfilled, (state, action) => {
      state.resourceData.push(action.payload);
    });
    builder.addCase(addResourcesForReservation.fulfilled, (state, action) => {
      console.log('action.payload = ', action.payload);
      state.selectedResources.push(action.payload);
    });
    builder.addCase(addUsersForReservation.fulfilled, (state, action) => {
      state.selectedUsers.push(action.payload);
    });
    builder.addCase(deleteUsersFromReservation.fulfilled, (state, action) => {
      const idToDelete = action.payload;
      console.log('id to delete is ', idToDelete);
      state.selectedUsers = state.selectedUsers.filter(
        (resource) => resource.resource_id !== idToDelete
      );
    });
    builder.addCase(deleteReservation.fulfilled, (state, action) => { 
      const { id } = action.payload;
      const reservations = state.reservations.filter((reservation) => reservation.reservation_id !== id);
      state.reservations = reservations;
    });
     builder.addCase(approveReservation.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.reservations = action.payload;
     });
     builder.addCase(updateReservationSmall.fulfilled, (state, action) => {
     state.status = 'succeeded';
      // Update the specific reservation with the new rrule
     const updatedReservation = action.payload;
     const index = state.reservations.findIndex((reservation) => reservation.id === updatedReservation.id);
     if (index !== -1) {
       state.reservations[index] = updatedReservation;
     }
     });
    builder.addCase(updateReservation.fulfilled, (state, action) => {
      state.status = 'succeeded';
      // Update the specific reservation with the new rrule
      const updatedReservation = action.payload;
      const index = state.reservations.findIndex((reservation) => reservation.id === updatedReservation.id);
      if (index !== -1) {
        state.reservations[index] = updatedReservation;
      }
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.emailStatus = 'succeeded';
      // Handle additional state changes if needed
    });

    builder.addCase(sendEmail.pending, (state) => {
      state.emailStatus = 'loading';
    });

    builder.addCase(sendEmail.rejected, (state, action) => {
      state.emailStatus = 'failed';
      state.emailError = action.error.message;
    });
  },
});

export default reservationsSlice.reducer;
export const selectAllReservations = (state) => state.reservations.reservations;
export const selectAllSelectedResources = (state) =>
  state.reservations.selectedResources;
export const selectAllSelectedUsers = (state) =>
  state.reservations.selectedUsers;
export const selectReservationsStatus = (state) => state.reservations.status;
export const selectReservationById = (state) => state.reservations.reservations;
export const selectMyReservations = (state) => state.reservations.reservations.filter(reservation => reservation.created_by === state.reservations.authenticatedUser);
export const {
  resourcesRefresh,
  appendResources,
  appendUsers,
  setResourceData,
  setUserData,
  setAlreadyBooked,
  removeResources,
  removeUsers,
  reservationReset,
  setSelectedReservation,
  setSelectedResources,
  setSelectedUsers,
  fetchResources,
  fetchUsers
} = reservationsSlice.actions;
