import React, { useEffect, useState, useCallback, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useLocation } from 'react-router-dom';
import rrulePlugin from '@fullcalendar/rrule';
import { Popup } from '../../components/Popup';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { fetchBookedResources } from '../reservationresources/bookedResourcesSlice';
import { fetchBookedUsers } from '../reservationusers/bookedUsersSlice';


import './calendarStyles.css';
import { InfoForm } from './InfoForm';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { ActionForm } from './ActionForm';
import { ConfirmationForm } from './ConfirmationForm';
import { deleteReservation, updateReservationSmall, reservationReset, getReservationById, setSelectedReservation, setSelectedResources, setSelectedUsers } from './reservationsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchBookedUsersWithRruleForId, updateUserRruleForId, fetchBookedUsersForId } from '../reservationusers/bookedUsersSlice';
import { fetchBookedResourcesWithRruleForId, updateResourceRruleForId } from '../reservationresources/bookedResourcesSlice';

import { InfoPopup } from '../../components/InfoPopup';
import { format } from 'date-fns';



export const CalendarForm = ({ events, onDeleteEvent }) => {
    
    
    const calendarRef = useRef(null);
    const [weekendsVisible, setWeekendsVisible] = useState(true);
    const [selectedData, setSelectedData] = useState(null)
    const [openPopupInfo, setOpenPopupInfo] = useState(false)
    const [openInfoPopup, setOpenInfoPopup] = useState(false)
    

    const [openPopupAction, setOpenPopupAction] = useState(false)
    const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false)
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [series, setSeries] = useState(false)
    
    let dataSelected = []

    const location = useLocation();
    const pathName = location.pathname
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const dateOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

    
    
    
    function parseExcludedDates(rruleString) {
        const excludedDates = [];
        const lines = rruleString.split('\n');
        for (const line of lines) {
            if (line.startsWith('EXDATE:')) {
                line.replace('EXDATE', ';EXDATE')
                const exdateString = line.substring('EXDATE:'.length);
                const exdateValues = exdateString.split(',');
                exdateValues.forEach((dateString) => {
                    // Convert the date string to a valid format (e.g., 'YYYY-MM-DDTHH:mm:ssZ')
                    const formattedDateString = dateString.slice(0, 4) + '-' + dateString.slice(4, 6) + '-' + dateString.slice(6, 11) + ':' + dateString.slice(11, 13) + ':' + dateString.slice(13);
                    const date = new Date(formattedDateString);
                    if (!isNaN(date.getTime())) {
                        excludedDates.push(date);
                    }
                });
            }
        }
        return excludedDates;
    }

    function parseTimeRange() {
        if (!selectedData || !Array.isArray(selectedData) || selectedData.length === 0) {
            console.error('No valid data found in selectedData');
            return;
        }
        console.log('selectedData =', selectedData)
        const start = selectedData[0].start;
        const end = selectedData[0].end

        const startTime = format(new Date(start), 'HH:mm')
        const endTime = format(new Date(end), 'HH:mm')

        return [startTime, endTime]
    }
    
    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
        
    }

    const handleDelete = async () => {
        const [data] = selectedData;
        const eventId = data.internalId; 
        console.log('eventId = ', eventId);
        console.log('selectedData = ', selectedData)
        
        if (!series) {
            onDeleteEvent(eventId, 0);
        } else {
            onDeleteEvent(0, selectedData[0].projectId)
        }
    };
    
    const handleYesClick = async () => {
        setOpenPopupConfirmation(false)
        const [data] = selectedData
        console.log('data = ', data)
      
        if (data.recurrenceRule && !series) {
            try { 
                const resourceAction = await dispatch(fetchBookedResourcesWithRruleForId(data.projectId))
                const resources = resourceAction.payload
                const userAction = await dispatch(fetchBookedUsersWithRruleForId(data.projectId))     
                const users = userAction.payload
                console.log('resources in handleYesClick = ', resources)
                console.log('users in handleYesClick = ', users)
                const currentRrule = data.recurr;
                const rrule = rrulestr(currentRrule);
                const excludedDates = Array.isArray(rrule.exdate) ? rrule.exdate.map((date) => date.toISOString()) : [];
                //const excludedDate = parseDate(data.start);
                const excludedDate = new Date(data.start);
                console.log('excluded date = ', excludedDate)
                const formattedDate = excludedDate.toISOString().slice(0, 10).replace(/-/g, '');
                console.log('formatted date = ', formattedDate) // Format the parsed date to YYYYMMDD
                excludedDates.push(formattedDate);
                console.log('excludedDates = ', excludedDates)
                const rruleSet = new RRuleSet();
                rruleSet.rrule(rrule);
                excludedDates.forEach((dateString) => {
                    const formattedDate = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                    const excludedDate = new Date(formattedDate); // Convert each date string back to a Date object
                    rruleSet.exdate(excludedDate); // Add each excluded date to the RRuleSet
                });
                const updatedRRuleString = rruleSet.toString();
                console.log('updatedRRuleString = ', updatedRRuleString);
                
                await dispatch(updateReservationSmall({ id: data.projectId, action: 'setRrule', rrule: updatedRRuleString }));
                // Use await to wait for the Promise.all to complete
                await Promise.all(
                    resources.map((resource) =>
                        dispatch(updateResourceRruleForId({ rrule: updatedRRuleString, id: data.projectId }))
                    ),
                    users.map((user) => 
                        dispatch(updateUserRruleForId({ rrule: updatedRRuleString, id: data.projectId }))
                    )
                    );    
                handleDelete()
                
            } catch (error) {
            // Handle error, e.g., log or show an error message
                console.error('Error updating recurrence rule:', error);
            }
            
        } else if(series) {
            
            dispatch(deleteReservation(data.projectId))
            handleDelete()
            setSeries(false)
            
            
        } else {
            dispatch(deleteReservation(data.projectId))
            handleDelete()
            
        }
      
    }
    
    const onCancelInfo = () => {
        setOpenInfoPopup(false)
    }
    const onCancelAction = () => {
        setOpenPopupAction(false)
    }
    const onCancelConfirmation = () => {
        setOpenPopupConfirmation(false)
    }

    const onDelete = () => {
        setOpenPopupAction(false)
        const [dataToDelete] = selectedData

        if (dataToDelete.recurrenceRule && !series) {
            let message = `Are you sure you want to delete occurrence starting on ${(new Date(dataToDelete.start)).toLocaleString('nl-BE', dateOptions)} of project '${dataToDelete.projectName}'?`
            setConfirmationMessage(message)
            setSeries(false)
            setOpenPopupConfirmation(true)    
        } else if (series) {
            console.log('in onDelete')
            let message = `Are you sure you want to delete reservation '${dataToDelete.projectName}'?`
            setConfirmationMessage(message)
            setSeries(true)
            setOpenPopupConfirmation(true)     
        } else {
            let message = `Are you sure you want to delete reservation '${dataToDelete.projectName}'?`
            setConfirmationMessage(message)
            setSeries(false)
            setOpenPopupConfirmation(true)     
        }
    }

    const onDeleteSeries = () => {
        console.log('in onDeleteSeries')
        setOpenPopupAction(false)
        const [dataToDelete] = selectedData
        let message = `Are you sure you want to delete reservation '${dataToDelete.projectName}'?`
        setConfirmationMessage(message)
        setSeries(true);
        setOpenPopupConfirmation(true)    
    }

    const onEdit = async () => {
        const projectId = selectedData[0].projectId
        const resources = selectedData[0].resources
        const users = selectedData[0].users
        await dispatch(setSelectedReservation(selectedData[0]))
        await dispatch(setSelectedResources(resources))
        await dispatch(setSelectedUsers(users))
        navigate(`/addreservation/${projectId}/edit`)
        
    }

    const removeExDates = (rulestring) => {
        const lines = rulestring.split('\n');
        const modifiedLines = lines.filter(line => !line.startsWith('EXDATE:'));
        const modifiedRruleString = modifiedLines.join('\n');
        const rule = RRule.fromString(modifiedRruleString);
        const occurrences = rule.all();
        const dtStart = occurrences.length > 0 ? occurrences[0] : null;
        const until = occurrences.length > 0 ? occurrences[occurrences.length - 1] : null;
        return [dtStart, until];
    };

//     const convertToFormattedString = (inputString) => {
//     // Define regular expressions to match different parts of the input string
//         const dtstartRegex = /DTSTART:\d{8}T\d{6}Z/;
//         const rruleRegex = /RRULE:.+?UNTIL=\d{8}T\d{6}Z/;
//         const exdateRegex = /EXDATE:\d{8}T\d{6}Z/;

//         // Extract individual components
//         const dtstart = inputString.match(dtstartRegex)[0];
//         const rrule = inputString.match(rruleRegex)[0];
//         const exdate = inputString.match(exdateRegex)[0];
    

//         // Concatenate the components with newline characters
//         const formattedString = `${dtstart}\n${rrule}\n${exdate}`;
            
//         return formattedString;
//   };

        

    const handleEventClick = (info) => {
        const event = info.event;
        console.log('event before setState = ', event)
        console.log('eventId = ', event.id)
        
    

        if (pathName !== '/myreservations') {
            if (!event.extendedProps.isRecurrent) {
                console.log('event is not recurring')
                dataSelected = [{
                    projectId: event.extendedProps.projectId,
                    resourceId: event.extendedProps.resource,
                    resourceName: event.title,
                    projectName: event.extendedProps.project,
                    type: event.extendedProps.type,
                    resourceStart: event.startStr ? format(new Date(event.startStr), 'dd/MM/yyyy HH:mm') : '',
                    resourceEnd: event.endStr ? format(new Date(event.endStr), 'dd/MM/yyyy HH:mm') : '',
                    createdBy: event.extendedProps.createdBy,
                    internalId: event.id,
                    recurr: '',
                    recurrenceRule: '',
                }]
            
            } else {
               
                dataSelected = [{
                    projectId: event.extendedProps.projectId,
                    resourceName: event.title,
                    resourceId: event.extendedProps.resource,
                    projectName: event.extendedProps.project,
                    type: event.extendedProps.type,
                    projectStart: format(removeExDates(event.extendedProps.rruleString)[0], 'dd/MM/yyyy'),
                    projectEnd: format(removeExDates(event.extendedProps.rruleString)[1], 'dd/MM/yyyy'),
                    recurr: event.extendedProps.rruleString,
                    resourceStart: event.start ? (event.start).toLocaleString('nl-BE', dateOptions) : '',
                    start: event.startStr ? (event.startStr).toLocaleString('nl-BE', dateOptions) : '',
                    end: event.endStr ? (event.endStr).toLocaleString('nl-BE', dateOptions) : '',
                    recurrenceRule: translateRruleToText(event.extendedProps.rruleString, event.startStr, event.endStr),
                    createdBy: event.extendedProps.createdBy,
                    internalId: event.id
            
                }]
            
            }
            console.log('dataSelected = ', dataSelected)
            setSelectedData(dataSelected)
            setOpenPopupAction(false)
            setOpenInfoPopup(true)
          
        } else {
            console.log('event = ', event)
            if (!event.extendedProps.isRecurrent) {
                dataSelected = [{
                    projectId: event.extendedProps.projectId,
                    projectName: event.title,
                    type: event.extendedProps.type,
                    projectStart: event.startStr ? format(new Date(event.startStr), 'dd/MM/yyyy HH:mm') : '',
                    projectEnd: event.endStr ? format(new Date(event.endStr), 'dd/MM/yyyy HH:mm') : '',
                    resources: event.extendedProps.resources,
                    users: event.extendedProps.users,
                    createdBy: event.extendedProps.createdBy,
                    internalId: event.id
                }]
                console.log('MyReservation dataSelected = ', dataSelected)
            } else {
                dataSelected = [{
                    projectId: event.extendedProps.projectId,
                    projectName: event.title,
                    type: event.extendedProps.type,
                    projectStart: format(removeExDates(event.extendedProps.rruleString)[0], 'dd/MM/yyyy'),
                    projectEnd: format(removeExDates(event.extendedProps.rruleString)[1], 'dd/MM/yyyy'),
                    recurr: event.extendedProps.rruleString,
                    start: event.startStr ? (event.startStr).toLocaleString('nl-BE', dateOptions) : '',
                    end: event.endStr ? (event.endStr).toLocaleString('nl-BE', dateOptions) : '',
                    recurrenceRule: translateRruleToText(event.extendedProps.rruleString, event.startStr, event.endStr),
                    resources: event.extendedProps.resources,
                    users: event.extendedProps.users,
                    createdBy: event.extendedProps.createdBy,
                    internalId: event.id
                }]
            }
            console.log('MyReservation dataSelected = ', dataSelected)
            setSelectedData(dataSelected)
            setOpenPopupAction(true)
            setOpenPopupInfo(false)
        
        }
    }

    function ordinalSuffix(number) {
        const j = number % 10;
        const k = number % 100;
        if (j === 1 && k !== 11) {
            return number + "st";
        }
        if (j === 2 && k !== 12) {
            return number + "nd";
        }
        if (j === 3 && k !== 13) {
            return number + "rd";
        }
        return number + "th";
    }
    
    const translateRruleToText = (rruleString, start, end) => {
        let humanReadableText = ''
        let humanReadableRule = ''
        console.log('rruleString = ', rruleString)
        const rruleLines = rruleString.split('\n');
        const rruleOptions = rruleLines.find((line) => line.startsWith('RRULE:'));
        if (!rruleOptions) return "Invalid RRULE test Options";

        if (!rruleOptions) {
            return "Invalid RRULE: Missing RRULE options.";
        }

        const weekdaysMatch = rruleOptions.match(/BYDAY=([A-Z,]+)/);
        const monthdaysMatch = rruleOptions.match(/BYMONTHDAY=([+-]?\d+)/);
        const dayOfMonthMatch = rruleOptions.match(/BYMONTHDAY=([+-]?\d+)/);

        if (!weekdaysMatch && !monthdaysMatch && !dayOfMonthMatch) {
        return "Invalid RRULE: Missing or invalid BYDAY, BYMONTHDAY, and BYMONTHDAY options.";
        }

        if (weekdaysMatch) {
            const weekdays = weekdaysMatch[1].split(',');
            const weekdaysText = weekdays.map((day) => day).join(', ');
            humanReadableRule = `Every ${weekdaysText}.`;
        }
        if (monthdaysMatch) {
            const monthdays = monthdaysMatch[1].split(',');
            const monthdaysText = monthdays.map((day) => day).join(', ');
            humanReadableRule = `Every ${monthdaysText}.`;
        }
        if (dayOfMonthMatch) {
            const dayOfMonth = parseInt(dayOfMonthMatch[1]);
            humanReadableRule = `Every ${ordinalSuffix(dayOfMonth)} day of the month.`;
        }

        const excludedDates = parseExcludedDates(rruleString);
        const startTime = format(new Date(start), 'HH:mm')
        const endTime = format(new Date(end), 'HH:mm')
       
        const excludedDatesText = excludedDates
            .filter((date) => !isNaN(date.getTime()))
            .map(formatDate)
            .map((date, index) => <li key={index}>{date}</li>);

        humanReadableText = (
            <>
            {`${humanReadableRule} from ${startTime} to ${endTime}`}
            <br />
            {excludedDatesText.length > 0 ? (
                <>
                {'except on:'}
                <ul>
                    {excludedDatesText}
                </ul>
                </>
            ) : (
                ' No exceptions.'
            )}
            </>
        );
    console.log('humanReadableText = ', humanReadableText)        
    return humanReadableText;
        
}; 


    function renderEventContent(eventInfo) {
        return (
            <>
            <i>{eventInfo.event.title}</i>
            </>
        );
    }
    
    return (
      <>
        <div className='demo-app-main'>
          <FullCalendar
            height='650px'
            
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              rrulePlugin,
              momentTimezonePlugin,
            ]}
            timeZone='Europe/Brussels'
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView='dayGridMonth'
            displayEventTime={true}
            editable={false}
            eventOverlap={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            events={events}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
        />
        <div>
            {selectedData && (
                (openInfoPopup ? (
                <InfoPopup openInfoPopup={openInfoPopup} title={'Info'}>
                        <InfoForm
                            selectedData={selectedData} 
                            onCancel={onCancelInfo}
                        />
                        
                </InfoPopup>
                ) : null) ||
                (openPopupAction ? (
                <Popup openPopupAction={openPopupAction} title={'Update or Delete Event'}>
                    <ActionForm
                            selectedData={selectedData}
                            onCancel={onCancelAction}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            onDeleteSeries={onDeleteSeries}
                            translateRruleToText={translateRruleToText}
                    />
                </Popup>
                ) : null)
            )}
        </div>
        <div>
            {selectedData && (
                <ConfirmationPopup
                    openPopupConfirmation={openPopupConfirmation}
                    title={'Confirm'} >
                    <ConfirmationForm
                        message={confirmationMessage}
                        onCancel={onCancelConfirmation}
                        onConfirm={handleYesClick}
                    />
                </ConfirmationPopup>
            )}
        </div>
        </div>   
      </>
    );
  };
