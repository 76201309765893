import { Checkbox } from '../../assets/Checkbox'



export const RESERVATION_COLUMNS = [
  { id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
    ),
  },
  {
    header: 'Reservation ID',
    accessorKey: 'reservation_id',
    props: (props) => props.getValue(),
  },
  {
    header: 'Project Name',
    accessorKey: 'reservation_project_name',
    props: (props) => props.getValue(),
  },
  {
    header: 'Project Type ID',
    accessorKey: 'reservation_project_type',
    props: (props) => props.getValue(),
  },
  {
    header: 'Project Type',
    accessorKey: 'project_type_name',
    props: (props) => props.getValue(),
  },
  {
    header: 'Project Recurrence ID',
    accessorKey: 'reservation_recurrent',
    props: ( props ) => props.getValue(),
  },
  {
    header: 'Project Recurrence',
    accessorKey: 'recurrence_type',
    props: ( props ) => props.getValue(),
  },
        
];

export const ROLES = [
  { value: 1, label: 'user' },
  { value: 2, label: 'admin' }
];

export const CATEGORIES = [
  { value: 0, label: 'Select the category...' },
  { value: 1, label: 'RESEARCH' },
  { value: 2, label: 'EDUCATION' },
  { value: 3, label: 'DEPARTMENT' },
];

export const SUBCATEGORIES = [
  { value: 0, label: 'Select the subcategory...' },
  { value: 1, label: 'KINE' },
  { value: 2, label: 'LOGO/AUDIO' },
  { value: 3, label: 'nvt' },
];

export const RESOURCE_STATUSSES = [
  { value: 0, label: 'Select the status...' },
  { value: 1, label: 'AVAILABLE' },
  { value: 2, label: 'UNAVAILABLE' },
  { value: 3, label: 'MAINTENANCE' },
];

export const PROJECT_TYPES = [
  { value: 0, label: '' },
  { value: 1, label: 'PHD' },
  { value: 2, label: 'PostDoc' },
  { value: 3, label: 'MP' },
  { value: 4, label: 'Education' },
  { value: 5, label: 'Other' },
];

