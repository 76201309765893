import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addNewPost, fetchPosts, getPostById, updatePost} from '../postsSlice'
import { useForm, Controller } from "react-hook-form";
import { DevTool } from '@hookform/devtools';
import {
  POST_TYPES,
} from './adminConstants'
import { TextField, MenuItem, Box, Button, Select, FormControl, InputLabel, FormHelperText, Alert } from '@mui/material';
import './postForm.css'
import { useNavigate } from 'react-router-dom';

export const PostForm = () => {
    
    const params = useParams()
    const id = parseInt(params.id)
    const isAddMode = !id
    const dispatch = useDispatch()
    const navigate = useNavigate()
   

    const form = useForm({
        defaultValues: {
            post_id: 0,
            post_type: 0,
            post_title: '',
            post_body: '',
            posted_by: '',
            posted_on: ''
        },
    })
    
    const { register, control, handleSubmit, setValue, formState } = form
    const { errors } = formState
    
    function onSubmit(data) {
        console.log('data in onSubmit = ', data)
        return isAddMode
            ? create(data)
            : update(data);
    }

    const create = async (data) => {
        await (dispatch(addNewPost(data))).then((result) => {
            console.log('Create succeeded');
            console.log('result is ', result)
            navigate('/manageposts')
        }).catch((error) => {
            console.log('Error creating post:', error.message)
        }
        )
    }

    const update = async (data) => {
        await dispatch(updatePost({ id: id, data: data })).then((result) => {
            console.log('Update succeeded')
            console.log('Result is ', result)
            navigate('/manageposts')
        }).catch((error) => console.log('error = ', error))
    }
    
    useEffect(() => {
        if (!isAddMode) {
            console.log('Inside useEffect')
            console.log('id = ', id)
            dispatch(getPostById(id)).then(action => {
                const thePost = action.payload
                const fields = ['post_id', 'post_type', 'post_title', 'post_body', 'posted_by', 'posted_on'];
                fields.forEach((field) => {
                    if (field === 'post_id' || field === 'post_type') {
                        setValue(field, parseInt(thePost[field]), {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    } else {
                        setValue(field, thePost[field], {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    }
                })
            })
            
        }
    }, []);
    
    return (
        <>
            <div align='center'>
                <h1>{isAddMode ? 'Add Post' : 'Edit Post'}</h1>
                <Box
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '75ch' },
                        '& .MuiSelect-select': { fontFamily: 'UGent', m: 1, minWidth: 120 },
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div>
                            <TextField
                                hidden
                                id='post_id'
                                {...register('post_id')}
                            />
                        </div>
                        <div>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                                <InputLabel id="post_type" style={{ fontFamily: 'UGent' }} shrink={true}>Post Type</InputLabel>
                                <Controller
                                    name="post_type"
                                    control={control}
                                    defaultValue="" // Set the default value
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Type"
                                            {...register('post_type', {
                                                validate: (value) => value !== 0
                                            })}
                                            error={!!errors.post_type}
                                            
                                        >
                                            {POST_TYPES.map((type) => (
                                                <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                    sx={{ fontFamily: 'UGent' }}
                                                >
                                                    {type.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.post_type && (
                                    <FormHelperText error>Post type must be selected</FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <div >
                            <TextField
                                name='post_title'
                                id='postTitle'
                                label='Title'
                                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                                {...register('post_title', {
                                    required: {
                                        value: true,
                                        message: 'Post title is required'
                                    }
                                })}
                                error={!!errors.post_title}
                                helperText={errors.post_title?.message}
                                inputProps={{ style: { fontFamily: 'UGent' } }}
                            />
                        </div>
                        <div >
                            <TextField
                                id='postBody'
                                label='Body'
                                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                                {...register('post_body', {
                                    required: {
                                        value: true,
                                        message: 'Post must have a body'
                                    }
                                })}
                                error={!!errors.post_body}
                                helperText={errors.post_body?.message}
                                inputProps={{ style: { fontFamily: 'UGent' } }}
                            />
                        </div>
                        <div>
                            <TextField
                                id='postedBy'
                                label='Author'
                                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                                {...register('posted_by', {
                                    required: {
                                        value: true,
                                        message: 'Post must have an author'
                                    }
                                })}
                                error={!!errors.posted_by}
                                helperText={errors.posted_by?.message}
                                inputProps={{ style: { fontFamily: 'UGent' } }}
                            />
                        </div>
                        <div>
                            <Button
                                id='cancel'
                                variant='outlined'
                                sx={{ m: 4 }}
                                onClick={() => {
                                    dispatch(fetchPosts()).then(() => {
                                        navigate(-1);
                                    })
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button variant='contained' id='submit' type='submit'>
                                SUBMIT
                            </Button>
                        </div>
                    </form>
                </Box>
            </div>
            <DevTool control={control} />
        </>

    )
}
            
        
        
                


