 import { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Checkbox, MenuItem, Box, Button, Select, FormControl, FormControlLabel, InputLabel, FormHelperText, Grid } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PROJECT_TYPES } from './reservationConstants';
import { useSelector, useDispatch } from 'react-redux';
import { fetchResources} from '../resources/resourcesSlice';
import { addReservation, getReservationById, reservationReset, updateReservation, setSelectedReservation, setSelectedResources, setSelectedUsers, sendEmail } from './reservationsSlice';
import { useNavigate } from 'react-router-dom';
import  be  from 'date-fns/locale/be';
import { checkConflictingEvents, createFormEvents, createApprovalBody  } from './eventUtils';
import { RecurrenceModal } from './RecurrenceModal';
import { ErrorPopup } from '../../components/ErrorPopup'
import { ErrorForm } from './ErrorForm';
import { DevTool } from '@hookform/devtools';
import { CreatorContext } from '../../context/CreatorProvider';
import { format, parseISO, differenceInCalendarDays  } from 'date-fns';
import 'moment-timezone';
import './recurrenceStyles.css';
import { fetchUsers } from '../users/usersSlice';
import {  Controller } from "react-hook-form";
import { useFormContext } from '../../context/FormProvider';





export const ReservationForm3 = ({ currentEvents, id, origin }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [templateChecked, setTemplateChecked] = useState(false);
    const [recurrentChecked, setRecurrentChecked] = useState(false);
    const [templateId, setTemplateId] = useState()
    const [recurrenceModalOpen, setRecurrenceModalOpen] = useState(false);
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [rrule, setRrule] = useState('');
    const { creator } = useContext(CreatorContext);
    const [errorMessage, setErrorMessage] = useState('')

      
  
  
  //let errorMessage = ''
    let theTemplateId;
  
    const selectedReservation = useSelector((state) => state.reservations.reservations)
    const selectedResources = useSelector(
      (state) => state.reservations.selectedResources
    ); 
    const selectedUsers = useSelector(
      (state) => state.reservations.selectedUsers
    );
    const selectedTemplate = useSelector((state) => state.templates.templates)
  
    console.log('selectedReservation = ', selectedReservation)
    console.log('selectedResources = ', selectedResources)
    console.log('selectedUsers = ', selectedUsers)  
    console.log('selectedTemplate = ', selectedTemplate)
    
    console.log('theTemplate is ', selectedTemplate)
    const isAddMode = id === undefined || id === null || Number(id) === 0;
    
  let conflicts = []
  let inputErrors = []

  const { register, control, handleSubmit, setValue, getValues, formState, setError, reset } = useFormContext({
        defaultValues: {
           reservation_id: 0,
            reservation_project_name: '',
            reservation_project_type: '',
            reservation_start_date: '',
            reservation_end_date: '',
            selectedResources,
            selectedUsers,
            reservation_remarks: '',
            reservation_template: 0,
            reservation_recurrent: 0,
            reservation_rrule: '',
            approved: 0,
            created_by: creator.user_email,
      },
    })
  const { errors, isSubmitting, isDirty, isValid } = formState
  const { current: resetForm } = useRef(reset);

  console.log('ERRORS : ', errors);
  console.log('form state = ', formState)

  const handleRecurrentChange = (event) => {
    const isChecked = event.target.checked
    setRecurrentChecked(isChecked);
    setValue('reservation_recurrent', isChecked); // Set the value using React Hook Form
    setRecurrenceModalOpen(isChecked);
  };

  const handleTemplateChange = (event) => {
    const isChecked = event.target.checked
    setTemplateChecked(isChecked);
    setValue('reservation_template', isChecked); // Set the value using React Hook Form
  };

    const handleRecurrenceModalClose = () => {
      setRecurrenceModalOpen(false);
    };
  
    const updateRrule = (rrule) => {
      setRrule(rrule);
      setValue('reservation_rrule', rrule);
      handleRecurrenceModalClose();
    };
  
  function onSubmit(data) {
    inputErrors = getInputErrors(data)
    console.log('inputErrors = ', inputErrors)
      if (inputErrors.length > 0) {
        const inputErrorMessage = buildInputErrorMessage(inputErrors)
        setErrorMessage(inputErrorMessage)
        setOpenErrorPopup(true);
        return;
      }
      conflicts = conflictingEvents()
      if (conflicts.length > 0) {
        const message = buildMessage(conflicts)
        setErrorMessage(message)
        setOpenErrorPopup(true);
        return;
      }
      else {
          setErrorMessage('')
          return isAddMode
            ? create(data)
            : update(data);
      } 
    }

  const create = async () => {
    try {
      const data = getValues()
      const formattedData = {
          ...data,
            reservation_start_date: format(new Date(data.reservation_start_date), 'yyyy-MM-dd HH:mm:ss'),
            reservation_end_date: format(new Date(data.reservation_end_date), 'yyyy-MM-dd HH:mm:ss'),
            selectedResources,
            selectedUsers,
            created_by: creator.user_email,
      };
      const startDate = new Date(formattedData.reservation_start_date);
      const endDate = new Date(formattedData.reservation_end_date);
      const differenceDays = differenceInCalendarDays(endDate, startDate);
      if (differenceDays >= 4 || formattedData.reservation_rrule !== '') {
        const body = createApprovalBody(formattedData);
        await dispatch(sendEmail({
          project: formattedData,
          action: 'approve',
          subject: 'New reservation request awaits your approval',
          body: body
        }))
        formattedData.approved = 0;
      } else {
        // make sure the reservation is automatically approved
        formattedData.approved = 1;
      }
      console.log('formatted data = ', formattedData)
      await new Promise((resolve) => setTimeout(resolve, 1000))
      await (dispatch(addReservation(formattedData)))

    } catch (error) {
        setError('root', {message: 'There are errors in your form or the email for approval failed.'})
    }
    resetForm()
    dispatch(reservationReset())
    navigate('/myreservations')
  }  
    

  const update = async (data) => {
    try {
      const formattedData = {
        ...data,
        reservation_start_date: format(new Date(data.reservation_start_date), 'yyyy-MM-dd HH:mm:ss'),
        reservation_end_date: format(new Date(data.reservation_end_date), 'yyyy-MM-dd HH:mm:ss'),
        selectedResources,
        selectedUsers,
        created_by: creator.user_email,
      };
      const startDate = new Date(formattedData.reservation_start_date);
      const endDate = new Date(formattedData.reservation_end_date);
      const differenceDays = differenceInCalendarDays(endDate, startDate);
      if (differenceDays >= 4 || formattedData.reservation_rrule !== '') {
          console.log('differenceDays = ', differenceDays)
          const body = createApprovalBody(formattedData);
          await dispatch(sendEmail({
            project: formattedData,
            action: 'approve',
            subject: 'Updated reservation request awaits your approval',
            body: body
          }))
          formattedData.approved = 0;
        } else {
          formattedData.approved = 1; 
        }
      await new Promise((resolve) => setTimeout(resolve, 1000))
      console.log('formattedData in update = ', formattedData)
      await dispatch(updateReservation({ id: id, data: formattedData }))
      } catch (error) {
        setError('root', {message: 'There are errors in your form'})
      }
    resetForm()
    dispatch(reservationReset())
    navigate('/myreservations')
  }

  const getInputErrors = (data) => {
    console.log('data = ', data)
    let errMessage;
    if (data.reservation_project_name === "") { errMessage = 'Project name/title is required'; inputErrors.push(errMessage) }
    if (data.reservation_project_type === "") { errMessage = 'Project type is required'; inputErrors.push(errMessage) }
    if (data.reservation_start_date === undefined) {errMessage = 'Reservation start date is required'; inputErrors.push(errMessage) }
    if (data.reservation_end_date === undefined) {errMessage = 'Reservation end date is required'; inputErrors.push(errMessage) }
    if (data.reservation_end_date !== undefined && data.reservation_end_date <= data.reservation_start_date) {errMessage ='Reservation end date must be after start date'; inputErrors.push(errMessage) }
    if (selectedResources.length === 0) { errMessage = 'You must select at least 1 resource'; inputErrors.push(errMessage) }
    if (selectedUsers.length === 0) { errMessage = 'You must select at least 1 user'; inputErrors.push(errMessage) }
    //if (selectedUsers.length === 1) { errMessage = 'You must select at least 1 resource'; inputErrors.push(errMessage) }
    return inputErrors;
  }

    const fetchData = async () => {
        try {
          if (!isAddMode && origin !== 'template') {
            console.log('Inside useEffect ReservationForm');
            console.log('id = ', id);

            const action = await dispatch(getReservationById(id));
            const theReservation = action.payload[0][0]; // Assuming the payload is an array

            console.log('theReservation is: ', theReservation);

            const fields = [
              'reservation_id',
              'reservation_start_date',
              'reservation_end_date',
              'reservation_project_name',
              'reservation_project_type',
              'reservation_recurrent',
              'reservation_rrule',
              'reservation_template',
              'created_by',
              'reservation_remarks',
              'approved'
            ];

            fields.forEach((field) => {
              if (field === 'reservation_id' || field === 'reservation_project_type' || field === 'approved') {
                setValue(field, parseInt(theReservation[field]), {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true
                })
                setValue('reservation_template', 0)
              } else if (field === 'reservation_start_date') {
                const dateString = parseISO(theReservation[field]);
                const dateObject = new Date(dateString)
                setValue(field, dateObject, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true
                })
              } else if (field === 'reservation_end_date') {
                const dateString = parseISO(theReservation[field]);
                const dateObject = new Date(dateString)
                setValue(field, dateObject, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true
                })
              } else if (field === 'reservation_recurrent') {
                theReservation[field] === 1 ? setValue(field, true) : setValue(field, false)
                setRecurrentChecked(getValues().reservation_recurrent)
              } else if (field === 'reservation_template') {
                 theReservation[field] === 1 ? setValue(field, true) : setValue(field, false)
              } else {
                    setValue(field, theReservation[field], {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true
                    })
                }
              
              console.log(`${field}:`, getValues(field)); 
            });

            await dispatch(setSelectedReservation(theReservation))
            await dispatch(setSelectedResources(selectedResources))
            await dispatch(setSelectedUsers(selectedUsers))

          } else if (origin === 'new') {
              await dispatch(setSelectedResources(selectedResources))
              await dispatch(setSelectedUsers(selectedUsers))
              console.log('selectedTemplate = ', selectedTemplate)
              const theTemplate = selectedTemplate[0].templateData[0]
              console.log('theTemplate = ', theTemplate)
              theTemplateId = theTemplate.template_id
              console.log('theTemplateId = ', theTemplateId)
              setTemplateId(theTemplateId)
              console.log('templateId = ', templateId)
              setValue('reservation_project_name', theTemplate.project_name)
              setValue('reservation_project_type', theTemplate.project_type)            
          }

        } catch (error) {
            console.error('Error fetching reservation data:', error);
        }
    };
        

    useEffect(() => {
      fetchData()
    }, []);
  
    const conflictingEvents = () => {
      const data = getValues()
      let conflictResult;
      console.log('data = ', data)
      const eventsForConflictCheck = createFormEvents(selectedResources, selectedUsers, data.reservation_start_date, data.reservation_end_date, data.reservation_rrule)
      //console.log('eventsForConflictCheck = ', eventsForConflictCheck)
      //console.log('currentEvents = ', currentEvents)
      if (isAddMode) {
        //console.log('In add mode, no comparing')
        conflictResult = checkConflictingEvents(currentEvents, eventsForConflictCheck, data.reservation_rrule,data.reservation_start_date, data.reservation_end_date);
      } else {
        //console.log('In update mode, comparing is needed')
        const filteredCurrentEvents = currentEvents.filter((event) => {
          for (const eventBeingUpdated of eventsForConflictCheck) {
            if (areEventsEqual(event, eventBeingUpdated)) {
              return false; // Exclude the current event being updated
            }
          }
          return true; // Include other events
        });
        //console.log('filtered current events = ', filteredCurrentEvents)
        conflictResult = checkConflictingEvents(filteredCurrentEvents, eventsForConflictCheck, data.reservation_rrule,data.reservation_start_date, data.reservation_end_date);
      }
      //console.log('conflictResult = ', conflictResult)
      return conflictResult;
    }
  
  const areEventsEqual = (event1, event2) => {
    console.log('event1.start = ', event1.start)
    if (event1.resource === event2.resource && event1.projectId === event2.projectId) {
      return true;
    } else {
      return false
    };
  }
    
    const buildMessage = (conflicts) => {
      let errorMessage = '';
        conflicts.map((conflict) => {
          errorMessage += `There is a conflict of type "${conflict.type}" on resource "${conflict.title} at date "${conflict.start}" or "${conflict.end}"<br>`;
        });
      return errorMessage
    }
  
    const buildInputErrorMessage = (errors) => {
      let inputErrorMessage = '';
        errors.map((error) => {
          inputErrorMessage += `${error}<br>`;
        });
      console.log('inputErroprMessage = ', inputErrorMessage)
      return inputErrorMessage
    }
  
  useEffect(() => {
  console.log(errors); // Log errors to the console
}, [errors]);


    return (
               <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={be} />
      <Grid
        container
        spacing={2}
        sx={{
          width: '65%',
          marginLeft: '4rem',
          //marginTop: '2rem',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noVaidate>
          <Grid
            container
            spacing={2}
            sx={{ width: '100%', marginLeft: '2rem', fontSize: '0.5rem' }}
          >
            <Grid
              container
              spacing={2}
              sx={{ width: '100%', marginLeft: '2rem', marginTop: '2rem' }}
            >
            
              <Grid item xs={12} sm={8} md={8}>
                <TextField
                  hidden
                  id='projectId'
                  {...register('reservation_id')}
                />
              </Grid>
              <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant='contained'
                  sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
                  onClick={() => {
                    dispatch(fetchResources())
                      .then(() => {
                        const formValues = getValues();
                        if (origin !== 'new') {
                          navigate(`/selectresources/${formValues.reservation_id}/${origin}`)
                        } else {
                          navigate(`/selectresources/${0}/${origin}`)
                        }
                      });
                    }}
                >
                  RESOURCES
                </Button>
              
                <Button
                  variant='contained'
                  sx={{ height: 40, width: 110, fontSize: 12, margin: 1 }}
                  onClick={() => {
                      dispatch(fetchUsers())
                        .then(() => {
                        const formValues = getValues();
                        if (origin !== 'new') {
                          navigate(`/selectusers/${formValues.reservation_id}/${origin}`)
                        } else {
                          navigate(`/selectusers/${0}/${origin}`)
                        }
                      });
                    }}
                >
                  USERS
                </Button>
              </Box>  
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth error={!!errors.reservation_project_name} >
                  <TextField
                    InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                    fullWidth
                      label='Project Name'
                      {...register("reservation_project_name")}
                  />
                  <FormHelperText>{errors.reservation_project_name?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>                  
              <FormControl
                sx={{ mt: 0, ml: 2, minWidth: 120 }}
                error={!!errors.reservation_project_type}
              >
                <InputLabel id="reservation_project_type" style={{ fontFamily: 'UGent' }}>
                  Type
                </InputLabel>
                    <Controller
                      name="reservation_project_type"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} label="Type">
                          {PROJECT_TYPES.map((type) => (
                            <MenuItem
                              key={type.value}
                              value={type.value}
                              sx={{ fontFamily: 'UGent' }}
                            >
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                  />
                {errors.reservation_project_type && (
                  <FormHelperText>{errors.reservation_project_type?.message}</FormHelperText>
                )}
              </FormControl>
              </Grid>
                
            
            <Grid item xs={6} sm={6} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'left' }}>
                    <FormControl error={!!errors.reservation_start_date}>
                        <Controller
                          control={control}
                          name='reservation_start_date'
                          render={({ field }) => (
                            <DateTimePicker
                              disablePast
                              label='Start Date and Time'
                              id='startDate'
                              //format='dd.MM.yyyy hh:mm'
                              {...field}
                              slotProps={{ textField: { variant: 'outlined' } }}
                              inputProps={{ style: { fontFamily: 'UGent' } }}
                              InputLabelComponent={({ children, ...props }) => (
                                <InputLabel {...props} shrink style={{ fontFamily: 'UGent' }}>
                                  {children}
                                </InputLabel>
                              )}
                            />
                          )}
                      />
                      <FormHelperText>{errors.reservation_start_date?.message}</FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'left' }}>
                    <FormControl error={!!errors.reservation_end_date}>
                      <Controller
                        control={control}
                        name="reservation_end_date"
                        render={({ field }) => (
                          <DateTimePicker
                            disablePast
                            required
                            label='End Date and Time'
                            id='endDate'
                            //format='dd.MM.yyyy hh:mm'
                            {...field}
                            slotProps={{ textField: { variant: 'outlined' } }}
                            error={!!errors.reservation_end_date}
                            helperText={errors.reservation_end_date?.message}
                            inputProps={{ style: { fontFamily: 'UGent' } }}
                            InputLabelComponent={({ children, ...props }) => (
                              <InputLabel {...props} shrink style={{ fontFamily: 'UGent' }}>
                                {children}
                              </InputLabel>
                            )}
                          />
                        )}
                    />
                    <FormHelperText>{errors.reservation_end_date?.message}</FormHelperText>
                  </FormControl>
                </Box>
            </Grid>    
            <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between">
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register('reservation_template')}
                          checked={templateChecked}
                          onChange={(e) => {
                            console.log('Template Checkbox Clicked');
                            handleTemplateChange(e);
                          }}
                        />
                      }
                      label="Is Template?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register('reservation_recurrent')}
                          checked={recurrentChecked}
                          onChange={(e) => {
                            console.log('Recurrent Checkbox Clicked');
                            handleRecurrentChange(e);
                          }}
                        />
                      }
                      label="Is Recurrent?"
                    />
                    <RecurrenceModal
                      isOpen={recurrenceModalOpen}
                      onClose={handleRecurrenceModalClose}
                      updateRrule={updateRrule}
                    />
                    <TextField
                      hidden
                      id='rrule'
                      fullWidth
                      {...register('reservation_rrule')}              
                  >
                      {rrule}
                  </TextField>
                </Box>                
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                id='reservationRemarks'
                label='Remarks'
                {...register('reservation_remarks')}
                multiline
                maxRows={30}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '250px',
                  },
                }}
                //onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  id='cancel'
                  variant='outlined'
                      onClick={() => {
                        resetForm()
                        dispatch(reservationReset())
                        navigate('/myreservations');
                      }}
                  >
                  CANCEL
                </Button>
                <Button
                      disabled={(origin === 'edit' && isSubmitting) || (origin !== 'edit' &&  (!isValid))}
                      variant='contained'
                      type='submit'
                    >
                      {isSubmitting ? 'Loading...' : (origin === 'edit' ? 'UPDATE' : 'SUBMIT')}
                </Button>
              </Box>
            </Grid>

            
              </Grid>
            </Grid>
            
            <DevTool control={control} />
          </form>
          {openErrorPopup && (
            <ErrorPopup
              openInfoPopup={openErrorPopup}
              title={'Error'}
              onCancel={() => setOpenErrorPopup(false)}
            >
              <ErrorForm errorMessage={errorMessage} onCancel={() => setOpenErrorPopup(false)} />
            </ErrorPopup>
          )}
    </Grid>
    </>
  );
}
