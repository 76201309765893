import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { deleteTemplate, fetchTemplates, getTemplateById, createTemplate, updateTemplate, templateReset} from './templatesSlice';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel  } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { TEMPLATE_COLUMNS } from './templateConstants'
import { Button, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Filters } from '../../components/Filters';

import '../../styles.css';
import { reservationReset } from '../reservations/reservationsSlice';

export const TemplateAdminView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [data, setData] = useState({})
    const [sorting, setSorting] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState([])

    const templates = useSelector((state) => state.templates.templates)
    const selectedResources = useSelector((state) => state.templates.selectedResources)
    const selectedUsers = useSelector((state) => state.templates.selectedUsers)
    console.log('selectedResources = ', selectedResources)
    console.log('selectedUsers = ', selectedUsers)

    //const memoizedData = useMemo(() => data, [data]);
    
    const table = useReactTable(
        {
            //data: data,
            data: data,
            columns: TEMPLATE_COLUMNS,
            state: {
                columnVisibility: { template_id: false},
                columnFilters,
                sorting: sorting,
                rowSelection: rowSelection,
                
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: setRowSelection,
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setData(setFilterFunc);
                },
            }
        },
    )
  
    

    const handleCreate = () => {
        dispatch(templateReset())
        navigate('/managetemplates/add')
    }

    const handleEdit = (row) => {
        const templateId = row[0].original.template_id;
        //console.log('SelectedTemplateID is :', templateId)
        navigate(`/managetemplates/edit/${templateId}`);
    }

    const handleDelete = async (rows) => {
        const meta = table.options.meta
        let templateString='';
        for (let i = 0; i < rows.length; i++) {
            const selected = rows[i].original.template_id
            console.log('selected = ', selected)
            if (i !== rows.length - 1) {
                templateString += selected + ', '
            } else {
                templateString += selected + '?'
            }
        }
        const result = window.confirm(`Are you sure you want to delete template(s) ${templateString}`);
        if (result) {
            const deletedTemplateIds = rows.map(row => row.original.template_id);
            for (let i = 0; i < deletedTemplateIds.length; i++) {
                await dispatch(deleteTemplate(deletedTemplateIds[i]));
            }
            meta.removeSelectedRows(table.getSelectedRowModel().rows.map(row => row.index))
            table.resetRowSelection()
        } else {
        navigate('/templates');
    }
  }

    useEffect(() => {
       const fetchData = async () => {  
            const result = await dispatch(fetchTemplates());
            setData(result.payload);
        };
        fetchData();
    }, []);

    

    return (
        <div align='center' className='-table-adresourcemin'>
            <h1>Manage Templates</h1>
            <br />
            <br />
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>
                <div>    
                    <Filters 
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}        
                    />              
                </div>
                <div className='button-next' style={{ marginLeft: 'auto' }}>
                    <Button disabled={table.getIsSomeRowsSelected()} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleCreate()}>CREATE</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length !== 1} startIcon={<EditOutlinedIcon />} variant='contained' onClick={() => handleEdit(table.getSelectedRowModel().flatRows)}>EDIT</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(table.getSelectedRowModel().flatRows)}>DELETE</Button>
                </div>
            </Box>
            <br />
            <table width={table.getTotalSize()}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup =>
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header =>
                                <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                    {header.column.columnDef.header}
                                    {
                                        { asc: ' 🔼 ', desc: ' 🔽 ' }[header.column.getIsSorted() ?? null]
                                    }
                                </th>
                            )}
                        </tr>)}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell =>
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            <br />
            <div className='pagination-style'>
                <Button variant='text' onClick={() => table.setPageIndex(0)} size='small'>First</Button>
                <Button disabled={!table.getCanPreviousPage()} variant='text' onClick={() => table.previousPage()} size='small'>Previous</Button>
                <Button disabled={!table.getCanNextPage()} variant='text' onClick={() => table.nextPage()} size='small'>Next</Button>
                <Button variant='text' onClick={() => table.setPageIndex(table.getPageCount() - 1)} size='small'>Last</Button>
            </div>
        </div>
    )
}



