import React, { useEffect, useState } from 'react'
import { fetchPosts } from './postsSlice';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { POST_COLUMNS, POST_TYPES } from './admin/adminConstants'
import { Button, Box } from '@mui/material';
import { Filters } from '../../components/Filters';


import './admin/postForm.css';

export const PostsView = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState({})
    const [sorting, setSorting] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState([])
    
    const getStyle = (row) => {
    let styleSetting = '';
    const theRow = row.original;
    if (theRow.post_type === 1) {
      //console.log('in IT');
      styleSetting = 'IT';
    } else if (theRow.post_type === 2) {
      //console.log('in OZ');
      styleSetting = 'OZ';
    } else if (theRow.post_type === 3) {
      styleSetting = 'OW';
    } else if (theRow.post_type === 4) {
      styleSetting = 'VG';
    }
    return styleSetting;
  };

    const table = useReactTable(
        {
            data: data,
            columns: POST_COLUMNS,
            state: {
                columnVisibility: { post_id: false},
                columnFilters,
                sorting: sorting,
                rowSelection: rowSelection,
                
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: setRowSelection,
            enableRowSelection: true,
        },
    )
  
   

    useEffect(() => {
        (async () => {
            const result = await dispatch(fetchPosts())
            console.log('result = ', result)
            setData(result.payload);
        })();
    }, []);

    

    return (
        <div align='center' className='-table-adresourcemin'>
            <h1>Posts</h1>
            <br />
            <br />
            <Box sx={{ '& button': { m: 5 }, display: 'flex', alignItems: 'center', width: '80%', marginBottom:5 }}>  
                <div>
                    <Filters 
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}        
                    />
                </div>
            </Box>
            
            <table width={table.getTotalSize()}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup =>
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header =>
                                <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                    {header.column.columnDef.header}
                                    {
                                        { asc: ' 🔼 ', desc: ' 🔽 ' }[header.column.getIsSorted() ?? null]
                                    }
                                </th>
                            )}
                        </tr>)}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        <tr key={row.id} className={getStyle(row)}>
                            {row.getVisibleCells().map(cell =>
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            <br />
            <div className='pagination-style'>
                <Button variant='text' onClick={() => table.setPageIndex(0)} size='small'>First</Button>
                <Button disabled={!table.getCanPreviousPage()} variant='text' onClick={() => table.previousPage()} size='small'>Previous</Button>
                <Button disabled={!table.getCanNextPage()} variant='text' onClick={() => table.nextPage()} size='small'>Next</Button>
                <Button variant='text' onClick={() => table.setPageIndex(table.getPageCount() - 1)} size='small'>Last</Button>
            </div>
        </div>
    )
}