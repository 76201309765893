import { Box } from '@mui/material'
import React from 'react'

export const Filters = ({ columnFilters, setColumnFilters }) => {
    const columnName = columnFilters.find(
         f => f.id === 'searchColumn'
    )?.value || ''

    const onFilterChange = (id, value) => {
        setColumnFilters(
            prev => prev.filter(f => f.id !== id).concat({id, value})
        )
    }
  return (
    <Box>
        <input
              type='text'
              variant='filled'
              borderRadius={5}
              columnName={columnName}
              onChange={ (e)=>onFilterChange('searchColumn', e.target.value)}
        />
    </Box>
  )
}
