import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { deletePost, fetchPosts } from '../postsSlice';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { POST_COLUMNS, POST_TYPES } from './adminConstants'
import { Button, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Filters } from '../../../components/Filters';

import '../../../styles.css';

export const PostAdminView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [data, setData] = useState({})
    const [filter, setFilter] = useState('')
    const [sorting, setSorting] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const [columnFilters, setColumnFilters] = useState([])

    const getStyle = (row) => {
        let styleSetting = '';
        const theRow = row.original;
        if (theRow.post_type === 1) {
        //console.log('in IT');
        styleSetting = 'IT';
        } else if (theRow.post_type === 2) {
        //console.log('in OZ');
        styleSetting = 'OZ';
        } else if (theRow.post_type === 3) {
        styleSetting = 'OW';
        } else if (theRow.post_type === 4) {
        styleSetting = 'VG';
        }
        return styleSetting;
    };

    

    const table = useReactTable(
        {
            data: data,
            columns: POST_COLUMNS,
            state: {
                columnVisibility: { post_type: false},
                sorting: sorting,
                rowSelection: rowSelection,
                columnFilters,
                
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: setRowSelection,
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setData(setFilterFunc);
                //setOriginalData(setFilterFunc);
                },
      }
        },
    )
  
    const handleCreate = () => {
        navigate('/manageposts/add')
    }

    const handleEdit = (row) => {
        const postId = row[0].original.post_id;
        navigate(`/manageposts/edit/${postId}`);
    }

    const handleDelete = (row) => {
        const result = window.confirm(`Are you sure you want to delete post ${row[0].original.post_title}?`);
        if (result) {
            console.log(`Post ${row[0].original.post_title} will be deleted`)
            const postId = row[0].original.post_id
            dispatch(deletePost(postId))
            navigate(-1)
        } else {
            navigate(-1);
        }
    
    }

    useEffect(() => {
        (async () => {
            const result = await dispatch(fetchPosts())
            console.log('result = ', result)
            setData(result.payload);
        })();
    }, []);

    

    return (
        <div align='center' className='-table-adresourcemin'>
            <h1>Manage Posts</h1>
            <br />
            <br />
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>
                <div>
                    <Filters 
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}        
                    />
                </div>
                <div className='button-next' style={{ marginLeft: 'auto' }}>
                    {/* <div className='button-next' style={{ marginLeft: 'auto' }}> */}
                    <Button disabled={table.getIsSomeRowsSelected()} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleCreate()}>CREATE</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length !== 1} startIcon={<EditOutlinedIcon />} variant='contained' onClick={() => handleEdit(table.getSelectedRowModel().flatRows)}>EDIT</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length !== 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(table.getSelectedRowModel().flatRows)}>DELETE</Button>
                    {/* </div> */}
                </div>
            </Box>
            <br />
            <table width={table.getTotalSize()}>
                <thead>
                    {table.getHeaderGroups().map(headerGroup =>
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header =>
                                <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                                    {header.column.columnDef.header}
                                    {
                                        { asc: ' 🔼 ', desc: ' 🔽 ' }[header.column.getIsSorted() ?? null]
                                    }
                                </th>
                            )}
                        </tr>)}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        <tr key={row.id} className={getStyle(row)}>
                            {row.getVisibleCells().map(cell =>
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
            <br />
            <div className='pagination-style'>
                <Button variant='text' onClick={() => table.setPageIndex(0)} size='small'>First</Button>
                <Button disabled={!table.getCanPreviousPage()} variant='text' onClick={() => table.previousPage()} size='small'>Previous</Button>
                <Button disabled={!table.getCanNextPage()} variant='text' onClick={() => table.nextPage()} size='small'>Next</Button>
                <Button variant='text' onClick={() => table.setPageIndex(table.getPageCount() - 1)} size='small'>Last</Button>
            </div>
        </div>
    )
}