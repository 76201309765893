import React, { useState, useEffect, useContext } from 'react'
import { CreatorContext } from '../../context/CreatorProvider';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { useDispatch, useSelector} from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchUserById, fetchUsers} from './usersSlice'
import { appendUsers, removeUsers } from '../reservations/reservationsSlice';
import { appendTemplateUsers, removeTemplateUsers } from '../templates/templatesSlice';
import { Button, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { USER_COLUMNS } from './admin/adminConstants';
import { Filters } from '../../components/Filters';

import '../../styles.css';
import { ConfirmationPopup } from '../../components/ConfirmationPopup';
import { ConfirmationForm } from '../reservations/ConfirmationForm';



export const UserSelection = () => {
    const [userData, setUserData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [columnFilters, setColumnFilters] = useState([])
    const [sorting, setSorting] = useState([])
    const [rowSelection, setRowSelection] = useState([])
    const [selectedRowId, setSelectedRowId] = useState()
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [openPopupConfirmation, setOpenPopupConfirmation] = useState(false)
    const [rowSelectionSelectedUsers, setRowSelectionSelectedUsers] = useState({})
    const [rowSelectionUsers, setRowSelectionUsers] = useState({})
    const { creator } = useContext(CreatorContext);
    

    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate()

    const id = params.id
    const origin = params.origin

    
    const selectedUsers = useSelector((state) => state.reservations.selectedUsers)
    const selectedTemplate = useSelector((state) => state.templates.templates)
    const selectedTemplateUsers = useSelector((state) => state.templates.selectedUsers)



    console.log('id=', id)
    console.log('origin=', origin)

    const SelectedUsersTable = useReactTable(
        {
            data: selectedData,
            columns: USER_COLUMNS,
            state: {
            columnVisibility: { user_password: false },
            sorting: sorting,
            rowSelection: rowSelectionUsers,
            selectedRowIds: {},
        },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: (selectedRowIds) => {
                    setRowSelectionUsers(selectedRowIds); // Update row selection state for the first table
                },
            enableRowSelection: true,
            meta: {
                removeSelectedRows: (selectedRows) => {
                const setFilterFunc = (old) =>
                old.filter((_row, index) => !selectedRows.includes(index));
                setSelectedData(setFilterFunc);
          //setOriginalData(setFilterFunc);
                },
            }

        },
    )
    
    const UsersTable = useReactTable(
        {
            data: userData,
            columns: USER_COLUMNS,
            state: {
                columnVisibility: { user_password: false},
                sorting: sorting,
                rowSelection: rowSelection,
                columnFilters,
            },
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onSortingChange: setSorting,
            getFilteredRowModel: getFilteredRowModel(),
            onRowSelectionChange: setRowSelection,
            enableRowSelection: true,
        },
    )

    const handleAdd = (rows) => {
        rows.map((row) => {
            let check;
            const userId = row.original.user_id
            console.log('userId = ', userId)
            if (origin !== 'template') {
                check = selectedUsers.filter((user) => user.user_id === userId)
            } else {
                check = selectedTemplateUsers.filter((user) => user.user_id === userId)
            }
            console.log('check = ', check)
            if (check.length === 0) {
                dispatch(fetchUserById(userId)).then((result) => {
                    const user = result.payload;
                    setSelectedData((previousArray) => [...previousArray, user]);
                    if (origin !== 'template') {
                        dispatch(appendUsers(user))
                    } else {
                        dispatch(appendTemplateUsers(user))
                    }
                })
            }
        })
        UsersTable.resetRowSelection(true)
        //UsersTable.setGlobalFilter('')
    }

    const handleDelete = async (rows) => {
        const meta = SelectedUsersTable.options.meta
        const creatorUserId = creator.user_id
        const deletedUserIds = rows.map(row => row.original.user_id);

        const filteredDeletedUserIds = deletedUserIds.filter((deletedUser) => deletedUser !== creatorUserId)
        console.log('filteredUserIds = ', filteredDeletedUserIds)
        for (let i = 0; i < filteredDeletedUserIds.length; i++) {
            if (origin !== 'template') {
                    await dispatch(removeUsers(filteredDeletedUserIds[i]));
                } else {
                    dispatch(removeTemplateUsers(filteredDeletedUserIds[i]))
                }
        }
        console.log('SelectedUsersTable.getSelectedRowModel().rows = ', SelectedUsersTable.getSelectedRowModel().rows)
        let constRows = (SelectedUsersTable.getSelectedRowModel().rows).filter(row => row.original.user_id !== creatorUserId)
        meta.removeSelectedRows(constRows.map(row => row.index))
        SelectedUsersTable.resetRowSelection(true)
  }
    

    const onCancelConfirmation = () => {
        setOpenPopupConfirmation(false)
    }
    
    useEffect(() => {
        let checkForAdmin;
        (async () => {
            const result = await dispatch(fetchUsers())
            console.log('result = ', result)
            setUserData(result.payload);
            if (origin !== 'template') {
                selectedUsers.map((user) => {
                    dispatch(fetchUserById(user.user_id)).then((result) => {
                        const user = result.payload;
                        setSelectedData((previousArray) => [...previousArray, user]);
                    })
                })
                checkForAdmin = selectedUsers.filter((user) => user.user_email === creator.user_email)
            } else {
                selectedTemplateUsers.map((user) => {
                        dispatch(fetchUserById(user.user_id)).then((result) => {
                            const user = result.payload;
                            setSelectedData((previousArray) => [...previousArray, user]);
                    })
                })
                checkForAdmin = selectedTemplateUsers.filter((user) => user.user_email === creator.user_email)
            }
            console.log('check for admin = ', checkForAdmin)
            console.log('creator = ', creator)
            if (checkForAdmin.length === 0) {
                if (origin !== 'template') {
                    dispatch(appendUsers(creator))
                } else {
                    dispatch(appendTemplateUsers(creator))
                }
                setSelectedData((previousArray) => [...previousArray, creator]);
            }
      })();
    }, []);

    return (
    <>        
        <div align='center'>
                <Box sx={{ width: '80%' }}>
                    <div className='button-align-left'>
                        
                            <Button
                                variant='contained'
                                style={{ textAlign: 'left' }}
                                onClick={() =>
                                    origin !== 'template'
                                        ? navigate(`/addreservation/${id}/${origin}`)
                                        : navigate(`/managetemplates/edit/${id}`)
                                }
                            >
                                Back
                            </Button>
                        
                    </div>
            </Box>
            <h1>Selected Users</h1>
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>  
            <div className='button-next' style={{ marginLeft: 'auto' }}>
                <Button disabled={(SelectedUsersTable.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(SelectedUsersTable.getSelectedRowModel().flatRows)}>DELETE</Button> 
            </div>
            </Box>
            <table width={SelectedUsersTable.getTotalSize()}>
                <thead>
                {SelectedUsersTable.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {SelectedUsersTable.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <br />
        <br />
        <div align='center'>
            <h1>Users</h1>
            <Box sx={{ '& button': { m: 1 }, display: 'flex', alignItems: 'center', width: '80%' }}>  
              <div>
                <Filters 
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}             
                 />
              </div>
              <div className='button-next'>
                  <Button variant='contained' sx = {{marginLeft: 'auto'}} onClick={() => handleAdd(UsersTable.getSelectedRowModel().flatRows)}>
                    Add
                  </Button>
              </div>      
            </Box>      
            <br />
            <br />
            <table width={UsersTable.getTotalSize()}>
                <thead>
                {UsersTable.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {UsersTable.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
        </table>
        <br />
            <div className='pagination-style'>
              <Button variant='text' onClick={()=> UsersTable.setPageIndex(0)} size='small'>First</Button>
              <Button disabled={!UsersTable.getCanPreviousPage()} variant='text' onClick={()=>UsersTable.previousPage()} size='small'>Previous</Button>
              <Button disabled={!UsersTable.getCanNextPage()} variant='text' onClick={()=>UsersTable.nextPage()} size='small'>Next</Button>
              <Button variant='text' onClick={()=>UsersTable.setPageIndex(UsersTable.getPageCount()-1)} size='small'>Last</Button>
            </div>
            </div>
             <ConfirmationPopup
                            openPopupConfirmation={openPopupConfirmation}
                            title={'Confirm'} >
                            <ConfirmationForm
                                message={confirmationMessage}
                                onCancel={onCancelConfirmation}
                                //onConfirm={handleYesClick}
                            />
                        </ConfirmationPopup>
            
    </>
  )
}


