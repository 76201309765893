export const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'user_id',
  },
  {
    Header: 'Short Name',
    accessor: 'user_name',
  },
  {
    Header: 'First Name',
    accessor: 'user_first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'user_last_name',
  },
  {
    Header: 'Email',
    accessor: 'user_email',
  },
  {
    Header: 'Phone',
    accessor: 'user_phone',
  },
  {
    Header: 'Language',
    accessor: 'user_language',
  },
  {
    Header: 'Role',
    accessor: 'user_role',
  },
  {
    Header: 'Department',
    accessor: 'user_department',
  },
];
