import React from 'react';

export const UserFilter = ({ filter, setFilter }) => {
  return (
    <>
      <input
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder='Search any value...'
        className='resource-search'
      />
    </>
  );
};
