import React from 'react'
import { Grid, Button, Typography } from '@mui/material';


export const ErrorForm = ({ errorMessage, onCancel }) => {
  console.log('passed errorMessage is ', errorMessage)
  //const conflictsArray = errorMessage.split('<br>');
  const errorsArray = errorMessage.split('<br>')

  return (
    <form onCancel={onCancel}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1">
            You have errors in your reservation:
          </Typography>
          {/* <ul>
            {conflictsArray
            .filter((conflict) => conflict.trim())  // Filter out empty strings
            .map((conflict, index) => (
                <li key={index}>{conflict}</li>
            ))}
          </ul> */}
          <ul>
            {errorsArray
            .filter((error) => error.trim())  // Filter out empty strings
            .map((error, index) => (
                <li key={index}>{error}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between" marginTop="25px">
        <Grid item>
          <Button id="cancel" variant="outlined" size="small" align="left" onClick={onCancel}>
            OK
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
