import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

export const ErrorPopup = (props) => {
    const { title, children, openInfoPopup, onCancel, errorMessage } = props;
  return (
    <Dialog open={openInfoPopup}>
        <DialogTitle bgcolor='#FF0000' color='white'>
            <h3 align='center'>{title}</h3>
        </DialogTitle>
          <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}