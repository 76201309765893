import { Grid, Button } from '@mui/material'
import React from 'react'

export const ConfirmationForm = ({ message, onCancel, onConfirm }) => {

    console.log('Message in confirmation from is ', { message })

  const handleSubmit = (event) => {
      event.preventDefault()
      

  }
    
  return (
      <form onSubmit={handleSubmit}>
        <Grid container>
            <Grid item xs={12}>
                {message}
            </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-between" marginTop='25px'>
            <Grid item>
                      <Button
                          id='cancel'
                          variant='outlined'
                          size='small'  
                          onClick={ onCancel}>
                    CANCEL
                </Button>
            </Grid>
            <Grid item>
                      <Button
                          id='delete'
                          variant='contained'
                          size='small'
                          //type='submit'
                          onClick={onConfirm} >
                    YES
                </Button>
            </Grid>
        </Grid>
      </form>
  )
}

