import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { getReservationById} from './reservationsSlice';
import { fetchBookedResources} from '../reservationresources/bookedResourcesSlice';
import { fetchBookedUsers } from '../reservationusers/bookedUsersSlice';
import { CalendarForm } from './CalendarForm';
import useAuth from '../../components/hooks/useAuth';
import moment from 'moment-timezone';
import 'moment-timezone';
import { createMyEvents } from './eventUtils';
import { SpinnerModal } from '../../assets/fancy/SpinnerModal';
import { ThemeProvider, createTheme } from '@mui/material/styles';


export const MyReservations = () => {
  const [events, setEvents] = useState([]);
  const [spinnerModalOpen, setSpinnerModalOpen] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const { auth } = useAuth();
  const dispatch = useDispatch();
  const timeZone = moment.tz.setDefault('Europe/Brussels');
  
  // const handleOpen = () => {
  //   setSpinnerModalOpen(true);
  // };

  const handleClose = () => {
    setSpinnerModalOpen(false);
  }
  

  const createEvents = useCallback(async () => {
    //console.log('Creating events...');
    try {
        setSpinnerLoading(true); // Start loading spinner
        const bookedUsersResponse = await dispatch(fetchBookedUsers());
        const bookedUsers = bookedUsersResponse.payload;
        const filteredBookedUsers = bookedUsers.filter((user) => user.user_email === (auth.user).toLowerCase())

        const reservationIds = [...new Set(filteredBookedUsers.map(user => user.reservation_id))];

        const myReservationsResponses = await Promise.all(
            reservationIds.map(async (reservationId) => {
            return await dispatch(getReservationById(reservationId)); // Replace with your actual action to get a reservation by ID
          })
        );
      
        const myReservations = myReservationsResponses.map((response) => response.payload[0][0]);
        console.log('myReservations = ', myReservations)
      
        const bookedResourcesResponse = await dispatch(fetchBookedResources());
        const bookedResources = bookedResourcesResponse.payload;

        

        return createMyEvents(myReservations, bookedResources, bookedUsers);
    } catch (error) {
        //console.error('Error fetching data or creating events:', error);
        return [];
    } finally { 
      setTimeout(() => {
        setSpinnerLoading(false);
        setSpinnerModalOpen(false);
      }, 3000);
    }
}, [auth.user, dispatch]);

  const fetchDataAndSetEvents = async () => {
    const newEvents = await createEvents();
    setEvents(newEvents);
  };

  useEffect(() => {
    fetchDataAndSetEvents();
  }, [createEvents]);
  
  const handleDeleteEvent = (eventId, projectId) => {
    if (eventId !== 0 && projectId === 0) {
      setEvents(prevEvents => prevEvents.filter((event) => event.id !== eventId));
    } else {
      setEvents(prevEvents => prevEvents.filter((event) => event.projectId !== projectId));
    }
  };

  const localTheme = createTheme();

  return (
    <ThemeProvider theme={localTheme}>
      <div align='center' width='80%'>
        {/* Conditionally render CalendarForm based on spinnerLoading */}
        {!spinnerLoading && events && (
          <CalendarForm
            timeZone={timeZone}
            key={events.length}
            events={events}
            onDeleteEvent={handleDeleteEvent}
          />
        )}
        <SpinnerModal open={spinnerModalOpen} onClose={handleClose} loading={spinnerLoading} />
      </div>
    </ThemeProvider>
  );
};

