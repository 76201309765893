import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { deleteUser, fetchUsers } from '../usersSlice';
import { flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, getSortedRowModel, getFilteredRowModel, RowSelection, isRowSelected } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { USER_COLUMNS } from './adminConstants'
import { Button, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Filters } from '../../../components/Filters';

import '../../../styles.css';

export const UserAdminView = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [data, setData] = useState({})
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})
  const [columnFilters, setColumnFilters] = useState([])

  const table = useReactTable(
    {
      data: data,
      columns: USER_COLUMNS,
      state: {
        columnVisibility: { resource_id: false, user_name: false, user_password: false },
        globalFilter,
        sorting: sorting,
        rowSelection: rowSelection,    
        columnFilters,
      },
      getCoreRowModel: getCoreRowModel(),
      onGlobalFilterChange: setGlobalFilter,
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
      getFilteredRowModel: getFilteredRowModel(),
      onRowSelectionChange: setRowSelection,
      enableRowSelection: true,
      meta: {
        removeSelectedRows: (selectedRows) => {
          const setFilterFunc = (old) =>
          old.filter((_row, index) => !selectedRows.includes(index));
          setData(setFilterFunc);
        },
      }
    },
  )
  
  const handleCreate = () => {
    navigate('/manageusers/add')
  }

  const handleEdit = (row) => {
    //console.log('row[0].original = ', row[0].original)
    const userId = row[0].original.user_id;
    //console.log('resourceId = ', resourceId, ' of type ', typeof(resourceId))
    navigate(`/manageusers/edit/${userId}`);
  }

  
  const handleDelete = async (rows) => {
    const meta = table.options.meta
    let userString='';
    for (let i = 0; i < rows.length; i++ ) {
      const selected = rows[i].original.user_email
      //console.log('selected = ', selected)
      if (i !== rows.length-1) {
        userString += selected + ', '
      } else {
        userString += selected + '?'
      }
    }
    const result = window.confirm(`Are you sure you want to delete user(s) ${userString}`);
    if (result) {
      const deletedUserIds = rows.map(row => row.original.user_id);
      for (let i = 0; i < deletedUserIds.length; i++) {
        await dispatch(deleteUser(deletedUserIds[i]));
      }
      meta.removeSelectedRows(table.getSelectedRowModel().rows.map(row => row.index))
      table.resetRowSelection()
      setGlobalFilter('')
    } else {
      navigate('/manageresources');
   }
  }

  useEffect(() => {
      (async () => {
          const result = await dispatch(fetchUsers())
          //console.log('result = ', result)
          setData(result.payload);
      })();
  }, []);

    

    return (
        <div align='center' className='-table-adresourcemin'>
            <h1>Manage Users</h1>
            <br />
            <br />
            <Box sx={{ '& button': { m: 1 },  display: 'flex', alignItems: 'center', width: '80%' } }>
              <div>
                  <Filters 
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}        
                />
              </div>  
              <div className='button-next' style={{ marginLeft: 'auto' }}>
                    {/* <div className='button-next' style={{ marginLeft: 'auto' }}> */}
                    <Button disabled={table.getIsSomeRowsSelected()} startIcon={<AddCircleOutlineIcon />} variant='contained' onClick={() => handleCreate()}>CREATE</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length !== 1} startIcon={<EditOutlinedIcon />} variant='contained' onClick={() => handleEdit(table.getSelectedRowModel().flatRows)}>EDIT</Button>
                    <Button disabled={(table.getSelectedRowModel().flatRows).length < 1} startIcon={<DeleteOutlineIcon />} variant='contained' onClick={() => handleDelete(table.getSelectedRowModel().flatRows)}>DELETE</Button>
                    {/* </div> */}
                </div>
            </Box>
          
            <br />
            <table width={table.getTotalSize()}>
                <thead>
                {table.getHeaderGroups().map(headerGroup =>
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header =>
                          <th key={header.id} onClick={header.column.getToggleSortingHandler()}>
                              {header.column.columnDef.header}
                              {
                                {asc:' 🔼 ', desc: ' 🔽 '}[header.column.getIsSorted() ?? null]
                              }
                            </th>
                        )}
                    </tr>)}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => 
                                <td key={cell.id}>
                                    {
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
        </table>
        <br />
            <div className='pagination-style'>
              <Button variant='text' onClick={()=> table.setPageIndex(0)} size='small'>First</Button>
              <Button disabled={!table.getCanPreviousPage()} variant='text' onClick={()=>table.previousPage()} size='small'>Previous</Button>
              <Button disabled={!table.getCanNextPage()} variant='text' onClick={()=>table.nextPage()} size='small'>Next</Button>
              <Button variant='text' onClick={()=>table.setPageIndex(table.getPageCount()-1)} size='small'>Last</Button>
          </div>
        <hr />
        <div>
          {console.log(table.getSelectedRowModel().flatRows)}
          {console.log(getSelection())}
        </div>
      </div>
  )
}
