import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createResource, fetchResourceById, updateResource, fetchResources } from '../resourcesSlice'
import { useForm, Controller } from "react-hook-form";
import { DevTool } from '@hookform/devtools';
import {
  CATEGORIES,
  SUBCATEGORIES,
  RESOURCE_STATUSSES,
} from '../resourceConstants';
import { TextField, MenuItem, Box, Button, Select, FormControl, InputLabel, FormHelperText, Alert } from '@mui/material';
import './resourceForm.css'
import { useNavigate } from 'react-router-dom';
import { ErrorPopup } from '../../../components/ErrorPopup'
import { ErrorForm } from '../../reservations/ErrorForm';

export const ResourceForm = () => {
    
    const [errorMessage, setErrorMessage] = useState('')
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    
    const params = useParams()
    const id = parseInt(params.id)
    const isAddMode = !id
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let inputErrors=[]
   
   

    const form = useForm({
        defaultValues: {
            resource_id: 0,
            resource_name: '',
            resource_description: '',
            resource_location: '',
            resource_category_id: 0,
            resource_subcategory_id: 0,
            resource_status: 0
        },
    })
    
    const { register, control, handleSubmit, setValue, formState } = form
    const { errors } = formState
    
    function onSubmit(data) {
        console.log('data in onSubmit = ', data)
        const inputErrors = getInputErrors(data)
        if (inputErrors.length > 0) {
            const inputErrorMessage = buildInputErrorMessage(inputErrors)
            setErrorMessage(inputErrorMessage)
            setOpenErrorPopup(true);
            return;
        }
        return isAddMode
            ? create(data)
            : update(data);
    }

    const create = async (data) => {
        console.log('data in create = ', data)
        await (dispatch(createResource(data))).then((result) => {
            console.log('Create succeeded');
            console.log('result is ', result)
            navigate('/manageresources')
        }).catch((error) => {
            console.log('Error creating resource:', error.message)
            }
        )
    }  
    

    const update = async (data) => {
        console.log('data in update = ', data)
        await dispatch(updateResource({ id: id, data: data })).then((result) => {
            console.log('Update succeeded')
            console.log('Result is ', result)
            navigate('/manageresources')
        }).catch((error) => console.log('error = ', error))
    }

    const getInputErrors = (data) => {
        console.log('data = ', data)
        let errMessage;
        if (data.resource_category_id === 0) { errMessage = 'Resource category is required'; inputErrors.push(errMessage) }
        if (data.resource_subcategory_id === 0) { errMessage = 'Resource subcategory is required'; inputErrors.push(errMessage) }
        if (data.resource_status === 0) {errMessage = 'Resource status is required'; inputErrors.push(errMessage) }
       
    //if (selectedUsers.length === 1) { errMessage = 'You must select at least 1 resource'; inputErrors.push(errMessage) }
        return inputErrors;
    }

    const buildInputErrorMessage = (errors) => {
      let inputErrorMessage = '';
        errors.map((error) => {
          inputErrorMessage += `${error}<br>`;
        });
      console.log('inputErroprMessage = ', inputErrorMessage)
      return inputErrorMessage
    }
    
    useEffect(() => {
        if (!isAddMode) {
            console.log('Inside useEffect')
            console.log('id = ', id)
            dispatch(fetchResourceById(id)).then(action => {
                const theResource = action.payload
                const fields = ['resource_id', 'resource_name', 'resource_description', 'resource_location', 'resource_category_id', 'resource_subcategory_id', 'resource_status'];
                fields.forEach((field) => {
                    if (field === 'resource_id' || field === 'resource_category_id' || field === 'resource_subcategory_id' || field === 'resource_status') {
                        setValue(field, parseInt(theResource[field]), {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    } else {
                        setValue(field, theResource[field], {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true
                        })
                    }
                })
            })
            
        }   
    }, []);
    
    return (
        <>
            <div align='center'>
            <h1>{isAddMode ? 'Add Resource' : 'Edit Resource'}</h1>
            <Box
                sx={{
                '& .MuiTextField-root': { m: 1, width: '75ch' },
                '& .MuiSelect-select': { fontFamily: 'UGent', m:1, minWidth: 120} ,
                }}
                >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div>
            <TextField      
                hidden
                id='resourceId'
                {...register('resource_id')}
                />
            </div>
            <div>
            <TextField      
                id='resourceName'
                label='Resource Name'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                {...register('resource_name', {
                    required: {
                        value: true,
                        message: 'Resource name is required'
                    }
                })}
                error={!!errors.resource_name}
                helperText={errors.resource_name?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
                />
            </div>
            <div >
            <TextField
                multiline
                id='resourceDescription'
                label='Resource Description'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}
                {...register('resource_description', {
                    required: {
                        value: true,
                        message: 'Resource description is required'
                    }
                })}
                error={!!errors.resource_description}
                helperText={errors.resource_description?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            />
            
            </div>
            <div >
            <TextField
                id='resourceLocation'
                label='Resource Location'
                InputLabelProps={{ shrink: true, style: { fontFamily: 'UGent' } }}            
                {...register('resource_location', {
                    required: {
                        value: true,
                        message: 'Resource location is required'
                    }
                })}
                error={!!errors.resource_location}
                helperText={errors.resource_location?.message}
                inputProps={{ style: { fontFamily: 'UGent' } }}
            /> 
            </div>
            <div >
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id="resource_category_id" style={{ fontFamily: 'UGent' }} shrink={true}>Category</InputLabel>
                <Controller
                    name="resource_category_id"
                    control={control}
                    defaultValue="" // Set the default value
                    render={({ field }) => (
                <Select
                    {...field}
                    label="Category"
                    >
                    {CATEGORIES.map((category) => (
                        <MenuItem
                        key={category.value}
                        value={category.value}
                        sx={{ fontFamily: 'UGent' }}
                        >
                        {category.label}
                        </MenuItem>
                    ))}
                </Select>
                        
                )}
            />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id="resource_subcategory_id" style={{ fontFamily: 'UGent' }} shrink={true}>Subcategory</InputLabel>
                <Controller
                    name="resource_subcategory_id"
                    control={control}
                    defaultValue="" // Set the default value
                    render={({ field }) => (
                <Select
                    {...field}
                    label="Subcategory"
                    error={!!errors.resource_subcategory_id}
                    >
                    {SUBCATEGORIES.map((subcategory) => (
                        <MenuItem
                        key={subcategory.value}
                        value={subcategory.value}
                        sx={{ fontFamily: 'UGent' }}
                        >
                        {subcategory.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            />
            {errors.resource_subcategory_id && (
            <FormHelperText>{errors.resource_subcategory_id.message}</FormHelperText>
            )}
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id="resource_status" style={{ fontFamily: 'UGent' }} shrink={true}>Status</InputLabel>
                <Controller
                    name="resource_status"
                    control={control}
                    defaultValue="" // Set the default value
                    render={({ field }) => (
                <Select
                    {...field}
                    label="Status"
                    error={!!errors.resource_status}
                    >
                    {RESOURCE_STATUSSES.map((status) => (
                        <MenuItem
                        key={status.value}
                        value={status.value}
                        sx={{ fontFamily: 'UGent' }}
                        >
                        {status.label}
                        </MenuItem>
                    ))}
                </Select>
            )}
            />
            {errors.resource_status && (
            <FormHelperText>{errors.status.message}</FormHelperText>
            )}
            </FormControl>
            </div>
            <div>
            <Button
                id='cancel'
                variant='outlined'
                sx={{ m: 4 }}
                onClick={() => {
                    dispatch(fetchResources()).then(() => {
                        navigate(-1);
                    })
                }}
            >
                CANCEL
            </Button>
            <Button variant='contained' id='submit' type='submit'>
                    SUBMIT
            </Button>
            </div>
            </form>
            <DevTool control={control }/>
                </Box>
            </div>{openErrorPopup && (
            <ErrorPopup
              openInfoPopup={openErrorPopup}
              title={'Error'}
              onCancel={() => setOpenErrorPopup(false)}
            >
              <ErrorForm errorMessage={errorMessage} onCancel={() => setOpenErrorPopup(false)} />
            </ErrorPopup>
          )}    
        </>
  )
}

