import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { AuthProvider } from './context/AuthProvider';
import { CreatorProvider } from './context/CreatorProvider';
import { FormProvider } from './context/FormProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import './font/ugentpannotext-normal-web.ttf';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import nlBE from 'date-fns/locale/nl-BE';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  //   <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nlBE}>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <CreatorProvider>
            <FormProvider>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </FormProvider>
          </CreatorProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </LocalizationProvider>

  //   </React.StrictMode>
);
