import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../localApi/axios';

/* DEV */
const RESERVATION_USERS_URL = '/reservationusers';
const BOOKED_USERS_URL = '/bookedusers'

/* PROD 
 const RESERVATION_USERS_URL =
  'https://revareservatie.ugent.be/reva-api/reservationusers';
  */


const initialState = {
  users: [],
  status: 'idle',
  error: null,
};

export const fetchBookedUsers = createAsyncThunk(
  'bookedusers/fetchbookedusers',
  async () => {
    return await axios
      .get(RESERVATION_USERS_URL)
      .then((response) => response.data);
  }
);

export const fetchBookedUsersForId = createAsyncThunk(
  'bookedusers/fetchbookedusersforid',
  async (id) => {
    return await axios
      .get(`${RESERVATION_USERS_URL}/${id}`)
      .then((response) => response.data);
  }
);

export const fetchBookedUsersWithRruleForId = createAsyncThunk(
  'bookedusers/fetchbookeduserswithrruleforid',
  async (id) => {
    return await axios
      .get(`${BOOKED_USERS_URL}/${id}`)
      .then((response) => response.data);
  }

)

export const updateUserRruleForId = createAsyncThunk(
  'bookedusers/updateuserrruleforid',
  async ({ rrule, id }) => {
    return await axios
      .patch(`${BOOKED_USERS_URL}/${id}?rrule=${encodeURIComponent(rrule)}`)
      .then((response) => response.data)
  }
)

const bookedUsersSlice = createSlice({
  name: 'bookedUsers',
  initialState,
  reducers: {
    bookedUsersReset: (state) => {
      // Set the slice state back to its initial value
      state.users =[];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookedUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookedUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchBookedUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBookedUsersForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(updateUserRruleForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload
      })
      .addCase(fetchBookedUsersWithRruleForId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload
      });
      
  },
});

export const selectBookedUsers = (state) => state.bookedUsers.users;
export const selectBookedUsersStatus = (state) => state.bookedUsers.status;

export const { bookedUsersReset } = bookedUsersSlice.actions;

export default bookedUsersSlice.reducer;
